// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectSummaryWeeklyApi from './apis';

type ProjectSummaryWeeklyResponseSuccessType = {
  responsibility: {
    [key: string]: number,
  }
};

export type ProjectSummaryWeeklyAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_SUMMARY_WEEKLY_SUCCESS'>,
  data: ProjectSummaryWeeklyResponseSuccessType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_SUMMARY_WEEKLY_FAIL'>,
|};


function* getProjectSummaryWeekly(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ProjectSummaryWeeklyResponseSuccessType } = yield call(getProjectSummaryWeeklyApi, action.data.projectId, action.data.startDate);
    yield put({ type: ActionTypes.GET_PROJECT_SUMMARY_WEEKLY_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_SUMMARY_WEEKLY_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_SUMMARY_WEEKLY, getProjectSummaryWeekly);
}
