// @flow
import ActionTypes from './action-types';
import type { DelayReasonAction } from './sagas';

type DelayReasonItem = {
  label: string,
  value: number,
  text: string,
};

export type delayReasonsState = {
  chartData: Array<DelayReasonItem>,
  loading: boolean | null,
};

const getOrderedList: Function = function getOrderedList(data: DelayReasonItem): DelayReasonItem[] {
  const list: DelayReasonItem[] = Object.keys(data).map((key: string): DelayReasonItem => {
    const projectDelay: number = Object.keys(data[key].project_delay).reduce((acc: number, delay: number): number => acc + data[key].project_delay[delay], 0);

    return {
      label: key,
      value: data[key].value,
      text: `A total of ${data[key].occurrence} occurrence(s) has delayed the project by ${projectDelay} day(s)`,
    };
  });

  return list.sort((item1: DelayReasonItem, item2: DelayReasonItem): number => (
    item1.value < item2.value ? 1 : -1
  ));
};

const initialState: delayReasonsState = { loading: null, chartData: [] };

export default function (state: delayReasonsState = initialState, action: DelayReasonAction): delayReasonsState {
  switch (action.type) {
    case ActionTypes.GET_ROOT_CAUSE_OF_DELAY_REASONS_SUCCESS:
      return {
        ...state,
        chartData: getOrderedList(action.data.root_cause),
        loading: false,
      };
    default:
      return state;
  }
}
