// @flow

import { combineReducers } from 'redux';
import delayReasons from './widgets/delay-reasons/reducer';
import delayResponsibility from './widgets/delay-responsibility/reducer';
import reasonForDelays from './widgets/reason-for-delays/reducer';
import projectWeeklyProgress from './widgets/project-weekly-progress/reducer';
import projectWeeklyCostProgress from './widgets/project-weekly-cost-progress/reducer';
import topMostDelayedActivities from './widgets/top-most-delayed-activities/reducer';
import topMostDelayedTasks from './widgets/top-most-delayed-tasks/reducer';
import topExtendedActivities from './widgets/top-extended-activities/reducer';
import topExtendedTasks from './widgets/top-extended-tasks/reducer';
import topDelayedConstraints from './widgets/top-delayed-constraints/reducer';
import topEarlyFinishedActivities from './widgets/top-early-finished-activities/reducer';
import topEarlyFinishedTasks from './widgets/top-early-finished-tasks/reducer';
import topShortenedActivities from './widgets/top-shortened-activities/reducer';
import topShortenedTasks from './widgets/top-shortened-tasks/reducer';
import activityProfileDate from './widgets/activity-profile-date/reducer';
import activityProfileEndDate from './widgets/activity-profile-enddate/reducer';
import activityProfileDuration from './widgets/activity-profile-duration/reducer';
import weeklyActivityProfileDate from './widgets/weekly-activity-profile-date/reducer';
import weeklyActivityProfileDuration from './widgets/weekly-activity-profile-duration/reducer';
import weeklyActivityProfileEndDate from './widgets/weekly-activity-profile-end-date/reducer';
import delayMitigationEffort from './widgets/delay-mitigation-effort/reducer';
import topCriticalPathDelayedActivities from './widgets/top-critical-path-delayed-activities/reducer';
import projectStatus from './widgets/project-status/reducer';
import rateOfProgressWeekly from './widgets/rate-progress-weekly/reducer';
import projectSummaryWeekly from './widgets/project-summary-weekly/reducer';
import projectStatusWeekly from './widgets/project-status-weekly/reducer';
import cprWeekly from './widgets/cpr-weekly/reducer';
import topDelayReasonsWeekly from './widgets/top-critical-delay-reasons-weekly/reducer';
import projectProjection from './widgets/project-projection/reducer';
import weeklyPPC from './widgets/weekly-ppc/reducer';
import weeklyDelayMitigation from './widgets/weekly-delay-mitigation/reducer';
import weeklyProductivityImprovement from './widgets/weekly-productivity-improvement/reducer';
import delaysAndMitigations from './widgets/delays-and-mitigations/reducer';
import weeklyWorkableBacklog from './widgets/weekly-workablebacklog/reducer';
import workableBacklog from './widgets/workable-backlog-weekly/reducer';
import workDelay from './widgets/work-delay-widget-weekly/reducer';
import constraintDelay from './widgets/constraint-delay-widget-weekly/reducer';
import weeklyCommitmentReliability from './widgets/weekly-commitment-reliability/reducer';
import commitmentReliabilityWeekly from './widgets/commitment-reliability-weekly/reducer';
import modals from './modals/reducer';
import activityDetail from './activity-detail/reducer';
import taskDetail from './task-detail/reducer';
import delayDetail from './delay-detail/reducer';
import project from './project/reducer';
import othersDelays from './others-delays/reducer';

import dashboard from './dashboard/reducer';
import weekly from './weekly/reducer';

import type { dashboardState } from './dashboard/reducer';
import type { delayReasonsState } from './widgets/delay-reasons/reducer';
import type { delayResponsibilityState } from './widgets/delay-responsibility/reducer';
import type { ReasonForDelaysState } from './widgets/reason-for-delays/reducer';
import type { ProjectWeeklyProgressState } from './widgets/project-weekly-progress/reducer';
import type { ProjectWeeklyCostProgressState } from './widgets/project-weekly-cost-progress/reducer';
import type { TopMostDelayedActivitiesState } from './widgets/top-most-delayed-activities/reducer';
import type { TopMostDelayedTasksState } from './widgets/top-most-delayed-tasks/reducer';
import type { TopExtendedActivitiesState } from './widgets/top-extended-activities/reducer';
import type { TopExtendedTasksState } from './widgets/top-extended-tasks/reducer';
import type { TopDelayedConstraintsState } from './widgets/top-delayed-constraints/reducer';
import type { TopEarlyFinishedActivitiesState } from './widgets/top-early-finished-activities/reducer';
import type { TopEarlyFinishedTasksState } from './widgets/top-early-finished-tasks/reducer';
import type { TopShortenedActivitiesState } from './widgets/top-shortened-activities/reducer';
import type { TopShortenedTasksState } from './widgets/top-shortened-tasks/reducer';
import type { ProfileState } from './widgets/activity-profile-date/reducer';
import type { ProfileEndState } from './widgets/activity-profile-enddate/reducer';
import type { ProfileDurationState } from './widgets/activity-profile-duration/reducer';
import type { WeeklyActivityProfileDateState } from './widgets/weekly-activity-profile-date/reducer';
import type { WeeklyActivityProfileDurationState } from './widgets/weekly-activity-profile-duration/reducer';
import type { WeeklyActivityProfileEndDateState } from './widgets/weekly-activity-profile-end-date/reducer';
import type { DelayMitigationEffortState } from './widgets/delay-mitigation-effort/reducer';
import type { TopCriticalPathDelayedActivitiesState } from './widgets/top-critical-path-delayed-activities/reducer';
import type { ProjectStatusState } from './widgets/project-status/reducer';
import type { RateOfProgressWeeklyState } from './widgets/rate-progress-weekly/reducer';
import type { ProjectStatusWeeklyState } from './widgets/project-status-weekly/reducer';
import type { ProjectSummaryWeeklyState } from './widgets/project-summary-weekly/reducer';
import type { CprState } from './widgets/cpr-weekly/reducer';
import type { TopDelayReasonsState } from './widgets/top-critical-delay-reasons-weekly/reducer';
import type { ProjectProjectionState } from './widgets/project-projection/reducer';
import type { ActivityDetailState } from './activity-detail/reducer';
import type { TaskDetailState } from './task-detail/reducer';
import type { DelayDetailState } from './delay-detail/reducer';
import type { WeeklyPPCState } from './widgets/weekly-ppc/reducer';
import type { WeeklyDelayMitigationState } from './widgets/weekly-delay-mitigation/reducer';
import type { WeeklyProductivityImprovementState } from './widgets/weekly-productivity-improvement/reducer';
import type { DelaysAndMitigationsState } from './widgets/delays-and-mitigations/reducer';
import type { WeeklyWorkableBacklogState } from './widgets/weekly-workablebacklog/reducer';
import type { WorkableBacklogState } from './widgets/workable-backlog-weekly/reducer';
import type { WorkDelayState } from './widgets/work-delay-widget-weekly/reducer';
import type { ConstraintDelayState } from './widgets/constraint-delay-widget-weekly/reducer';
import type { WeeklyCommitmentReliabilityState } from './widgets/weekly-commitment-reliability/reducer';
import type { CommitmentReliabilityWeeklyState } from './widgets/commitment-reliability-weekly/reducer';
import type { ModalsState } from './modals/reducer';
import type { ProjectState } from './project/reducer';
import type { weeklyState } from './weekly/reducer';
import type { OthersDelaysState } from './others-delays/reducer';

// TODO: Rename types to use upper camel case.
export type Reducers = {
  delayReasons: delayReasonsState,
  delayResponsibility: delayResponsibilityState,
  dashboard: dashboardState,
  reasonForDelays: ReasonForDelaysState,
  projectWeeklyProgress: ProjectWeeklyProgressState,
  projectWeeklyCostProgress: ProjectWeeklyCostProgressState,
  topMostDelayedActivities: TopMostDelayedActivitiesState,
  topMostDelayedTasks: TopMostDelayedTasksState,
  topExtendedActivities: TopExtendedActivitiesState,
  topExtendedTasks: TopExtendedTasksState,
  topDelayedConstraints: TopDelayedConstraintsState,
  topEarlyFinishedActivities: TopEarlyFinishedActivitiesState,
  topEarlyFinishedTasks: TopEarlyFinishedTasksState,
  topShortenedActivities: TopShortenedActivitiesState,
  topShortenedTasks: TopShortenedTasksState,
  activityProfileDate: ProfileState,
  activityProfileEndDate: ProfileEndState,
  activityProfileDuration: ProfileDurationState,
  weekly: weeklyState,
  weeklyActivityProfileDate: WeeklyActivityProfileDateState,
  weeklyActivityProfileDuration: WeeklyActivityProfileDurationState,
  weeklyActivityProfileEndDate: WeeklyActivityProfileEndDateState,
  delayMitigationEffort: DelayMitigationEffortState,
  topCriticalPathDelayedActivities: TopCriticalPathDelayedActivitiesState,
  projectStatus: ProjectStatusState,
  rateOfProgressWeekly: RateOfProgressWeeklyState,
  projectSummaryWeekly: ProjectSummaryWeeklyState,
  projectStatusWeekly: ProjectStatusWeeklyState,
  cprWeekly: CprState,
  topDelayReasonsWeekly: TopDelayReasonsState,
  projectProjection: ProjectProjectionState,
  weeklyPPC: WeeklyPPCState,
  weeklyDelayMitigation: WeeklyDelayMitigationState,
  weeklyProductivityImprovement: WeeklyProductivityImprovementState,
  delaysAndMitigations: DelaysAndMitigationsState,
  weeklyWorkableBacklog: WeeklyWorkableBacklogState,
  workDelay: WorkDelayState,
  constraintDelay: ConstraintDelayState,
  workableBacklog: WorkableBacklogState,
  weeklyCommitmentReliability: WeeklyCommitmentReliabilityState,
  commitmentReliabilityWeekly: CommitmentReliabilityWeeklyState,
  modals: ModalsState,
  activityDetail: ActivityDetailState,
  taskDetail: TaskDetailState,
  delayDetail: DelayDetailState,
  project: ProjectState,
  othersDelays: OthersDelaysState
}

export default combineReducers({
  delayReasons,
  delayResponsibility,
  dashboard,
  reasonForDelays,
  projectWeeklyProgress,
  projectWeeklyCostProgress,
  topMostDelayedActivities,
  topMostDelayedTasks,
  topExtendedActivities,
  topExtendedTasks,
  topDelayedConstraints,
  topEarlyFinishedActivities,
  topEarlyFinishedTasks,
  topShortenedActivities,
  topShortenedTasks,
  activityProfileDate,
  activityProfileEndDate,
  activityProfileDuration,
  weekly,
  weeklyActivityProfileDate,
  weeklyActivityProfileDuration,
  weeklyActivityProfileEndDate,
  delayMitigationEffort,
  topCriticalPathDelayedActivities,
  projectStatus,
  projectStatusWeekly,
  rateOfProgressWeekly,
  projectSummaryWeekly,
  cprWeekly,
  topDelayReasonsWeekly,
  projectProjection,
  weeklyPPC,
  weeklyDelayMitigation,
  weeklyProductivityImprovement,
  delaysAndMitigations,
  weeklyWorkableBacklog,
  workDelay,
  constraintDelay,
  workableBacklog,
  weeklyCommitmentReliability,
  commitmentReliabilityWeekly,
  modals,
  activityDetail,
  taskDetail,
  project,
  othersDelays,
  delayDetail,
});
