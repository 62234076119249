// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getResponsibilityOfDelayReasonsApi from './apis';

type DelayResponsibilityResponseSuccessType = {
  responsibility: {
    [key: string]: {
      value: number,
      project_delay: {
        [key: string]: number,
      },
    },
  }
};

export type DelayResponsibilityAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_RESPONSIBILITY_OF_DELAY_REASONS_SUCCESS'>,
  data: DelayResponsibilityResponseSuccessType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_RESPONSIBILITY_OF_DELAY_REASONS_FAIL'>,
|};


function* getResponsibilityOfDelayReasons(action: { data: { projectId: string, filter: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DelayResponsibilityResponseSuccessType } = yield call(getResponsibilityOfDelayReasonsApi, action.data.projectId, action.data.filter);
    yield put({ type: ActionTypes.GET_RESPONSIBILITY_OF_DELAY_REASONS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_RESPONSIBILITY_OF_DELAY_REASONS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_RESPONSIBILITY_OF_DELAY_REASONS, getResponsibilityOfDelayReasons);
}
