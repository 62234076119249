// @flow
import ActionTypes from './action-types';
import type { TopEarlyFinishedTasksAction, EarlyFinishedTask } from './sagas';

export type TopEarlyFinishedTasksState = {
  chartData: EarlyFinishedTask[],
  loading: boolean | null,
};

const initialState: TopEarlyFinishedTasksState = { loading: null, chartData: [] };

export default function (state: TopEarlyFinishedTasksState = initialState, action: TopEarlyFinishedTasksAction): TopEarlyFinishedTasksState {
  switch (action.type) {
    case ActionTypes.GET_TOP_EARLY_FINISHED_TASKS_SUCCESS:
      return {
        ...state,
        chartData: action.data.tasks,
        loading: false,
      };
    default:
      return state;
  }
}
