// @flow

import * as React from 'react';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ResponsivePieChart from '../../components/charts/pie';
import ActionTypes from './action-types';
import SelectBox from '../../components/select-box';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import ExportButton from '../../components/export';

import type { CommitmentReliabilityWeeklyState } from './reducer';
import type { CommitmentReliabilityResponse } from './sagas';
import type { DataItem } from '../../components/charts/pie';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import './styles.css';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: CommitmentReliabilityWeeklyState,
  project: Project,
  week: string,
  widgetStyle: Object,
};

const colorConfig: Object = {
  plan_reliability: ['#6fac71', '#dedede'],
  constraint_management: ['#484848', '#dedede'],
  site_readiness: ['#3777b0', '#dedede'],
  commitment_level: ['#ff6500', '#dedede'],
};

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  selectBoxContainer: {
    maxWidth: '350px',
  },
  selectBoxCompany: {
    margin: '0 1% 0 4%',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '350px',
  },
  note: {
    display: 'block',
    fontSize: '0.8rem',
    marginTop: '30px',
  },
};

type State = {
  companyId: number | string,
};

const getLabel: Object = {
  plan_reliability: 'Did / Should do',
  constraint_management: 'Can do / Should do',
  site_readiness: 'Will do / Can do',
  commitment_level: 'Did / Will do',
};

const getLabelNames: Object = {
  plan_reliability: 'Shortfall',
  constraint_management: 'Cannot do',
  site_readiness: 'Will not do',
  commitment_level: 'Did not do',
};

class CommitmentReliabilityWeekly extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      companyId: 'all_companies',
    };
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onCompanyChange = this.onCompanyChange.bind(this);

  onCompanyChange(e: Object) {
    this.setState({
      companyId: e.value,
    });
  }

  getFormattedData(
    type: string,
    data: CommitmentReliabilityResponse,
  ): DataItem[] {
    const traces: DataItem[] = [
      {
        label: getLabel[type],
        value: data[type],
        text: `${data[type]}%`,
      },
      {
        label: getLabelNames[type],
        value: 100 - data[type],
        text: `${(100 - data[type]).toFixed(1)}%`,
      },
    ];

    return traces;
  }

  getCompanies = this.getCompanies.bind(this);

  getCommitmentReliabilityGraph = this.getCommitmentReliabilityGraph.bind(this);

  getCommitmentReliabilityGraph(companyId: number): React.Node {
    const companies: Object[] = this.props.data.data.map(
      (c: CommitmentReliabilityResponse): Object => ({
        label:
          c.company_id === 'all_companies' ? 'All Companies' : c.company_name,
        value: c.company_id,
      }),
    );
    // companies.unshift({
    //   label: 'All companies',
    //   value: 'all_companies',
    // });
    const index: number = companies.findIndex(
      (obj: Object): boolean => obj.value === companyId,
    );
    const data: CommitmentReliabilityResponse = this.props.data.data[index];
    return (
      <div>
        <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="cpr_widget" />
        <div style={styles.selectBoxContainer}>
          {this.getCompanies(companies)}
        </div>
        <div className="container-commitment-reliability">
          <div className="cpr-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('plan_reliability', data)}
              dataOptions={this.getDataOptions('plan_reliability')}
              options={this.getOptions('plan_reliability', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#6fac71' }}
              />
              <div className="legend-commitment-reliability">
                Construction plan reliability (CPR)
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('constraint_management', data)}
              dataOptions={this.getDataOptions('constraint_management')}
              options={this.getOptions('constraint_management', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#484848' }}
              />
              <div className="legend-commitment-reliability">
                Constraint management level
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('site_readiness', data)}
              dataOptions={this.getDataOptions('site_readiness')}
              options={this.getOptions('site_readiness', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#3777b0' }}
              />
              <div className="legend-commitment-reliability">
                Site readiness level
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('commitment_level', data)}
              dataOptions={this.getDataOptions('commitment_level')}
              options={this.getOptions('commitment_level', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#ff6500' }}
              />
              <div className="legend-commitment-reliability">
                Commitment level
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCompanies(companies: Object[]): React.Node {
    return (
      <SelectBox
        options={companies}
        value={this.state.companyId}
        onChange={this.onCompanyChange}
      />
    );
  }

  getDataOptions(str: string): Object {
    return {
      hole: 0.8,
      sort: false,
      textinfo: 'none',
      marker: {
        colors: colorConfig[str],
        // line: {
        //   color: 'white',
        //   width: 4,
        // },
      },
    };
  }

  getOptions(type: string, data: CommitmentReliabilityResponse): Object {
    return {
      hideDownloadImage: 'hideDownloadImage',
      height: 250,
      width: type === 'plan_reliability' ? 200 : 150,
      margin: {
        t: 10,
        l: 0,
        r: 20,
        b: 10,
      },
      showlegend: false,
      annotations: [
        {
          x: 0.5,
          y: 0.5,
          text: `${data[type]}%`,
          showarrow: false,
          font: {
            size: type === 'plan_reliability' ? 22 : 14,
          },
        },
      ],
    };
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_COMMITMENT_RELIABILITY,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    if (isEmpty(this.props.data.data) && this.props.data.CommitmentReliabilityLoading === false) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      this.getCommitmentReliabilityGraph(this.state.companyId)
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    return (
      <section style={this.props.widgetStyle}>
        <h1 className="header-widget-common">
          Commitment and Reliability
          <img
            data-tip="This graph presents the weekly reliablity & commitment levels of the project team in that week.<br />Constraint management level (Can do / Should Do) describes how well constraints are identified and managed.<br />Site readiness level (Will Do / Can Do) depicts how ready the site team to take up the planned jobs.<br />Commitment level (PPC - Did / Will Do) represents how well the site team can keep their commitment, and<br />Construct Plan Reliability (CPR - Did / Should Do) describes the overall reliability of the plan.<br />The higher these indicators are, the better performance is expected"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.CommitmentReliabilityLoading === null ? <Loader /> : this.renderWidget()
        }
        <span style={styles.note}>*Data available from March 2018</span>
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: CommitmentReliabilityWeeklyState } => ({
    data: state.commitmentReliabilityWeekly,
  }),
)(CommitmentReliabilityWeekly);

Component.style = {
  width: '98%',
};

export default Component;
