// @flow

import ActionTypes from './action-types';
import type { ProjectWeeklyProgressAction, ProjectWeeklyProgress } from './sagas';

export type ProjectWeeklyProgressState = {
  chartData: ProjectWeeklyProgress[],
  projectStart: string | null,
  loading: boolean | null,
};

const initialState: ProjectWeeklyProgressState = { loading: null, chartData: [], projectStart: null };

export default function (state: ProjectWeeklyProgressState = initialState, action: ProjectWeeklyProgressAction): ProjectWeeklyProgressState {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_WEEKLY_PROGRESS_SUCCESS:
      return {
        ...state,
        chartData: action.data.weekly_progress,
        projectStart: action.data.project_start_date,
        loading: false,
      };
    case ActionTypes.GET_PROJECT_WEEKLY_PROGRESS_FAIL:
      return {
        ...state,
        chartData: [],
        projectStart: null,
        loading: false,
      };
    default:
      return state;
  }
}
