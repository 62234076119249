// @flow

export type WeeklyActivityProfileActionTypes = {
  GET_WEEKLY_DURATION_PROFILE: 'getWeeklyDurationProfile',
  GET_WEEKLY_DURATION_PROFILE_SUCCESS: 'getWeeklyDurationProfileSuccess',
  GET_WEEKLY_DURATION_PROFILE_FAIL: 'getWeeklyDurationProfileFail',
}

const actionTypes: WeeklyActivityProfileActionTypes = {
  GET_WEEKLY_DURATION_PROFILE: 'getWeeklyDurationProfile',
  GET_WEEKLY_DURATION_PROFILE_SUCCESS: 'getWeeklyDurationProfileSuccess',
  GET_WEEKLY_DURATION_PROFILE_FAIL: 'getWeeklyDurationProfileFail',
};

export default actionTypes;
