// @flow
import ActionTypes from './action-types';
import type {
  PROJECT_ACTIONS_TYPE, PROJECT_RESPONSE_TYPE, PROJECT_ALL_WEEKS_RESPONSE_TYPE,
} from './sagas';

type CompanyItem = {
  id: string,
  name: string,
};
export type CompanyListType = ?(CompanyItem[]);

export type ProjectState = {
  companies: any | null,
  loading: boolean | null,
  projectDetails: PROJECT_RESPONSE_TYPE | null,
  projectAllWeeks: PROJECT_ALL_WEEKS_RESPONSE_TYPE,
};

// const getSortedCompanyList: Function = function getSortedCompanyList(data: COMPANY_LIST_RESPONSE_TYPE): CompanyListType {
//   return Object.keys(data).map((id: string): Object => ({ id, name: data[id] }));
// };

const initialState: ProjectState = {
  companies: null,
  loading: null,
  projectDetails: null,
  projectAllWeeks: [],
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ProjectState = initialState, action: PROJECT_ACTIONS_TYPE): Exact<ProjectState> {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.data,
        loading: false,
      };
    case ActionTypes.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.data.project,
        loading: false,
      };
    case ActionTypes.GET_ALL_WEEKS_SUCCESS:
      return {
        ...state,
        projectAllWeeks: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
