// @flow

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'ramda';
import ReactTooltip from 'react-tooltip';

import colors from '../../styles/colors';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

import type { ProjectProjectionState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import './styles.css';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;


type Props = {
  dispatch: Dispatch,
  data: ProjectProjectionState,
  project: Project,
}

const styles: Object = {
  header: {
    // fontSize: '1.2rem',
    // textAlign: 'center',
    marginBottom: '2rem',
  },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  statusField: {
    borderColor: colors.themeSecondaryColor,
  },
  rightLabel: {
    width: 158,
  },
  statusHeading: {
    position: 'absolute',
    top: -18,
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
  },
  largeLabel: {
    marginLeft: 8,
    width: 68,
    textAlign: 'center',
    marginTop: -4,
  },
};


class ProjectProjectionWidget extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_PROJECT_PROJECTION,
      data: { projectId: this.props.project.id },
    });
  }

  renderWidget(): React$Element<*> {
    const data: Object = this.props.data.data;
    if (isEmpty(data)) {
      return <span />;
    }
    return (
      <div className="project-status-widget">
        <div className="status-block dates">
          <div style={styles.statusField} className="status-field dates">
            <div className="top">
              <div className="content">
                <div className="date-field">{data.projected_end_date === 'NA' ? 'NA' : moment(data.projected_end_date).date()}</div>
                <div className="month-field">{data.projected_end_date === 'NA' ? '' : moment(data.projected_end_date).format('MMM YY')}</div>
              </div>
            </div>
            <div className="bottom" style={styles.statusFieldBottom}>
              <div className="content">
                <div className="date-field">{data.expected_end_date === 'NA' ? 'NA' : moment(data.expected_end_date).date()}</div>
                <div className="month-field">{data.expected_end_date === 'NA' ? '' : moment(data.expected_end_date).format('MMM YY')}</div>
              </div>
            </div>
          </div>
          <div className="h-separator">
            <div className="separation-text top">Projected</div>
            <div className="separation-text bottom">Expected</div>
          </div>
        </div>
        <div className="status-block status">
          <div style={styles.statusHeading}>Delay</div>
          <div style={styles.statusField} className="status-field status">
            <div className="top">
              <div className="content">
                {data.projected_delay}
                <div>weeks</div>
              </div>
            </div>
            <div className="bottom" style={styles.statusFieldBottom}>
              <div className="content">
                {data.expected_delay}
                <div>weeks</div>
              </div>
            </div>
          </div>
          <div className="h-separator centre" />

          <div className="v-separator">
            <div className="left">
              <span style={{ marginRight: 8 }}>Completion</span>
              <div className="arrow left float-r">
                <div className="arrow-head" />
                <div className="arrow-line" />
              </div>
            </div>
            <div className="right">
              <div className="arrow right float-l">
                <div className="arrow-line" />
                <div className="arrow-head" />
              </div>
              <div style={styles.largeLabel}>Rate of Progress</div>
            </div>
          </div>
        </div>
        <div className="status-block">
          <div style={styles.statusField} className="status-field progress">
            <div className="top">
              <div className="content">
                {data.required_rate === 'NA' ? 'NA' : `${parseFloat(data.required_rate).toFixed(2)} %`}
                <div>
                  / week
                  {this.props.offsetPercentage !== null ? '*' : ''}
                </div>
              </div>
            </div>
            <div className="bottom" style={styles.statusFieldBottom}>
              <div className="content">
                {data.current_progress_rate === 'NA' ? 'NA' : `${parseFloat(data.current_progress_rate).toFixed(2)} %`}
                <div>
                  / week
                  {this.props.offsetPercentage !== null ? '*' : ''}
                </div>
              </div>
            </div>
          </div>
          <div className="h-separator right" style={styles.rightLabel}>
            <div className="separation-text top">Required</div>
            <div className="separation-text bottom">Current</div>
          </div>
        </div>
      </div>
    );
  }

  render(): React$Element<*> {
    const data: Object = this.props.data.data;
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common" style={styles.header}>
Projected Completion
          <img data-tip="Projected completion dates based on the current rate of progress per week since the last few weeks.<br />Also shows the required rate of progress per week to meet the planned date of completion" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget()
        }
        {
          !isEmpty(data)
            ? <Fragment>
                <div style={{ fontSize: '0.8rem', marginTop: 8 }}>
                  {`* This value is based on the median of last ${data.lookahead_size} weeks data`}
                </div>
                {
                  this.props.offsetPercentage
                  && <div style={{ fontSize: '0.8rem', marginTop: 8 }}>
                      * Rate values are recalculated based on already completed percentage of the project provided in the settings
                     </div>
                }
              </Fragment>
            : null
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ProjectProjectionState } => ({
    data: state.projectProjection,
    offsetPercentage: state.project.projectDetails.preferences.general.offset_percentage,
  }),
)(ProjectProjectionWidget);

component.style = {
  width: '98%',
};

export default component;
