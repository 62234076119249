// @flow

import * as React from 'react';
import './candle-bar.css';

const styles: Object = {
  rectangle: {
    position: 'absolute',
    top: '40px',
    height: '55px',
    width: '20px',
  },
  labelValues: {
    color: '#484848',
    fontSize: '14px',
    position: 'relative',
  },
  labelData: {
    left: '90px',
    fontSize: '15px',
  },
  candleBar: {
    position: 'relative',
    left: '36px',
    width: '200px',
  },
  max: {
    position: 'absolute',
    top: '-4px',
  },
  med: {
    color: '#484848',
    fontWeight: 'bold',
    position: 'absolute',
    top: '57px',
  },
  min: {
    position: 'absolute',
    top: '118px',
  },
};

type Props = {
  color: string,
  data: Object
};

// const CandleBar: Function = function candleBar(): React.Node {
const CandleBar: Function = function candleBar(props: Props): React.Node {
  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.labelValues}>
        <div style={styles.max}>Max</div>
        <div style={styles.med}>Med</div>
        <div style={styles.min}>Min</div>
      </div>
      <div style={{ ...styles.labelValues, ...styles.labelData }}>
        <div style={styles.max}>
          {props.data ? props.data.max : null}
          %
        </div>
        <div style={styles.med}>
          {props.data ? props.data.median : null}
          %
        </div>
        <div style={styles.min}>
          {props.data ? props.data.min : null}
          %
        </div>
      </div>
      <div style={styles.candleBar}>
        <div className="horizontal-line" />
        <div className="horizontal-line" style={{ marginTop: '64px', borderColor: props.color }} />
        <div className="horizontal-line" style={{ marginTop: '128px' }} />
        <div className="vertical-line" style={{ height: '130px' }} />
        <div style={{ ...styles.rectangle, backgroundColor: props.color }} />
      </div>
    </div>
  );
};

export default CandleBar;
