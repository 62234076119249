// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getRateOfProgressApi from './apis';

export type RateOfProgressSuccessType = {
  actual_progress_rate: number,
  required_progress_rate: number,
  planned_progress_rate: number,
};

export type RateOfProgressAction = {|
  type: typeof ActionTypes.GET_RATE_OF_PROGRESS_SUCCESS,
  data: RateOfProgressSuccessType,
|} | {|
  type: typeof ActionTypes.GET_RATE_OF_PROGRESS_FAIL,
|};

function* getRateOfProgress(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: RateOfProgressSuccessType } = yield call(getRateOfProgressApi, action.data.projectId, action.data.startDate);
    const actionData: RateOfProgressAction = { type: ActionTypes.GET_RATE_OF_PROGRESS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: RateOfProgressAction = { type: ActionTypes.GET_RATE_OF_PROGRESS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_RATE_OF_PROGRESS, getRateOfProgress);
}
