// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { ActivityProfileAction, DurationProfile } from './sagas';

export type WeeklyActivityProfileDurationItem = {
  asPlanned: number,
  extended: number,
  shortened: number,
  endDate: string,
  startDate: string,
};


export type WeeklyActivityProfileDurationState = {
  weeklyDurationProfile: WeeklyActivityProfileDurationItem[],
  weeklyDurationProfileLoading: boolean | null,
};

const initialState: WeeklyActivityProfileDurationState = {
  weeklyDurationProfileLoading: null,
  weeklyDurationProfile: [],
};

const formatData: Function = function formatData(data: DurationProfile[]): WeeklyActivityProfileDurationItem[] {
  return map((item: DurationProfile): WeeklyActivityProfileDurationItem => (
    {
      asPlanned: item.as_planned,
      extended: item.extended,
      shortened: item.shortened,
      endDate: item.end_date,
      startDate: item.start_date,
    }
  ), data);
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyActivityProfileDurationState = initialState, action: ActivityProfileAction): Exact<WeeklyActivityProfileDurationState> {
  switch (action.type) {
    case ActionTypes.GET_WEEKLY_DURATION_PROFILE_SUCCESS:
      return {
        ...state,
        weeklyDurationProfile: formatData(action.data.profiles),
        weeklyDurationProfileLoading: false,
      };
    default:
      return state;
  }
}
