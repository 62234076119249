// @flow

export type ActivityProfileActionTypes = {
  GET_DURATION_PROFILE: 'getDurationProfile',
  GET_DURATION_PROFILE_SUCCESS: 'getDurationProfileSuccess',
  GET_DURATION_PROFILE_FAIL: 'getDurationProfileFail',
  GET_DURATION_PROFILE_TASK: 'getDurationProfileTask',
  GET_DURATION_PROFILE_TASK_SUCCESS: 'getDurationProfileTaskSuccess',
  GET_DURATION_PROFILE_TASK_FAIL: 'getDurationProfileTaskFail',
}

const actionTypes: ActivityProfileActionTypes = {
  GET_DURATION_PROFILE: 'getDurationProfile',
  GET_DURATION_PROFILE_SUCCESS: 'getDurationProfileSuccess',
  GET_DURATION_PROFILE_FAIL: 'getDurationProfileFail',
  GET_DURATION_PROFILE_TASK: 'getDurationProfileTask',
  GET_DURATION_PROFILE_TASK_SUCCESS: 'getDurationProfileTaskSuccess',
  GET_DURATION_PROFILE_TASK_FAIL: 'getDurationProfileTaskFail',
};

export default actionTypes;
