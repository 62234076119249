// @flow
import { isEmpty } from 'ramda';

import ActionTypes from './action-types';
import type { RateOfProgressAction, RateOfProgressSuccessType } from './sagas';

export type RateOfProgressWeeklyState = {
  data: RateOfProgressSuccessType,
  RateOfProgressLoading: boolean | null,
};

const initialState: RateOfProgressWeeklyState = {
  RateOfProgressLoading: null,
  data: {
    actual_progress_rate: 0,
    required_progress_rate: 0,
    planned_progress_rate: 0,
  },
};

const formatData: Function = function formatData(data: RateOfProgressSuccessType): RateOfProgressSuccessType {
  if (isEmpty(data)) {
    return {
      actual_progress_rate: 0,
      required_progress_rate: 0,
      planned_progress_rate: 0,
    };
  }
  return data;
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: RateOfProgressWeeklyState = initialState, action: RateOfProgressAction): Exact<RateOfProgressWeeklyState> {
  let dp: RateOfProgressSuccessType;

  switch (action.type) {
    case ActionTypes.GET_RATE_OF_PROGRESS_SUCCESS:
      dp = formatData(action.data);
      return {
        ...state,
        data: dp,
        RateOfProgressLoading: false,
      };
    default:
      return state;
  }
}
