// @flow
import { map, takeLast } from 'ramda';

import ActionTypes from './action-types';
import type { DelaysAndMitigationsAction, DelaysAndMitigations } from './sagas';

export type DelaysAndMitigationsItem = {
  actualAdvances: number,
  actualDelays: number,
  endDate: string,
  forecastAdvances: number,
  forecastDelays: number,
  startDate: string,
  totalAdvances: number,
  totalDelays: number,
  // delaySum: number,
  projectBaseEndDate: string | null,
  projectExpectedEndDate: string | null,
  effectiveDelay: number
};

export type DelaysAndMitigationsState = {
  delaysAndMitigations: DelaysAndMitigationsItem[],
  delaysAndMitigationsLoading: boolean | null,
};

const initialState: DelaysAndMitigationsState = {
  delaysAndMitigationsLoading: null,
  delaysAndMitigations: [],
};

const formatData: Function = function formatData(data: DelaysAndMitigations): DelaysAndMitigationsItem[] {
  return map(
    (item: DelaysAndMitigations): DelaysAndMitigationsItem => ({
      actualAdvances: item.actual_advances,
      actualDelays: item.actual_delays,
      endDate: item.end_date,
      forecastAdvances: item.forecast_advances,
      forecastDelays: item.forecast_delays,
      startDate: item.start_date,
      totalAdvances: item.total_advances,
      totalDelays: item.total_delays,
      // delaySum: item.delay_sum,
      // distanceFromBase: item.distance_from_base,
      projectBaseEndDate: item.project_base_end_date,
      projectExpectedEndDate: item.project_expected_end_date,
      effectiveDelay: item.effective_delay,
    }),
    takeLast(20, data),
  );
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: DelaysAndMitigationsState = initialState, action: DelaysAndMitigationsAction): Exact<DelaysAndMitigationsState> {
  let dp: DelaysAndMitigationsItem[];

  switch (action.type) {
    case ActionTypes.GET_DELAYS_AND_MITIGATIONS_SUCCESS:
      dp = formatData(action.data.project_delays);
      return {
        ...state,
        delaysAndMitigations: dp,
        delaysAndMitigationsLoading: false,
      };
    default:
      return state;
  }
}
