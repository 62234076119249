// @flow

export type ActivityWorkableBacklogTypes = {
  GET_WORKABLE_BACKLOG: 'getWorkableBacklogWeekly',
  GET_WORKABLE_BACKLOG_SUCCESS: 'getWorkableBacklogSuccessWeekly',
  GET_WORKABLE_BACKLOG_FAIL: 'getWorkableBacklogFailWeekly',
}

const actionTypes: ActivityWorkableBacklogTypes = {
  GET_WORKABLE_BACKLOG: 'getWorkableBacklogWeekly',
  GET_WORKABLE_BACKLOG_SUCCESS: 'getWorkableBacklogSuccessWeekly',
  GET_WORKABLE_BACKLOG_FAIL: 'getWorkableBacklogFailWeekly',
};

export default actionTypes;
