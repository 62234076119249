// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import ExportButton from '../../components/export';

import ActionTypes from './action-types';

import type { TopDelayedConstraintsState } from './reducer';
import type { DelayedConstraint } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopDelayedConstraintsState,
  project: Project,
  week: string,
};

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  taskNameTable: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  artifactName: {
    fontSize: '0.75rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '180',
  '2': '230',
  '3': '275',
  '4': '325',
  '5': '375',
  '6': '423',
  '7': '472',
  '8': '520',
  '9': '568',
  '10': '617',
};
/* eslint-enable */

class TopDelayedConstraints extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.fetchData();
    }
  }

  getData(): ChartData[] {
    return this.props.data.chartData.map(
      (constraint: DelayedConstraint, index: number): ChartData => ({
        label: `${constraint.name} - ${constraint.artifact} ${index}`,
        value: parseInt(constraint.delay, 10),
        color: colors.themeSecondaryColor,
        text:
          `${constraint.name.trim()}<br>${
            constraint.type === 'activity_constraint' ? 'Activity' : 'Task'
          }: ${constraint.artifact}<br>`
          + `Delay: ${constraint.delay}<br>Project delay: ${
            constraint.project_delay === null ? 'NA' : constraint.project_delay
          }`,
      }),
    );
  }

  showTaskDetailModal = (task: DelayedConstraint) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'taskDetail',
      modalProps: {
        activityId: task.activity_id,
        taskId: task.task_id,
        taskName: task.artifact,
      },
    });
  };

  showActivityDetailModal = (activity: DelayedConstraint) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.activity_id,
        activityName: activity.artifact,
      },
    });
  };

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_DELAYED_CONSTRAINTS,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderTable(): React.Element<any> {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.taskNameTable}>Constraint</th>
            <th className="hide-element">
              Delay
              <br />
              (days)
            </th>
            <th>
              Project delayed by
              <br />
              (days)
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.chartData.map(
            (constraint: DelayedConstraint, i: number): React.Node => (
              <tr key={i}>
                <td style={styles.taskNameTable}>
                  {constraint.name}
                  <div style={styles.artifactName}>
                    {constraint.type === 'activity_constraint' ? (
                      <a
                        onClick={this.showActivityDetailModal.bind(
                          this,
                          constraint,
                        )}
                        className="underline-hover"
                      >
                        <b>
                          {constraint.type === 'activity_constraint'
                            ? 'Activity'
                            : 'Task'}
                        </b>
                        -
                        {constraint.artifact}
                      </a>
                    ) : (
                      <a
                        onClick={this.showTaskDetailModal.bind(
                          this,
                          constraint,
                        )}
                        className="underline-hover"
                      >
                        <b>
                          {constraint.type === 'activity_constraint'
                            ? 'Activity'
                            : 'Task'}
                        </b>
                        -
                        {constraint.artifact}
                      </a>
                    )}
                  </div>
                </td>
                <td className="hide-element">{constraint.delay}</td>
                <td>
                  {constraint.project_delay === null
                    ? 'NA'
                    : constraint.project_delay}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  }

  renderWidget(options: Object): React.Node {
    return this.props.data.chartData.length !== 0
      ? (
        <div>
          { this.props.week ? <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="top_delayed_constraints" /> : null }
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '60%' }}>
              {this.renderTable()}
            </div>
            <div style={{ width: '40%' }}>
              <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h' }} />
            </div>
          </div>
        </div>)
      : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    const options: Object = {
      height: getHeight[this.props.data.chartData.length],
      yaxis: {
        showticklabels: false,
      },
      xaxis: {
        title: 'Days',
      },
      margin: {
        t: 55,
        r: 10,
        l: 3,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Top Delayed Constraints
          <img
            data-tip="This chart lists the top delayed constraints sorted based on the total delays in that constraint"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget(options)
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: TopDelayedConstraintsState } => ({
    data: state.topDelayedConstraints,
  }),
)(TopDelayedConstraints);

component.style = {
  width: '98%',
};

export default component;
