// @flow

import * as React from 'react';
import Plotly from 'plotly.js/lib/index-basic';

export default function withResponsive<PropsInput: {}>(
  Component: React.ComponentType<{ onRenderChart: (Object) => mixed } & $Supertype<PropsInput>>,
): React.ComponentType<PropsInput> {
  return class ResponsiveChart extends React.PureComponent<PropsInput> {
    componentDidMount() {
      window.addEventListener('resize', this.windowResizeListener);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.windowResizeListener);
    }

    windowResizeListener = () => {
      if (this.chart) {
        Plotly.Plots.resize(this.chart);
      }
    }

    onRenderChart = (chart: Object) => {
      this.chart = chart;
    }

    chart: Object;

    render(): React.Node {
      return (
        <div>
          <Component {...this.props} onRenderChart={this.onRenderChart} />
        </div>
      );
    }
  };
}
