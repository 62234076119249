// @flow

export type ActivityProfileDateActionTypes = {
  GET_DATE_PROFILE: 'getDateProfile',
  GET_DATE_PROFILE_SUCCESS: 'getDateProfileSuccess',
  GET_DATE_PROFILE_FAIL: 'getDateProfileFail',
  GET_DATE_PROFILE_TASK: 'getDateProfileTask',
  GET_DATE_PROFILE_TASK_SUCCESS: 'getDateProfileTaskSuccess',
  GET_DATE_PROFILE_TASK_FAIL: 'getDateProfileTaskFail',
}

const actionTypes: ActivityProfileDateActionTypes = {
  GET_DATE_PROFILE: 'getDateProfile',
  GET_DATE_PROFILE_SUCCESS: 'getDateProfileSuccess',
  GET_DATE_PROFILE_FAIL: 'getDateProfileFail',
  GET_DATE_PROFILE_TASK: 'getDateProfileTask',
  GET_DATE_PROFILE_TASK_SUCCESS: 'getDateProfileTaskSuccess',
  GET_DATE_PROFILE_TASK_FAIL: 'getDateProfileTaskFail',
};

export default actionTypes;
