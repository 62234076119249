// @flow
import { filter } from 'ramda';
import ActionTypes from './action-types';
import type { DelayResponsibilityAction } from './sagas';

type DelayResponsibilityItem = {
  label: string,
  value: number,
  color?: string,
  text?: string,
};
export type delayResponsibilityState = {
  chartData: DelayResponsibilityItem[],
  loading: boolean | null,
};

const getOrderedList: Function = function getOrderedList(data: DelayResponsibilityItem): DelayResponsibilityItem[] {
  const filteredList: DelayResponsibilityItem = filter((item: Object): boolean => item.value * 100 > 0, data);
  const list: DelayResponsibilityItem[] = Object.keys(filteredList).map((key: string): DelayResponsibilityItem => {
    const projectDelay: number = Object.keys(data[key].project_delay).reduce((acc: number, delay: number): number => acc + data[key].project_delay[delay], 0);

    return {
      label: key,
      value: data[key].value,
      text: `Project delayed by ${projectDelay} day(s)`,
    };
  });
  return list.sort((item1: DelayResponsibilityItem, item2: DelayResponsibilityItem): number => (
    item1.value < item2.value ? 1 : -1
  ));
};

const initialState: delayResponsibilityState = { loading: null, chartData: [] };

export default function (state: delayResponsibilityState = initialState, action: DelayResponsibilityAction): delayResponsibilityState {
  switch (action.type) {
    case ActionTypes.GET_RESPONSIBILITY_OF_DELAY_REASONS_SUCCESS:
      return {
        ...state,
        chartData: getOrderedList(action.data.responsibility),
        loading: false,
      };
    default:
      return state;
  }
}
