// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { ActivityProfileAction, DurationProfile } from './sagas';

export type ProfileDurationItem = {
  id: string,
  value: number,
  percentage: number,
  totalDays: ?number,
};

export type ProfileDurationState = {
  durationProfile: ProfileDurationItem[],
  durationProfileTask: ProfileDurationItem[],
  durationProfileLoading: boolean | null,
};

const initialState: ProfileDurationState = {
  durationProfileLoading: null,
  durationProfile: [],
  durationProfileTask: [],
};

const formatData: Function = function formatData(data: DurationProfile): ProfileDurationItem[] {
  return map((key: string): ProfileDurationItem => (
    {
      id: key,
      value: data[key].value,
      percentage: data[key].percentage,
      totalDays: data[key].days,
    }
  ), Object.keys(data));
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ProfileDurationState = initialState, action: ActivityProfileAction): Exact<ProfileDurationState> {
  switch (action.type) {
    case ActionTypes.GET_DURATION_PROFILE_SUCCESS:
      return {
        ...state,
        durationProfile: formatData(action.data.activities),
        durationProfileLoading: false,
      };
    case ActionTypes.GET_DURATION_PROFILE_TASK_SUCCESS:
      return {
        ...state,
        durationProfileTask: formatData(action.data.tasks),
        durationProfileLoading: false,
      };
    default:
      return state;
  }
}
