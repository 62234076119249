// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { ActivityProfileAction, EndDateProfile } from './sagas';

export type ProfileItem = {
  id: string,
  value: number,
  percentage: number,
};

export type ProfileEndState = {
  dateProfile: ProfileItem[],
  dateProfileTask: ProfileItem[],
  dateProfileLoading: boolean | null,
};

const initialState: ProfileEndState = {
  dateProfileLoading: null,
  dateProfile: [],
  dateProfileTask: [],
};

const formatData: Function = function formatData(data: EndDateProfile): ProfileItem[] {
  return map((key: string): ProfileItem => (
    {
      id: key,
      value: data[key].value,
      percentage: data[key].percentage,
    }
  ), Object.keys(data));
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ProfileEndState = initialState, action: ActivityProfileAction): Exact<ProfileEndState> {
  let dp: ProfileItem[];

  switch (action.type) {
    case ActionTypes.GET_END_DATE_PROFILE_SUCCESS:
      dp = formatData(action.data.activities);
      return {
        ...state,
        dateProfile: dp,
        dateProfileLoading: false,
      };
    case ActionTypes.GET_END_DATE_PROFILE_TASK_SUCCESS:
      dp = formatData(action.data.tasks);
      return {
        ...state,
        dateProfileTask: dp,
        dateProfileLoading: false,
      };
    default:
      return state;
  }
}
