// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import 'reset-css';
import './index.css';

import App from './app';
import registerServiceWorker from './registerServiceWorker';

const root: any = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(<App />, root);
}

registerServiceWorker();
