// @flow

type ProjectWeeklyProgress = {
  GET_PROJECT_WEEKLY_COST_PROGRESS: 'getProjectWeeklyCostProgress',
  GET_PROJECT_WEEKLY_COST_PROGRESS_SUCCESS: 'getProjectWeeklyCostProgressSuccess',
  GET_PROJECT_WEEKLY_COST_PROGRESS_FAIL: 'getProjectWeeklyCostProgressFail',
}

const actionTypes: ProjectWeeklyProgress = {
  GET_PROJECT_WEEKLY_COST_PROGRESS: 'getProjectWeeklyCostProgress',
  GET_PROJECT_WEEKLY_COST_PROGRESS_SUCCESS: 'getProjectWeeklyCostProgressSuccess',
  GET_PROJECT_WEEKLY_COST_PROGRESS_FAIL: 'getProjectWeeklyCostProgressFail',
};

export default actionTypes;
