// @flow
import ActionTypes from './action-types';
import type { WorkDelayAction, WorkDelay } from './sagas';
import DelayDetailActions from '../../delay-detail/action-types';
import type { DelayDetailAction } from '../../delay-detail/sagas';


export type WorkDelayState = {
  chartData: WorkDelay[],
  loading: boolean | null,
};

const initialState: WorkDelayState = { loading: null, chartData: [] };

export default function (state: WorkDelayState = initialState, action: WorkDelayAction | DelayDetailAction): WorkDelayState {
  switch (action.type) {
    case ActionTypes.GET_WORK_DELAY_SUCCESS:
      return {
        ...state,
        chartData: action.data.delays,
        loading: false,
      };

    case DelayDetailActions.UPDATE_DELAY_HISTORY_SUCCESS:
      // $FlowFixMe
      const delayItemIndex: number = state.chartData.findIndex((d: WorkDelay): boolean => d.id === action.data.delay.id);
      if (delayItemIndex === -1) {
        return { ...state };
      }
      const chartData: WorkDelay[] = [...state.chartData];
      chartData[delayItemIndex] = { ...chartData[delayItemIndex], ...action.data.delay };
      return {
        ...state,
        chartData: [...chartData],
        loading: false,
      };
    default:
      return state;
  }
}
