// @flow
import ActionTypes from './action-types';
import type { TopExtendedTasksAction, ExtendedTask } from './sagas';

export type TopExtendedTasksState = {
  chartData: ExtendedTask[],
  loading: boolean | null,
};

const initialState: TopExtendedTasksState = { loading: null, chartData: [] };

export default function (state: TopExtendedTasksState = initialState, action: TopExtendedTasksAction): TopExtendedTasksState {
  switch (action.type) {
    case ActionTypes.GET_TOP_EXTENDED_TASKS_SUCCESS:
      return {
        ...state,
        chartData: action.data.tasks,
        loading: false,
      };
    default:
      return state;
  }
}
