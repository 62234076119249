// @flow

type ProjectWeeklyProgress = {
  GET_PROJECT_WEEKLY_PROGRESS: 'getProjectWeeklyProgress',
  GET_PROJECT_WEEKLY_PROGRESS_SUCCESS: 'getProjectWeeklyProgressSuccess',
  GET_PROJECT_WEEKLY_PROGRESS_FAIL: 'getProjectWeeklyProgressFail',
}

const actionTypes: ProjectWeeklyProgress = {
  GET_PROJECT_WEEKLY_PROGRESS: 'getProjectWeeklyProgress',
  GET_PROJECT_WEEKLY_PROGRESS_SUCCESS: 'getProjectWeeklyProgressSuccess',
  GET_PROJECT_WEEKLY_PROGRESS_FAIL: 'getProjectWeeklyProgressFail',
};

export default actionTypes;
