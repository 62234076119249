// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectStatusApi from './apis';

type ProjectStatusResponseSuccessType = {
  responsibility: {
    [key: string]: number,
  }
};

export type ProjectStatusAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_STATUS_SUCCESS'>,
  data: ProjectStatusResponseSuccessType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_STATUS_FAIL'>,
|};


function* getProjectStatus(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ProjectStatusResponseSuccessType } = yield call(getProjectStatusApi, action.data.projectId);
    yield put({ type: ActionTypes.GET_PROJECT_STATUS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_STATUS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_STATUS, getProjectStatus);
}
