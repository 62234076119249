// @flow

import * as React from 'react';
import { partial } from 'ramda';
import objstr from '../utils/obj-str';
import './toggle-button.css';

type Props = {
  onChange: Function,
  items: Array<{
    label: string,
    value: string,
  }>,
  value: string,
};

const ToggleButton: Function = function ToggleButton(props: Props): React.Node {
  const onChange: Function = props.onChange;
  return (
    <div className="toggle-button">
      {props.items.map((item: Object, count: number): React.Node => (
        <div
          key={item.value}
          onClick={partial(onChange, [item.value])}
          role="presentation"
          className={objstr({
            'toggle-button__item': true,
            'toggle-button__item--first': count === 0,
            'toggle-button__item--last': props.items.length - 1,
            'toggle-button__item--active': props.value === item.value,
          })}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default ToggleButton;
