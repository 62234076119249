// @flow
import { map, isEmpty } from 'ramda';

import ActionTypes from './action-types';
import type { WorkableBacklogAction, CompaniesWorkableBacklogResponse, WorkableBacklogSuccessType } from './sagas';

export type CompaniesWorkableBacklogItem = {
  shouldDo: number,
  canDo: number,
  willDo: number,
  done: number,
  companyName: string,
  companyId: number | string,
};

export type AllWorkableBacklog = {
  shouldDo: number,
  canDo: number,
  willDo: number,
  done: number,
};

export type WorkableBacklog = {
  all: AllWorkableBacklog,
  companiesBacklog: CompaniesWorkableBacklogItem[],
};

export type WorkableBacklogState = {
  data: WorkableBacklog,
  WorkableBacklogLoading: boolean | null,
};

const initialState: WorkableBacklogState = {
  WorkableBacklogLoading: null,
  data: {
    all: {
      shouldDo: 0,
      canDo: 0,
      willDo: 0,
      done: 0,
    },
    companiesBacklog: [
      {
        shouldDo: 0,
        canDo: 0,
        willDo: 0,
        done: 0,
        companyName: '',
        companyId: 0,
      },
    ],
  },
};

const formatData: Function = function formatData(data: WorkableBacklogSuccessType): WorkableBacklog | {} {
  if (isEmpty(data)) {
    return {};
  }
  const companiesWorkableBacklogData: CompaniesWorkableBacklogItem[] = map((item: CompaniesWorkableBacklogResponse): CompaniesWorkableBacklogItem => (
    {
      shouldDo: item.num_should_do,
      canDo: item.num_can_do,
      willDo: item.num_will_do,
      done: item.num_done,
      companyName: item.company_name,
      companyId: item.company_id,
    }
  ), data.companies_backlog);

  const allWorkableBacklogData: AllWorkableBacklog = {
    shouldDo: data.all.num_should_do,
    canDo: data.all.num_can_do,
    willDo: data.all.num_will_do,
    done: data.all.num_done,
  };
  return ({ all: allWorkableBacklogData, companiesBacklog: companiesWorkableBacklogData });
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WorkableBacklogState = initialState, action: WorkableBacklogAction): Exact<WorkableBacklogState> {
  let dp: WorkableBacklog;

  switch (action.type) {
    case ActionTypes.GET_WORKABLE_BACKLOG_SUCCESS:
      dp = formatData(action.data);
      return {
        ...state,
        data: dp,
        WorkableBacklogLoading: false,
      };
    default:
      return state;
  }
}
