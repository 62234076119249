// @flow

export type TopShortenedTasks = {
  GET_TOP_SHORTENED_TASKS: 'getTopShortenedTasks',
  GET_TOP_SHORTENED_TASKS_SUCCESS: 'getTopShortenedTasksSuccess',
  GET_TOP_SHORTENED_TASKS_FAIL: 'getTopShortenedTasksFail',
}

const actionTypes: TopShortenedTasks = {
  GET_TOP_SHORTENED_TASKS: 'getTopShortenedTasks',
  GET_TOP_SHORTENED_TASKS_SUCCESS: 'getTopShortenedTasksSuccess',
  GET_TOP_SHORTENED_TASKS_FAIL: 'getTopShortenedTasksFail',
};

export default actionTypes;
