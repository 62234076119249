// @flow

type ProjectStatusActionTypes = {
  GET_PROJECT_STATUS: 'getProjectStatus',
  GET_PROJECT_STATUS_SUCCESS: 'getProjectStatusSuccess',
  GET_PROJECT_STATUS_FAIL: 'getProjectStatusFail',
}

const actionTypes: ProjectStatusActionTypes = {
  GET_PROJECT_STATUS: 'getProjectStatus',
  GET_PROJECT_STATUS_SUCCESS: 'getProjectStatusSuccess',
  GET_PROJECT_STATUS_FAIL: 'getProjectStatusFail',
};

export default actionTypes;
