// @flow

import ActionTypes from './action-types';
import type { ActivityDetailResponseType, ActivityDetailAction } from './sagas';

export type ActivityDetailState = {
  activity: ActivityDetailResponseType | null,
  isLoading: boolean,
};

const initialState: ActivityDetailState = {
  isLoading: true,
  activity: null,
};


type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ActivityDetailState = initialState, action: ActivityDetailAction): Exact<ActivityDetailState> {
  switch (action.type) {
    case ActionTypes.GET_ACTIVITY_DETAIL_SUCCESS:
      return {
        ...state,
        activity: action.data,
      };
    case ActionTypes.CLEAR_ACTIVITY_DETAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
