// @flow
import ActionTypes from './action-types';
import type { TopDelayedConstraintsAction, DelayedConstraint } from './sagas';

export type TopDelayedConstraintsState = {
  chartData: DelayedConstraint[],
  loading: boolean | null,
};

const initialState: TopDelayedConstraintsState = { loading: null, chartData: [] };

export default function (state: TopDelayedConstraintsState = initialState, action: TopDelayedConstraintsAction): TopDelayedConstraintsState {
  switch (action.type) {
    case ActionTypes.GET_TOP_DELAYED_CONSTRAINTS_SUCCESS:
      return {
        ...state,
        chartData: action.data.delays,
        loading: false,
      };
    default:
      return state;
  }
}
