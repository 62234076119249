// @flow

import React, { Fragment } from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

import type { TopCriticalPathDelayedActivitiesState } from './reducer';
import type { CriticalPathDelayedActivity } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopCriticalPathDelayedActivitiesState,
  project: Project,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  tbodyContainer: {
    display: 'block',
    overflowY: 'scroll',
    maxHeight: '320px',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  parentHierarchy: {
    fontSize: '0.70rem',
    marginTop: '0.2rem',
  },
  reason: {
    textTransform: 'capitalize',
  },
  cause: {
    fontSize: '0.7rem',
  },
  causeName: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '15px',
  },
};

const delayTexts: Object = {
  access_space: 'Access / Space',
  approval_permits: 'Approval / Permits',
  client_decision: 'Client decision',
  contracts: 'Contracts',
  drawings: 'Drawings',
  equipment: 'Equipment',
  inspection: 'Inspection',
  labor: 'Labor',
  logistics_storage: 'Logistics / Storage',
  material: 'Material',
  method_statement: 'Method statement',
  minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
  rfi: 'RFI',
  safety_requirement: 'Safety requirement',
  re_scheduling: 'Re-Scheduling ',
  unforeseen_site_conditions: 'Unforeseen site conditions',
  weather: 'Weather',
  schedule_error: 'Schedule error',
  defects_rework: 'Defects rework',
  first_lookahead: 'Delay on First Lookahead',
  second_version: 'Delay on new version upload',
  others: 'Others',
};
type State = {
  order: string,
  column: string,
};

class TopCriticalPathDelayedActivitiesWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'project_delay',
    };
  }

  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES,
      data: { projectId: this.props.project.id },
    });
  }

  // getParentHierarchy(activity: CriticalPathDelayedActivity): string {
  //   const parents: Array<string> = activity.parent.split(' | ').reverse();
  //   parents.pop();
  //   return parents.join(' ➔ ');
  // }

  // getCauseDetails(activity: CriticalPathDelayedActivity): React.Element<any> | null {
  //   const causes: Object = {
  //     activity_constraint: 'activity constraint',
  //     task: 'task',
  //     task_constraint: 'task constraint',
  //     activity_dependency: 'activity dependency',
  //     task_dependency: 'task dependency',
  //   };

  //   if (activity.cause === null) {
  //     return (
  //       <span>
  //         <span style={styles.cause}>Activity</span>
  //       </span>
  //     );
  //   }

  //   return (
  //     <span>
  //       <span style={styles.cause}>Delayed due to the {causes[activity.cause]}</span> <em style={styles.causeName}>{activity.cause_name}</em>
  //     </span>
  //   );
  // }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
    // this.setState({
    //   column,
    //   order: this.state.order === 'desc' ? 'asc' : 'desc',
    // });
  }

  getReasons(reasons: string[]): string {
    const texts: string[] = reasons.map(
      (reason: string): string => {
        if (reason === null) {
          return '';
        }
        if (delayTexts[reason]) {
          return delayTexts[reason];
        }
        return 'Others';
      },
    );
    if ([...new Set(texts)].includes('Others')) {
      const reason: any = [...new Set(texts)];
      reason.splice(reason.indexOf('Others'), 1);
      return reason.join(', ');
    }
    return [...new Set(texts)].join(', ');
  }

  getActivityName(activity: Object): string {
    if (activity.reason.indexOf('first_lookahead') > -1) {
      return `*${activity.name}`;
    }
    if (activity.reason.indexOf('second_version') > -1) {
      return `** ${activity.name}`;
    }
    if (activity.deleted) {
      return `\u271D(Deleted) ${activity.name}`;
    }
    return activity.name;
  }

  showActivityDetailModal = (activity: CriticalPathDelayedActivity) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.id,
        activityName: activity.name,
      },
    });
  };

  showOthersDelaysModal = () => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'othersDelays',
      modalProps: {
        projectId: this.props.project.id,
        week: this.props.week,
      },
    });
  }

  renderTable(): Element<any> {
    const sortedData: CriticalPathDelayedActivity[] = this.props.data.chartData.sort(
      (item1: CriticalPathDelayedActivity, item2: CriticalPathDelayedActivity): number => {
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <Fragment>
        <table className="table-container">
          <thead className="thead-container">
            <tr>
              <th style={{ width: 670 }} className="header" onClick={this.onSortChange.bind(this, 'name')}>
                Activity
                {this.state.column === 'name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: 150 }} className="header" onClick={this.onSortChange.bind(this, 'delay')}>
                Activity Delay (days)
                {this.state.column === 'delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: 150 }} className="header" onClick={this.onSortChange.bind(this, 'project_delay')}>
                Project Delay (days)
                {this.state.column === 'project_delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th className="header" onClick={this.onSortChange.bind(this, 'reason')}>
                Reason
                {this.state.column === 'reason' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody style={styles.tbodyContainer}>
            {sortedData.map(
              (activity: CriticalPathDelayedActivity, i: number): Node => (
                <tr
                  key={i}
                  style={activity.deleted || activity.reason.indexOf('first_lookahead') > -1 || activity.reason.indexOf('second_version') > -1 ? styles.deletedGrey : null}
                >
                  <td style={{ width: 670 }}>
                    <a onClick={this.showActivityDetailModal.bind(this, activity)} className="underline-hover">
                      {this.getActivityName(activity)}
                    </a>
                  </td>
                  <td style={{ width: 150 }}>{activity.delay}</td>
                  <td style={{ width: 150 }}>{activity.project_delay === null ? 'NA' : activity.project_delay}</td>
                  {
                    activity.reason.join('').match(/others:/)
                      ? <td style={styles.reason}>
                        {
                          <Fragment>
                            <a onClick={this.showOthersDelaysModal} className="underline-hover">Others</a>
                            <span>{`, ${this.getReasons(activity.reason)}`}</span>
                          </Fragment>
                        }
                        </td>
                      : <td style={styles.reason}>{this.getReasons(activity.reason)}</td>

                  }
                </tr>
              ),
            )}
          </tbody>
        </table>
        <div style={styles.note}>* Delay on project end date due to rescheduling of various activities on first lookahead generation</div>
        <div style={styles.note}>
          &#10013; Project delay due to the deleted activity or deleted tasks, task constraints or dependencies under the activity
        </div>
        <div style={styles.note}>** Delay on project end date due to new masterplan version upload</div>
      </Fragment>
    );
  }

  render(): Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Project Delays
          <img
            data-tip="This table shows the list of top critical path activities that have caused a project delay along with the total days of delay it has gone through and the reasons of these delays"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderTable()}
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: TopCriticalPathDelayedActivitiesState } => ({
    data: state.topCriticalPathDelayedActivities,
  }),
)(TopCriticalPathDelayedActivitiesWidget);

component.style = {
  width: '98%',
};

export default component;
