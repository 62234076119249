// @flow
import { map, takeLast } from 'ramda';

import ActionTypes from './action-types';
import type { DelayMitigationAction, DelayMitigation } from './sagas';

export type WeeklyDelayMitigationItem = {
  delayMitigation: number,
  endDate: string,
  startDate: string,
  durationExtended: number,
  durationShortened: number,
};

export type WeeklyDelayMitigationState = {
  weeklyDelayMitigation: WeeklyDelayMitigationItem[],
  weeklyDelayMitigationLoading: boolean | null,
};

const initialState: WeeklyDelayMitigationState = {
  weeklyDelayMitigationLoading: null,
  weeklyDelayMitigation: [],
};

const formatData: Function = function formatData(data: DelayMitigation): WeeklyDelayMitigationItem[] {
  return map((item: DelayMitigation): WeeklyDelayMitigationItem => (
    {
      delayMitigation: item.delay_mitigation,
      endDate: item.end_date,
      startDate: item.start_date,
      durationExtended: item.duration_extended,
      durationShortened: item.duration_shortened,
    }
  ), takeLast(20, data));
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyDelayMitigationState = initialState, action: DelayMitigationAction): Exact<WeeklyDelayMitigationState> {
  let dp: WeeklyDelayMitigationItem[];

  switch (action.type) {
    case ActionTypes.GET_WEEKLY_DELAY_MITIGATION_SUCCESS:
      dp = formatData(action.data.delay_mitigation);
      return {
        ...state,
        weeklyDelayMitigation: dp,
        weeklyDelayMitigationLoading: false,
      };
    default:
      return state;
  }
}
