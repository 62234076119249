// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getDelayMitigationApi from './apis';

export type DelayMitigationResponseType = {
  delay_mitigation: number,
  delayed: number,
  shortened: number,
};

export type DelayMitigationAction = {|
  +type: typeof ActionTypes.GET_DELAY_MITIGATION_EFFORT_SUCCESS,
  data: DelayMitigationResponseType,
|} | {|
  +type: typeof ActionTypes.GET_DELAY_MITIGATION_EFFORT_FAIL,
|} | {
  +type: typeof ActionTypes.SET_DELAY_MITIGATION_EFFORT_METHOD,
  data: 'calendar' | 'task',
};

function* getDelayMitigation(action: { data: { projectId: string, method: 'calendar' | 'task' } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DelayMitigationResponseType } = yield call(getDelayMitigationApi, action.data.projectId, action.data.method);
    const actionData: DelayMitigationAction = { type: ActionTypes.GET_DELAY_MITIGATION_EFFORT_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: DelayMitigationAction = { type: ActionTypes.GET_DELAY_MITIGATION_EFFORT_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DELAY_MITIGATION_EFFORT, getDelayMitigation);
}
