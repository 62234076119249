// @flow
import ActionTypes from './action-types';
import type { ProjectProjectionAction } from './sagas';

export type ProjectProjectionState = {
  data: {
    "actual_progress": number,
    "expected_completion_date": string,
    "overall_status": string,
    "planned_completion_date": string,
    "planned_progress": number,
    "lookahead_size": number,
    project_name: string,
  } | {},
  loading: boolean | null,
};

const initialState: ProjectProjectionState = { loading: null, data: {} };

export default function (state: ProjectProjectionState = initialState, action: ProjectProjectionAction): ProjectProjectionState {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_PROJECTION_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
