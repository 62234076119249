// @flow
import * as React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';
import type { DelayDetailState } from './reducer';

import ActionTypes from './action-types';
import DelayDetail from './delay-detail';

import withProjectContext from '../auth/with-project-context';

type Props = {
  delayId: number,
  project: Project,
  dispatch: Object => mixed,
  data: DelayDetailState,
  companies: any,
  workdata: Object,
  onClose: () => void,
};

const modalStyles: Object = {
  content: {
    right: 'auto',
    width: '53rem',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '85vh',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

class DelayDetailContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_DELAY_COMMENTS,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
      },
    });
    this.props.dispatch({
      type: ActionTypes.GET_DELAY_HISTORY,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.CLEAR_DELAY_DETAIL });
  }

  closeModal = () => {
    this.props.onClose();
  }


  commentsUpdateHandler = (data: any) => {
    this.props.dispatch({
      type: ActionTypes.POST_DELAY_COMMENTS,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
        data,
      },
    });
  }

  delayUpdateHandler = (data: any) => {
    this.props.dispatch({
      type: ActionTypes.UPDATE_DELAY_HISTORY,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
        data,
      },
    });
  }

  render(): React.Node {
    return (
      <Modal isOpen contentLabel={this.props.workdata.variance} style={modalStyles} onRequestClose={this.closeModal}>
        <DelayDetail
          comments={this.props.data.chartData}
          delayHistory={this.props.data.delayData}
          onSave={this.commentsUpdateHandler}
          onDelaySave={this.delayUpdateHandler}
          companies={this.props.companies}
          workdata={this.props.workdata}
        />
      </Modal>
    );
  }
}

const Component: any = compose(
  connect((state: Reducers): { data: Object } => ({ data: state.delayDetail, companies: state.project.companies })),
  withProjectContext,
)(DelayDetailContainer);

export default Component;
