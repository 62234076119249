// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopShortenedTasksApi from './apis';

export type ShortenedTask = {
  base_duration: number,
  actual_duration: number,
  name: string,
  parent: string,
  ratio: number,
  wbs: string,
};

type TopShortenedTasksResponse = {
  tasks: Array<ShortenedTask>,
};

export type TopShortenedTasksAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_SHORTENED_TASKS_SUCCESS'>,
  data: TopShortenedTasksResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_SHORTENED_TASKS_FAIL'>,
|};

function* getTopShortenedTasks(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopShortenedTasksResponse } = yield call(getTopShortenedTasksApi, action.data.projectId, action.data.startDate);
    const actionData: TopShortenedTasksAction = { type: ActionTypes.GET_TOP_SHORTENED_TASKS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopShortenedTasksAction = { type: ActionTypes.GET_TOP_SHORTENED_TASKS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_SHORTENED_TASKS, getTopShortenedTasks);
}
