// @flow
import ActionTypes from './action-types';

export type ModalsState = {
  showModal: boolean,
  modalProps: Object,
  modalType: string,
};

type ModalAction = {
  type: $Values<typeof ActionTypes>,
  modalProps: Object,
  modalType: string,
}

const initialState: ModalsState = {
  showModal: false,
  modalProps: {},
  modalType: '',
};

type Exact<T> = $Shape<T>;

export default function (state: ModalsState = initialState, action: ModalAction): Exact<ModalsState> {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL:
      return {
        ...state,
        modalProps: action.modalProps,
        modalType: action.modalType,
      };
    case ActionTypes.HIDE_MODAL:
      return {
        ...state,
        modalType: '',
      };
    default:
      return state;
  }
}
