// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ResponsivePieChart from '../../components/charts/pie';
import Loader from '../../components/loader';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import ExportButton from '../../components/export';

import type { ProfileEndState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProfileEndState,
  project: Project,
  week: string,
  isTask: boolean,
}

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string,
}

const LabelStrings: Object = {
  as_planned: 'As Planned',
  extended: 'Extended',
  shortened: 'Shortened',
  delayed: 'Delayed',
  earlier: 'Earlier',
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ActivityProfileEndDate extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  getFormattedData(data: Array<Object>): ViewModel[] {
    if (this.props.isTask === 'true') {
      return data.map((item: Object): ViewModel => ({ label: LabelStrings[item.id], value: item.percentage, text: `${item.value} tasks` }));
    }
    return data.map((item: Object): ViewModel => ({ label: LabelStrings[item.id], value: item.percentage, text: `${item.value} activities` }));
  }

  getWidget(): React.Node {
    if (this.props.data.dateProfileTask.length !== 0 && this.props.isTask === 'true') {
      return (<ResponsivePieChart data={this.getFormattedData(this.props.data.dateProfileTask)} />);
    } if (this.props.data.dateProfile.length !== 0 && this.props.isTask !== 'true') {
      return (<ResponsivePieChart data={this.getFormattedData(this.props.data.dateProfile)} />);
    }
    return (
      <span style={styles.noData}>
      No data available
      </span>
    );
  }

  dispatch() {
    if (this.props.isTask === 'true') {
      this.props.dispatch({
        type: ActionTypes.GET_END_DATE_PROFILE_TASK,
        data: {
          projectId: this.props.project.id,
          startDate: this.props.week,
          isTask: this.props.isTask,
        },
      });
    } else {
      this.props.dispatch({
        type: ActionTypes.GET_END_DATE_PROFILE,
        data: {
          projectId: this.props.project.id,
          startDate: this.props.week,
          isTask: this.props.isTask,
        },
      });
    }
  }

  renderWidget(): React.Node {
    if (this.props.data.dateProfileTask.length !== 0 && this.props.isTask === 'true') {
      return (
        <div>
          <div>
            <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="plan_reliability_end_date_tasks" />
          </div>
          <br />
          <br />
          <div>
            <ResponsivePieChart data={this.getFormattedData(this.props.data.dateProfileTask)} />
          </div>
        </div>
      );
    }
    if (this.props.data.dateProfile.length !== 0 && this.props.isTask !== 'true') {
      return (
        <div>
          <div>
            {this.props.week ? <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="plan_reliability_end_date_activities" /> : null }
          </div>
          {this.props.week ? <br /> : null }
          {this.props.week ? <br /> : null }
          <div>
            <ResponsivePieChart data={this.getFormattedData(this.props.data.dateProfile)} />
          </div>
        </div>
      );
    }
    return <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          {this.props.week ? 'End Date' : 'Plan Reliability Categorization (End Date)'}
          {' '}
          <img data-tip={`This displays the weekly profile of the ${this.props.isTask ? 'tasks' : 'activities'} based on the end date to arrive at a trend based on the past actual ends (compared with their baseline ends)`} width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.dateProfileLoading === null
            ? <Loader /> : this.renderWidget()
        }
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: ProfileEndState } => ({ data: state.activityProfileEndDate }),
)(ActivityProfileEndDate);

Component.style = {
  width: '32%',
};

export default Component;
