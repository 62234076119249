// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopEarlyFinishedActivitiesApi from './apis';

export type EarlyFinishedActivity = {
  actual_end_date: string,
  base_end_date: string,
  end_date_difference: number,
  name: string,
  parent: string,
  wbs: string,
  id: number,
};

type TopEarlyFinishedActivitiesResponse = {
  activities: Array<EarlyFinishedActivity>,
};

export type TopEarlyFinishedActivitiesAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EARLY_FINISHED_ACTIVITIES_SUCCESS'>,
  data: TopEarlyFinishedActivitiesResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EARLY_FINISHED_ACTIVITIES_FAIL'>,
|};

function* getTopEarlyFinishedActivities(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopEarlyFinishedActivitiesResponse } = yield call(getTopEarlyFinishedActivitiesApi, action.data.projectId, action.data.startDate);
    const actionData: TopEarlyFinishedActivitiesAction = { type: ActionTypes.GET_TOP_EARLY_FINISHED_ACTIVITIES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopEarlyFinishedActivitiesAction = { type: ActionTypes.GET_TOP_EARLY_FINISHED_ACTIVITIES_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_EARLY_FINISHED_ACTIVITIES, getTopEarlyFinishedActivities);
}
