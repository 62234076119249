// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { MapStateToProps, Connector } from 'react-redux';

// import StackedBarChart from '../../components/charts/stacked-bar';
import ActionTypes from './action-types';

import type { DelayMitigationEffortState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import type { DelayMitigationResponseType } from './sagas';

type Action = { type: $Values<typeof ActionTypes>, data: Object | string }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: DelayMitigationEffortState,
  project: Project,
}

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  optionLabel: {
    fontSize: '0.85rem',
    marginRight: '0.5rem',
  },
  form: {
    marginTop: '0.8rem',
  },
};

type ViewModel = Array<{
  value1: number,
  value2: number,
  text: string,
}>;

class ActivityProfile extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_DELAY_MITIGATION_EFFORT,
      data: { projectId: this.props.project.id, method: this.props.data.method },
    });
  }

  componentWillReceiveProps(props: Props) {
    if (this.props.data.method !== props.data.method) {
      this.props.dispatch({
        type: ActionTypes.GET_DELAY_MITIGATION_EFFORT,
        data: { projectId: this.props.project.id, method: this.props.data.method },
      });
    }
  }

  onSelectMethod = (e: SyntheticInputEvent<>) => {
    this.props.dispatch({
      type: ActionTypes.SET_DELAY_MITIGATION_EFFORT_METHOD,
      data: e.target.value,
    });
  }

  getFormattedData(data: DelayMitigationResponseType | null): ViewModel {
    if (!data) return [];

    return [{
      value1: data.shortened,
      value2: data.delayed,
      text: 'During Lean Catchup',
    }];
  }

  getCheckedStatus(method: string): ?'checked' {
    return this.props.data.method === method ? 'checked' : undefined;
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">Delay Mitigation Effort</h1>
        <form style={styles.form}>
          <span style={styles.optionLabel}>Method: </span>
          <input
            id="delayMitigationTaskType"
            onClick={this.onSelectMethod}
            type="radio"
            name="delayMitigationEffortMethod"
            value="task"
            checked={this.getCheckedStatus('task')}
          />
          <label htmlFor="delayMitigationTaskType" style={styles.optionLabel}>Task</label>
          <input
            id="delayMitigationCalendarType"
            onClick={this.onSelectMethod}
            type="radio"
            name="delayMitigationEffortMethod"
            value="calendar"
            checked={this.getCheckedStatus('calendar')}
          />
          <label htmlFor="delayMitigationCalendarType" style={styles.optionLabel}>Calendar</label>
        </form>
        { /* <StackedBarChart data={this.getFormattedData(this.props.data.chartData)} name1="Shortened" name2="Planned" /> */ }
      </section>
    );
  }
}

// export default connect(
//   (state: Reducers): { data: DelayMitigationEffortState } => ({ data: state.delayMitigationEffort }),
// )(ActivityProfile);

type TMapStateToFilter = MapStateToProps<Reducers, any, {data: DelayMitigationEffortState}>

const mapStateToProps: TMapStateToFilter = (state: any): Object => (
  {
    data: state.delayMitigationEffort,
  });

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(ActivityProfile);
