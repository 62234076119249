// @flow

import * as React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

const styles: Object = {
  container: {
    fontSize: '0.75rem',
    flex: 1,
  },
};

type Props = {
  children: any,
};

const SelectBox: Function = function SelectBox(props: Props): React.Node {
  return (
    <div style={styles.container}>
      <Select
        clearable={false}
        {...props}
      />
    </div>
  );
};

export default SelectBox;
