// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getCprApi from './apis';

export type CompaniesCprResponse = {
  num_should_do: number,
  num_can_do: number,
  num_cant_do: number,
  num_will_do: number,
  num_wont_do: number,
  num_done: number,
  num_didnt_do: number,
  company_name: string,
  company_id: number,
};

export type AllCprResponse = {
  num_should_do: number,
  num_can_do: number,
  num_cant_do: number,
  num_will_do: number,
  num_wont_do: number,
  num_done: number,
  num_didnt_do: number,
};

export type TableCprResponse = {
  cant_do: [],
  didnt_do: [],
  wont_do: [],
};

export type CprSuccessType = {
  companies: CompaniesCprResponse[],
  all: AllCprResponse,
  table_data: TableCprResponse,
};

export type CprAction = {|
  type: typeof ActionTypes.GET_CPR_WEEKLY_SUCCESS,
  data: CprSuccessType,
|} | {|
  type: typeof ActionTypes.GET_CPR_WEEKLY_FAIL,
|};

function* getCprWeekly(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: CprSuccessType } = yield call(getCprApi, action.data.projectId, action.data.startDate);
    const actionData: CprAction = { type: ActionTypes.GET_CPR_WEEKLY_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: CprAction = { type: ActionTypes.GET_CPR_WEEKLY_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_CPR_WEEKLY, getCprWeekly);
}
