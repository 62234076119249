// @flow

import * as React from 'react';
import Plotly from 'plotly.js/lib/index-basic';
import { reverse } from 'ramda';

import withResponsive from '../utils/withResponsive';

export type DataItem = {
  label: string,
  value: number,
  color: string,
  text: string,
};

type Props = {
  onRenderChart: (Object) => mixed,
  data: DataItem[],
  options?: Object,
  dataOptions?: Object,
};

class BarChart extends React.PureComponent<Props> {
  static defaultProps = {
    // onRenderChart: () => {},
    options: {},
    dataOptions: {},
  };

  componentDidMount() {
    if (this.el) {
      this.renderChart();
    }
  }

  componentDidUpdate() {
    if (this.el) {
      this.renderChart();
    }
  }

  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;

    this.props.onRenderChart(el);
  }

  el: HTMLDivElement;

  renderChart() {
    let xAxis: string = 'x';
    let yAxis: string = 'y';
    let margin: Object = {
      t: 40,
      l: 70,
      r: 50,
      b: 120,
    };
    let chartData: DataItem[] = this.props.data;

    if (this.props.dataOptions && this.props.dataOptions.orientation === 'h') {
      xAxis = 'y';
      yAxis = 'x';
      margin = {
        l: 100,
        t: 40,
        r: 50,
        b: 100,
        pad: 10,
      };
      chartData = reverse(this.props.data);
    }

    const data: Array<Object> = [
      {
        // [xAxis]: chartData.map((item: DataItem, index: number): string => {
        //   const label: string = `${item.label}${index}`;
        //   return label;
        // }),
        [xAxis]: chartData.map((item: DataItem): string => item.label),
        [yAxis]: chartData.map((item: DataItem): number => item.value),
        text: chartData.map((item: DataItem): string => item.text),
        type: 'bar',
        width: 0.5,
        hoverinfo: 'text',
        marker: {
          color: chartData.map((item: DataItem): string => item.color),
          line: {
            width: 0,
          },
        },
        ...this.props.dataOptions,
      },
    ];

    Plotly.newPlot(this.el, data, {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        tickangle: this.props.options ? this.props.options.tickangle : -45,
        tickfont: this.props.options ? this.props.options.tickfont : null,
      },
      margin,
      ...this.props.options,
    }, {
      showLink: false,
      displaylogo: false,
      sendData: false,
      modeBarButtonsToRemove: ['zoom2d', 'pan', 'pan2d', 'autoScale2d', 'sendDataToCloud', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian'],
    });
  }

  render(): React.Node {
    return (
      <div ref={this.getElementRef} />
    );
  }
}

// TODO: need to define proper types in place of any
export default withResponsive<any>(BarChart);
