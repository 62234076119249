// @flow

export type DelaysAndMitigationsActionTypes = {
  GET_DELAYS_AND_MITIGATIONS: 'getDelaysAndMitigations',
  GET_DELAYS_AND_MITIGATIONS_SUCCESS: 'getDelaysAndMitigationsSuccess',
  GET_DELAYS_AND_MITIGATIONS_FAIL: 'getDelaysAndMitigationsFail',
}

const actionTypes: DelaysAndMitigationsActionTypes = {
  GET_DELAYS_AND_MITIGATIONS: 'getDelaysAndMitigations',
  GET_DELAYS_AND_MITIGATIONS_SUCCESS: 'getDelaysAndMitigationsSuccess',
  GET_DELAYS_AND_MITIGATIONS_FAIL: 'getDelaysAndMitigationsFail',
};

export default actionTypes;
