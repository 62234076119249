// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getConstraintDelayApi from './apis';

export type ConstraintDelay = {
  project_delay: number,
  variance: string,
  delay: number,
  delay_description: string,
  responsible_company_name: string,
  responsible_entity: string,
  activity_constraint_name: string,
  task_constraint_name: string,
  delayed_artifact: string,
  id: number,
  delayed_on: string,
  delayed_by: string,
  activity_name: string,
  task_name: string,
  delay_description: string,
  task_constraint_desc: string,
  activity_constraint_desc: string,
};

type ConstraintDelayResponse = {
  delays: Array<ConstraintDelay>,
};

export type ConstraintDelayAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CONSTRAINT_DELAY_SUCCESS'>,
  data: ConstraintDelayResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CONSTRAINT_DELAY_FAIL'>,
|};

function* getConstraintDelays(action: { data: { projectId: string, weekId: number } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ConstraintDelayResponse } = yield call(getConstraintDelayApi, action.data.projectId, action.data.weekId);
    const actionData: ConstraintDelayAction = { type: ActionTypes.GET_CONSTRAINT_DELAY_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ConstraintDelayAction = { type: ActionTypes.GET_CONSTRAINT_DELAY_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_CONSTRAINT_DELAY, getConstraintDelays);
}
