// @flow
import ActionTypes from './action-types';
import type { TopEarlyFinishedActivitiesAction, EarlyFinishedActivity } from './sagas';

export type TopEarlyFinishedActivitiesState = {
  chartData: EarlyFinishedActivity[],
  loading: boolean | null,
};

const initialState: TopEarlyFinishedActivitiesState = { loading: null, chartData: [] };

export default function (state: TopEarlyFinishedActivitiesState = initialState, action: TopEarlyFinishedActivitiesAction): TopEarlyFinishedActivitiesState {
  switch (action.type) {
    case ActionTypes.GET_TOP_EARLY_FINISHED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        chartData: action.data.activities,
        loading: false,
      };
    default:
      return state;
  }
}
