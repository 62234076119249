// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import LineChart from '../../components/charts/line';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

// import ToggleButton from './../../components/toggle-button';
import type { ProjectWeeklyProgressState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { ProjectWeeklyProgress as ProjectWeeklyProgressType } from './sagas';

import withProjectContext from '../../auth/with-project-context';
import colors from '../../styles/colors';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProjectWeeklyProgressState,
  project: Project,
}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  meta: {
    fontSize: '0.85rem',
    position: 'relative',
    top: '-1.9rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};


class ProjectWeeklyProgress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'time_based',
  }

  componentDidMount() {
    this.fetchData();
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    }, this.fetchData);
  }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const overallPercents: number[] = [];
    const plannedPercents: number[] = [];
    this.props.data.chartData.forEach((item: ProjectWeeklyProgressType) => {
      const overallPercent: number = parseFloat(item.overall_percentage
        && item.overall_percentage.toFixed(1));
      const plannedPercent: number = parseFloat(item.planned_percentage
        && item.planned_percentage.toFixed(1));
      labels.push(moment(item.finish_date).format('DD MMM YYYY'));
      overallPercents.push(overallPercent);
      plannedPercents.push(plannedPercent);
    });
    return [
      {
        labels,
        values: overallPercents,
        name: 'Actual',
        color: colors.themePrimaryColor,
      },
      {
        labels,
        values: plannedPercents,
        name: 'Planned',
        color: colors.themeSecondaryColor,
      },
    ];
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_PROJECT_WEEKLY_PROGRESS,
      data: { projectId: this.props.project.id, filter: this.state.selectedFilter },
    });
  }

  /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */

  renderWidget(): React$Element<*> {
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <div>
        <LineChart data={this.getData()} />
        <div style={styles.meta}>
          Project start:
          {moment(this.props.data.projectStart).format('DD MMM, YYYY')}
        </div>
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
Weekly Project Progress(Time)
          <img data-tip="Displays the comparison of the Actual and Planned time progress on a weekly basis.<br />This is useful for understanding the gap from the progress to be achieved, if any" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget()
        }
         {
          this.props.offsetPercentage
          && <div style={{ fontSize: '0.8rem', marginTop: 8 }}>
             * Actual and Planned progress values are recalculated based on already
              completed percentage of the project provided in the settings
             </div>
                }
      </section>
    );
  }
}

const component: any = compose(
  connect((state: Reducers): { data: ProjectWeeklyProgressState } => (
    {
      data: state.projectWeeklyProgress,
      offsetPercentage: state.project.projectDetails.preferences.general.offset_percentage,
    }
  )),
  withProjectContext,
)(ProjectWeeklyProgress);

component.style = {
  width: '98%',
};

export default component;
