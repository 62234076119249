// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import ExportButton from '../../components/export';

import ActionTypes from './action-types';

import type { TopEarlyFinishedTasksState } from './reducer';
import type { EarlyFinishedTask } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopEarlyFinishedTasksState,
  project: Project,
  week: string,
};

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  taskNameTable: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '140',
  '2': '175',
  '3': '207',
  '4': '240',
  '5': '270',
  '6': '302',
  '7': '334',
  '8': '366',
  '9': '398',
  '10': '430',
};
/* eslint-enable */

class TopEarlyFinishedTasks extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.fetchData();
    }
  }

  // getParentHierarchy(task: EarlyFinishedTask): string {
  //   const parents: Array<string> = task.parent.split(' | ').reverse();
  //   parents.pop();
  //   return parents.join(' ➔ ');
  // }

  getData(): ChartData[] {
    return this.props.data.chartData.map(
      (task: EarlyFinishedTask): ChartData => ({
        label: task.name,
        value: parseInt(task.end_date_difference, 10) * -1,
        color: colors.themeSecondaryColor,
        text:
          `${task.name.trim()}<br>Actual end date: ${moment(
            task.actual_end_date,
          ).format('DD MMM YY')}<br>`
          + `Base end date: ${moment(task.base_end_date).format(
            'DD MMM YY',
          )}<br>Early by: ${task.end_date_difference * -1} day(s)`,
      }),
    );
  }

  showTaskDetailModal = (task: Object) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'taskDetail',
      modalProps: {
        activityId: task.activity_id,
        taskId: task.id,
        taskName: task.name,
      },
    });
  };

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_EARLY_FINISHED_TASKS,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderTable(): React.Element<any> {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.taskNameTable}>Task</th>
            <th className="hide-element">Base End date</th>
            <th className="hide-element">Actual End date</th>
            <th>Early by (days)</th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.chartData.map(
            (task: EarlyFinishedTask, i: number): React.Node => (
              <tr key={i}>
                <td style={styles.taskNameTable}>
                  <a
                    onClick={this.showTaskDetailModal.bind(this, task)}
                    className="underline-hover"
                  >
                    {task.name}
                  </a>
                </td>
                <td className="hide-element">
                  {moment(task.base_end_date).format('DD MMM YY')}
                </td>
                <td className="hide-element">
                  {moment(task.actual_end_date).format('DD MMM YY')}
                </td>
                <td>{task.end_date_difference * -1}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  }


  renderWidget(options: Object): React.Node {
    return this.props.data.chartData.length !== 0
      ? (
        <div>
          <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="top_early_finished_tasks" />
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '60%' }}>
              {this.renderTable()}
            </div>
            <div style={{ width: '40%' }}>
              <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h' }} />
            </div>
          </div>
        </div>)
      : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    const options: Object = {
      height: getHeight[this.props.data.chartData.length],
      yaxis: {
        showticklabels: false,
      },
      xaxis: {
        title: 'Days Shortened (end date)',
      },
      margin: {
        t: 35,
        r: 10,
        l: 3,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Top Early Finished Tasks
          <img
            data-tip="This chart lists the top early finished tasks sorted based on early finish"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget(options)
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: TopEarlyFinishedTasksState } => ({
    data: state.topEarlyFinishedTasks,
  }),
)(TopEarlyFinishedTasks);

component.style = {
  width: '98%',
};

export default component;
