// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectProjectionApi from './apis';

type ProjectProjectionResponseSuccessType = {
  responsibility: {
    [key: string]: number,
  }
};

export type ProjectProjectionAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_PROJECTION_SUCCESS'>,
  data: ProjectProjectionResponseSuccessType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_PROJECTION_FAIL'>,
|};


function* getProjectProjection(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ProjectProjectionResponseSuccessType } = yield call(getProjectProjectionApi, action.data.projectId);
    yield put({ type: ActionTypes.GET_PROJECT_PROJECTION_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_PROJECTION_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_PROJECTION, getProjectProjection);
}
