// @flow
import { map, isEmpty } from 'ramda';

import ActionTypes from './action-types';
import type { CprAction, CompaniesCprResponse, CprSuccessType } from './sagas';

export type CompaniesItem = {
  shouldDo: number,
  canDo: number,
  cantDo: number,
  willDo: number,
  wontDo: number,
  done: number,
  didntDo: number,
  companyName: string,
  companyId: number,
};

export type AllItem = {
  shouldDo: number,
  canDo: number,
  cantDo: number,
  willDo: number,
  wontDo: number,
  done: number,
  didntDo: number,
};

type TableItem = {
  cantDo: [],
  didntDo: [],
  wontDo: [],
};

export type Cpr = {
  all: AllItem,
  companies: CompaniesItem[],
  tableData: TableItem,
};

export type CprState = {
  data: Cpr,
  CprLoading: boolean | null,
};

const initialState: CprState = {
  CprLoading: null,
  data: {
    all: {
      shouldDo: 0,
      canDo: 0,
      cantDo: 0,
      willDo: 0,
      wontDo: 0,
      done: 0,
      didntDo: 0,
    },
    companies: [
      {
        shouldDo: 0,
        canDo: 0,
        cantDo: 0,
        willDo: 0,
        wontDo: 0,
        done: 0,
        didntDo: 0,
        companyName: '',
        companyId: 0,
      },
    ],
    tableData: {
      cantDo: [],
      didntDo: [],
      wontDo: [],
    },
  },
};

const formatData: Function = function formatData(data: CprSuccessType): Cpr | {} {
  if (isEmpty(data)) {
    return {};
  }
  const companiesData: CompaniesItem[] = map((item: CompaniesCprResponse): CompaniesItem => (
    {
      shouldDo: item.num_should_do,
      canDo: item.num_can_do,
      cantDo: item.num_cant_do,
      willDo: item.num_will_do,
      wontDo: item.num_wont_do,
      done: item.num_done,
      didntDo: item.num_didnt_do,
      companyName: item.company_name,
      companyId: item.company_id,
    }
  ), data.companies);

  const allData: AllItem = {
    shouldDo: data.all.num_should_do,
    canDo: data.all.num_can_do,
    cantDo: data.all.num_cant_do,
    willDo: data.all.num_will_do,
    wontDo: data.all.num_wont_do,
    done: data.all.num_done,
    didntDo: data.all.num_didnt_do,
  };

  const tableData: TableItem = {
    cantDo: data.table_data.cant_do,
    didntDo: data.table_data.didnt_do,
    wontDo: data.table_data.wont_do,
  };
  return ({ all: allData, companies: companiesData, tableData });
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: CprState = initialState, action: CprAction): Exact<CprState> {
  let dp: Cpr;

  switch (action.type) {
    case ActionTypes.GET_CPR_WEEKLY_SUCCESS:
      dp = formatData(action.data);
      return {
        ...state,
        data: dp,
        CprLoading: false,
      };
    default:
      return state;
  }
}
