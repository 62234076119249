// @flow

export type TopMostDelayedActivities = {
  GET_TOP_MOST_DELAYED_ACTIVITIES: 'getTopMostDelayedActivities',
  GET_TOP_MOST_DELAYED_ACTIVITIES_SUCCESS: 'getTopMostDelayedActivitiesSuccess',
  GET_TOP_MOST_DELAYED_ACTIVITIES_FAIL: 'getTopMostDelayedActivitiesFail',
}

const actionTypes: TopMostDelayedActivities = {
  GET_TOP_MOST_DELAYED_ACTIVITIES: 'getTopMostDelayedActivities',
  GET_TOP_MOST_DELAYED_ACTIVITIES_SUCCESS: 'getTopMostDelayedActivitiesSuccess',
  GET_TOP_MOST_DELAYED_ACTIVITIES_FAIL: 'getTopMostDelayedActivitiesFail',
};

export default actionTypes;
