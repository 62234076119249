// @flow

export type TopEarlyFinishedActivities = {
  GET_TOP_EARLY_FINISHED_ACTIVITIES: 'getTopEarlyFinishedActivities',
  GET_TOP_EARLY_FINISHED_ACTIVITIES_SUCCESS: 'getTopEarlyFinishedActivitiesSuccess',
  GET_TOP_EARLY_FINISHED_ACTIVITIES_FAIL: 'getTopEarlyFinishedActivitiesFail',
}

const actionTypes: TopEarlyFinishedActivities = {
  GET_TOP_EARLY_FINISHED_ACTIVITIES: 'getTopEarlyFinishedActivities',
  GET_TOP_EARLY_FINISHED_ACTIVITIES_SUCCESS: 'getTopEarlyFinishedActivitiesSuccess',
  GET_TOP_EARLY_FINISHED_ACTIVITIES_FAIL: 'getTopEarlyFinishedActivitiesFail',
};

export default actionTypes;
