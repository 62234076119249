// @flow

export type WorkDelay = {
  GET_WORK_DELAY: 'getWorkDelay',
  GET_WORK_DELAY_SUCCESS: 'getWorkDelaySuccess',
  GET_WORK_DELAY_FAIL: 'getWorkDelayFail',
}

const actionTypes: WorkDelay = {
  GET_WORK_DELAY: 'getWorkDelay',
  GET_WORK_DELAY_SUCCESS: 'getWorkDelaySuccess',
  GET_WORK_DELAY_FAIL: 'getWorkDelayFail',
};

export default actionTypes;
