// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import StackedBarChart from '../../components/charts/stacked-bar';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { WeeklyActivityProfileDurationState, WeeklyActivityProfileDurationItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { DataItem } from '../../components/charts/stacked-bar';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: WeeklyActivityProfileDurationState,
  project: Project,
};

type ViewModel = Array<DataItem>;

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ActivityProfileDuration extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_WEEKLY_DURATION_PROFILE,
      data: { projectId: this.props.project.id },
    });
  }

  getDaysDeltaText(item: Object): string {
    if (item.id === 'shortened' || item.id === 'extended') {
      return `${item.totalDays} days`;
    }
    return '';
  }

  getFormattedData(data: WeeklyActivityProfileDurationItem[]): ViewModel {
    const asPlanned: number[] = [];
    const extended: number[] = [];
    const shortened: number[] = [];
    const dates: string[] = [];
    data.forEach((item: WeeklyActivityProfileDurationItem) => {
      asPlanned.push(item.asPlanned);
      extended.push(item.extended);
      shortened.push(item.shortened);
      dates.push(moment(item.endDate).format('DD MMM YYYY'));
    });
    const traces: ViewModel = [
      {
        labels: dates,
        values: asPlanned,
        text: 'As Planned',
        color: colors.paleCerulean,
      },
      {
        labels: dates,
        values: shortened,
        text: 'Shortened',
        color: colors.yellow,
      },
      {
        labels: dates,
        values: extended,
        text: 'Extended',
        color: colors.mandysPink,
      },
    ];

    return traces;
  }

  renderWidget(): React.Node {
    return this.props.data.weeklyDurationProfile.length !== 0 ? (
      <StackedBarChart data={this.getFormattedData(this.props.data.weeklyDurationProfile)} />
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div style={styles.chart}>
          <h1 className="header-widget-common">
            Weekly Activity Profile (Duration)
            <img
              data-tip="This displays the weekly profile of the activities based on the duration to arrive at a trend based on the past actual duration"
              width="14"
              src={infoIcon}
              alt="i"
            />
          </h1>
          <ReactTooltip place="top" type="dark" effect="solid" border multiline />
          {this.props.data.weeklyDurationProfileLoading === null ? <Loader /> : this.renderWidget()}
        </div>
      </section>
    );
  }
}

const Component: any = connect((state: Reducers): { data: WeeklyActivityProfileDurationState } => (
  { data: state.weeklyActivityProfileDuration }))(ActivityProfileDuration);

Component.style = {
  width: '98%',
};

export default Component;
