// @flow
import ActionTypes from './action-types';
import type { DelayMitigationResponseType, DelayMitigationAction } from './sagas';

export type ActiveProfileItem = {
  id: string,
  value: number,
};


export type DelayMitigationEffortState = {
  chartData: DelayMitigationResponseType | null,
  method: 'calendar' | 'task',
  loading: boolean | null,
};

const initialState: DelayMitigationEffortState = {
  chartData: null,
  method: 'task',
  loading: null,
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: DelayMitigationEffortState = initialState, action: DelayMitigationAction): Exact<DelayMitigationEffortState> {
  switch (action.type) {
    case ActionTypes.GET_DELAY_MITIGATION_EFFORT_SUCCESS:
      return {
        ...state,
        chartData: action.data,
        loading: false,
      };
    case ActionTypes.SET_DELAY_MITIGATION_EFFORT_METHOD:
      return {
        ...state,
        method: action.data,
      };
    default:
      return state;
  }
}
