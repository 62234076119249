// @flow
import ActionTypes from './action-types';
import type { TopShortenedActivitiesAction, ShortenedActivity } from './sagas';

export type TopShortenedActivitiesState = {
  chartData: ShortenedActivity[],
  loading: boolean | null,
};

const initialState: TopShortenedActivitiesState = { loading: null, chartData: [] };

export default function (state: TopShortenedActivitiesState = initialState, action: TopShortenedActivitiesAction): TopShortenedActivitiesState {
  switch (action.type) {
    case ActionTypes.GET_TOP_SHORTENED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        chartData: action.data.activities,
        loading: false,
      };
    default:
      return state;
  }
}
