// @flow
/* eslint-disable */
export default function (obj: Object): string {
  let cls: string = '';
  for (const k: string in obj) {
		if (obj[k]) {
			cls && (cls += ' ');
			cls += k;
		}
	}
	return cls;
}