// @flow

import ActionTypes from './action-types';
import type { TaskDetailResponseType, TaskDetailAction } from './sagas';

export type TaskDetailState = {
  task: TaskDetailResponseType | null,
  isLoading: boolean,
};

const initialState: TaskDetailState = {
  isLoading: true,
  task: null,
};


type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: TaskDetailState = initialState, action: TaskDetailAction): Exact<TaskDetailState> {
  switch (action.type) {
    case ActionTypes.GET_TASK_DETAIL_SUCCESS:
      return {
        ...state,
        task: action.data,
      };
    case ActionTypes.CLEAR_TASK_DETAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
