// @flow

export type ActivityWorkableBacklogTypes = {
  GET_RATE_OF_PROGRESS: 'getWorkableBacklogWeekly',
  GET_RATE_OF_PROGRESS_SUCCESS: 'getWorkableBacklogWeeklySuccess',
  GET_RATE_OF_PROGRESS_FAIL: 'getWorkableBacklogWeeklyFail',
}

const actionTypes: ActivityWorkableBacklogTypes = {
  GET_RATE_OF_PROGRESS: 'getWorkableBacklogWeekly',
  GET_RATE_OF_PROGRESS_SUCCESS: 'getWorkableBacklogWeeklySuccess',
  GET_RATE_OF_PROGRESS_FAIL: 'getWorkableBacklogWeeklyFail',
};

export default actionTypes;
