// @flow

import * as React from 'react';
import Plotly from 'plotly.js/lib/index-basic';

import withResponsive from '../utils/withResponsive';

type DataItem = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
  yaxis?: any,
};

type Props = {
  onRenderChart: (Object) => mixed,
  data: DataItem[],
  yAxes?: Object,
  legendY?: any,
};

class LineChart extends React.PureComponent<Props> {
  static defaultProps = {
    // onRenderChart: () => {},
    yAxes: {},
    legendY: null,
  };

  componentDidMount() {
    if (this.el) {
      this.renderChart();
    }
  }

  componentDidUpdate() {
    if (this.el) {
      this.renderChart();
    }
  }

  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;
    this.props.onRenderChart(el);
  }

  el: HTMLDivElement;

  renderChart() {
    const data: Array<Object> = this.props.data.map((trace: DataItem): Object => ({
      x: trace.labels,
      y: trace.values,
      text: trace.texts,
      name: trace.name,
      type: 'lines',
      line: {
        color: trace.color,
      },
      yaxis: trace.yaxis || null,
    }));

    Plotly.newPlot(this.el, data, {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      legend: {
        orientation: 'h',
        y: this.props.legendY || -0.5,
        x: 0.5,
        xanchor: 'center',
      },
      margin: {
        t: 40,
        l: this.props.yAxes ? 100 : 80,
        r: this.props.yAxes ? 100 : 80,
        b: 0,
      },
      xaxis: {
        type: 'category',
        tickangle: -45,
      },
      ...this.props.yAxes,
    }, {
      showLink: false,
      displaylogo: false,
      sendData: false,
      modeBarButtonsToRemove: [
        'lasso2d',
        'zoom2d',
        'pan',
        'pan2d',
        'autoScale2d',
        'sendDataToCloud',
        'toggleSpikelines',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'select2d',
      ],
    });
  }

  render(): React.Node {
    return (
      <div ref={this.getElementRef} />
    );
  }
}

export default withResponsive<any>(LineChart);
