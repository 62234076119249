// @flow

import ActionTypes from './action-types';
import type { OthersDelaysResponseType, OthersDelaysAction } from './sagas';

export type OthersDelaysState = {
  othersDelays: OthersDelaysResponseType | null,
  isLoading: boolean
}

const initialState: OthersDelaysState = {
  isLoading: true,
  othersDelays: null,
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: OthersDelaysState = initialState, action: OthersDelaysAction): Exact<OthersDelaysState> {
  switch (action.type) {
    case ActionTypes.GET_OTHERS_DELAYS_SUCCESS:
      return {
        ...state,
        othersDelays: action.data,
        isLoading: false,
      };
    case ActionTypes.CLEAR_OTHERS_DELAYS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
