// @flow

import * as React from 'react';
import Button from './button';

type Props = {
  startDate: string,
  widgetName: string,
  projectId: any,
};

const styles: Object = {
  button: {
    background: '#fff',
    border: '1px solid #b3b3b3',
    fontSize: '0.8rem',
    padding: '0.3rem 0.7rem',
    cursor: 'pointer',
    color: '#616161',
    float: 'right',
    transition: 'border-color 0.2s',
    ':hover': {
      borderColor: '#737373',
    },
  },
};
class ExportButton extends React.Component<Props> {
  downloadFile() {
    /* eslint-disable */
    if (this.props.projectId !== undefined && this.props.projectId !== null) {
      if (window.location.host === 'insights.theplando.com') {
        window.open(`https://theplando.com/projects/${this.props.projectId}/insights/export?start_date=${this.props.startDate}&widget_name=${this.props.widgetName}&cmd=export_insights`, '_blank');
      } else if (window.location.host === 'insights.qa.theplando.com') {
        window.open(`https://qa.theplando.com/projects/${this.props.projectId}/insights/export?start_date=${this.props.startDate}&widget_name=${this.props.widgetName}&cmd=export_insights`, '_blank');
      } else if (window.location.host === 'insights.leanplando.com') {
        window.open(`https://leanplando.com/projects/${this.props.projectId}/insights/export?start_date=${this.props.startDate}&widget_name=${this.props.widgetName}&cmd=export_insights`, '_blank');
      } else {
        window.open(`http://devplando.com/projects/${this.props.projectId}/insights/export?start_date=${this.props.startDate}&widget_name=${this.props.widgetName}&cmd=export_insights`, '_blank');
      }
    }
  }

  render(): React.Node {
    return (
      <Button style={styles.button} onClick={this.downloadFile.bind(this)}>Export</Button>
    );
  }
}

export default ExportButton;
