// @flow
import ActionTypes from './action-types';
import type { TopMostActivitiesAction, DelayedActivity } from './sagas';

export type TopMostDelayedActivitiesState = {
  chartData: DelayedActivity[],
  loading: boolean | null,
};

const initialState: TopMostDelayedActivitiesState = { loading: null, chartData: [] };

export default function (state: TopMostDelayedActivitiesState = initialState, action: TopMostActivitiesAction): TopMostDelayedActivitiesState {
  switch (action.type) {
    case ActionTypes.GET_TOP_MOST_DELAYED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        chartData: action.data.activities,
        loading: false,
      };
    default:
      return state;
  }
}
