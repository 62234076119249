// @flow
import ActionTypes from './action-types';
import type { WeeklyPPCAction, WeeklyPPCResponse } from './sagas';

export type WeeklyPPCData = {
  completionPercent: number,
  totalTasks: number,
  startDate: string,
  finishDate: string,
  committableTasks: number,
};

type WeeklyPPCViewModel = {
  ppcMedian: number,
  data: WeeklyPPCData[],
  ppcMin: number,
  ppcMax: number,
  committedTasksMax: number,
  committedTasksMin: number,
  committedTasksMedian: number,
};

export type WeeklyPPCState = {
  chartData: WeeklyPPCViewModel,
  loading: boolean | null,
};

const getOrderedList: Function = function getOrderedList(data: WeeklyPPCResponse): WeeklyPPCViewModel {
  const list: WeeklyPPCData[] = data.ppc_report.map((item: *): WeeklyPPCData => ({
    completionPercent: item.percentage_complete * 100,
    totalTasks: item.total_activities,
    startDate: item.start_date,
    finishDate: item.end_date,
    committableTasks: item.committable_tasks,
  }));

  return {
    ppcMedian: data.ppc_median,
    committedTasksMedian: data.committed_tasks_median,
    ppcMin: data.ppc_min,
    ppcMax: data.ppc_max,
    committedTasksMax: data.committed_tasks_max,
    committedTasksMin: data.committed_tasks_min,
    data: list,
  };
};

const initialState: WeeklyPPCState = {
  loading: null,
  chartData: {
    ppcMedian: 0,
    ppcMin: 0,
    ppcMax: 0,
    committedTasksMax: 0,
    committedTasksMin: 0,
    committedTasksMedian: 0,
    data: [],
  },
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyPPCState = initialState, action: WeeklyPPCAction): Exact<WeeklyPPCState> {
  switch (action.type) {
    case ActionTypes.GET_WEEKLY_PPC_SUCCESS:
      return {
        ...state,
        chartData: getOrderedList(action.data),
        loading: false,
      };
    default:
      return state;
  }
}
