// @flow

export type TopMostDelayedTasks = {
  GET_TOP_MOST_DELAYED_TASKS: 'getTopMostDelayedTasks',
  GET_TOP_MOST_DELAYED_TASKS_SUCCESS: 'getTopMostDelayedTasksSuccess',
  GET_TOP_MOST_DELAYED_TASKS_FAIL: 'getTopMostDelayedTasksFail',
}

const actionTypes: TopMostDelayedTasks = {
  GET_TOP_MOST_DELAYED_TASKS: 'getTopMostDelayedTasks',
  GET_TOP_MOST_DELAYED_TASKS_SUCCESS: 'getTopMostDelayedTasksSuccess',
  GET_TOP_MOST_DELAYED_TASKS_FAIL: 'getTopMostDelayedTasksFail',
};

export default actionTypes;
