// @flow

export type TopCriticalPathDelayedActivities = {
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES: 'getTopCriticalPathDelayedActivities',
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_SUCCESS: 'getTopCriticalPathDelayedActivitiesSuccess',
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_FAIL: 'getTopCriticalPathDelayedActivitiesFail',
}

const actionTypes: TopCriticalPathDelayedActivities = {
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES: 'getTopCriticalPathDelayedActivities',
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_SUCCESS: 'getTopCriticalPathDelayedActivitiesSuccess',
  GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_FAIL: 'getTopCriticalPathDelayedActivitiesFail',
};

export default actionTypes;
