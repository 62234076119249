// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import type { MapStateToProps, Connector } from 'react-redux';

import Loader from '../../components/loader';
import ActionTypes from './action-types';
import PieChart from '../../components/charts/pie';
import ToggleButton from '../../components/toggle-button';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { delayResponsibilityState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: delayResponsibilityState,
  project: Project,
};

type State = {
  selectedFilter: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  emptydataContainer: {
    height: '430px',
    textAlign: 'center',
    paddingTop: 50,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class DelayResponsibilitiesWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  };

  componentDidMount() {
    this.fetchData();
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState(
      {
        selectedFilter: val,
      },
      this.fetchData,
    );
  }

  getWidget(): React.Node {
    return this.props.data.chartData.length !== 0 && this.props.data.loading === false ? (
      <div>
        <div style={styles.filterContainer}>
          <ToggleButton
            items={[{ value: 'occurences', label: 'Occurences' }, { value: 'days', label: 'Total Project Delay (days)' }]}
            value={this.state.selectedFilter}
            onChange={this.onFilterChange}
          />
        </div>
        <PieChart data={this.props.data.chartData} />
        <div style={{ fontSize: '0.8rem' }}>* Information accuracy available from Feb 8, 2018</div>
      </div>
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_RESPONSIBILITY_OF_DELAY_REASONS,
      data: { projectId: this.props.project.id, filter: this.state.selectedFilter },
    });
  }

  render(): React.Node {
    if (this.props.project.id === '646') {
      return (
        <section style={styles.widget}>
          <h1 style={styles.header}>Delay Responsibility</h1>
          <div style={{ ...styles.filterContainer, ...styles.emptydataContainer }}>
            <span>No Data Available</span>
          </div>
        </section>
      );
    }
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={this.props.data.chartData.length !== 0 ? { ...styles.widget, height: 536 } : styles.widget}>
        <h1 className="header-widget-common">
          Delay Responsibility
          <img
            data-tip="The delays occurred in activities or tasks or constraints are assigned to specific companies of the project, this displays the proportional responsibilities for the delays in the project"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.getWidget()}
      </section>
    );
  }
}

// export default connect(
//   (state: Reducers): { data: delayResponsibilityState } => ({ data: state.delayResponsibility }),
// )(DelayResponsibilitiesWidget);
type TMapStateToFilter = MapStateToProps<Reducers, any, { data: delayResponsibilityState }>;

const mapStateToProps: TMapStateToFilter = (state: any): Object => ({ data: state.delayResponsibility });

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(DelayResponsibilitiesWidget);
