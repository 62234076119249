// @flow

export type TopExtendedTasks = {
  GET_TOP_EXTENDED_TASKS: 'getTopExtendedTasks',
  GET_TOP_EXTENDED_TASKS_SUCCESS: 'getTopExtendedTasksSuccess',
  GET_TOP_EXTENDED_TASKS_FAIL: 'getTopExtendedTasksFail',
}

const actionTypes: TopExtendedTasks = {
  GET_TOP_EXTENDED_TASKS: 'getTopExtendedTasks',
  GET_TOP_EXTENDED_TASKS_SUCCESS: 'getTopExtendedTasksSuccess',
  GET_TOP_EXTENDED_TASKS_FAIL: 'getTopExtendedTasksFail',
};

export default actionTypes;
