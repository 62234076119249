// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getWeeklyCommitmentReliabilityApi from './apis';

export type WeeklyCommitmentReliability = {
  end_date: string,
  constraint_management: number,
  plan_reliability: number,
  ppc: number,
  site_readiness: number,
  confirmed_date: string,
}

type StatisticsData = {
  max: number,
  median: number,
  min: number
}

export type WeeklyCommitmentReliabilityStats = {
  constraint_management: StatisticsData,
  plan_reliability: StatisticsData,
  ppc: StatisticsData,
  site_readiness: StatisticsData,
}

type WeeklyCommitmentReliabilityResponse = {
  committment_insights: WeeklyCommitmentReliability[],
  commitment_stats: WeeklyCommitmentReliabilityStats
};

export type WeeklyCommitmentReliabilityAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WEEKLY_COMMITMENT_RELIABILITY_SUCCESS'>,
  data: WeeklyCommitmentReliabilityResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WEEKLY_COMMITMENT_RELIABILITY_FAIL'>,
|};

function* getWeeklyCommitmentReliability(action: { data: { projectId: string, week: string } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: WeeklyCommitmentReliabilityResponse } = yield call(getWeeklyCommitmentReliabilityApi, action.data.projectId, action.data.week);
    yield put({ type: ActionTypes.GET_WEEKLY_COMMITMENT_RELIABILITY_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_WEEKLY_COMMITMENT_RELIABILITY_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_COMMITMENT_RELIABILITY, getWeeklyCommitmentReliability);
}
