// @flow

export type TopEarlyFinishedTasks = {
  GET_TOP_EARLY_FINISHED_TASKS: 'getTopEarlyFinishedTasks',
  GET_TOP_EARLY_FINISHED_TASKS_SUCCESS: 'getTopEarlyFinishedTasksSuccess',
  GET_TOP_EARLY_FINISHED_TASKS_FAIL: 'getTopEarlyFinishedTasksFail',
}

const actionTypes: TopEarlyFinishedTasks = {
  GET_TOP_EARLY_FINISHED_TASKS: 'getTopEarlyFinishedTasks',
  GET_TOP_EARLY_FINISHED_TASKS_SUCCESS: 'getTopEarlyFinishedTasksSuccess',
  GET_TOP_EARLY_FINISHED_TASKS_FAIL: 'getTopEarlyFinishedTasksFail',
};

export default actionTypes;
