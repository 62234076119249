// @flow

import * as React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';
import type { TaskDetailState } from './reducer';

import ActionTypes from './action-types';
import TaskDetail from './task-detail';

import withProjectContext from '../auth/with-project-context';

type Props = {
  activityId: number,
  taskName: string,
  taskId: number,
  project: Project,
  dispatch: Object => mixed,
  data: TaskDetailState,
};

const modalStyles: Object = {
  content: {
    right: 'auto',
    width: '53rem',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '30rem',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

class TaskDetailContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_TASK_DETAIL,
      data: {
        activityId: this.props.activityId,
        taskId: this.props.taskId,
        projectId: this.props.project.id,
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.CLEAR_TASK_DETAIL });
  }

  closeModal = () => {
    this.props.dispatch({
      type: 'hideModal',
    });
  }

  render(): React.Node {
    return (
      <Modal isOpen contentLabel={this.props.taskName} style={modalStyles} onRequestClose={this.closeModal}>
        <TaskDetail task={this.props.data.task} taskName={this.props.taskName} />
      </Modal>
    );
  }
}

const Component: any = compose(
  connect((state: Reducers): { data: Object } => ({ data: state.taskDetail })),
  withProjectContext,
)(TaskDetailContainer);

export default Component;
