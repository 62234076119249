// @flow

export type ActivityWorkableBacklogTypes = {
  GET_PROJECT_STATUS: 'getProjectStatusWeekly',
  GET_PROJECT_STATUS_SUCCESS: 'getProjectStatusSuccessWeekly',
  GET_PROJECT_STATUS_FAIL: 'getProjectStatusFailWeekly',
}

const actionTypes: ActivityWorkableBacklogTypes = {
  GET_PROJECT_STATUS: 'getProjectStatusWeekly',
  GET_PROJECT_STATUS_SUCCESS: 'getProjectStatusSuccessWeekly',
  GET_PROJECT_STATUS_FAIL: 'getProjectStatusFailWeekly',
};

export default actionTypes;
