// @flow

export type WeeklyDelayMitigationActionTypes = {
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT: 'getWeeklyProductivityImprovement',
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_SUCCESS: 'getWeeklyProductivityImprovementSuccess',
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_FAIL: 'getWeeklyProductivityImprovementFail',
}

const actionTypes: WeeklyDelayMitigationActionTypes = {
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT: 'getWeeklyProductivityImprovement',
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_SUCCESS: 'getWeeklyProductivityImprovementSuccess',
  GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_FAIL: 'getWeeklyProductivityImprovementFail',
};

export default actionTypes;
