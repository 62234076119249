// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getWorkableBacklogApi from './apis';
// TODO: need to confirm whether company_id is string or number or can be both
export type CompaniesWorkableBacklogResponse = {
  num_should_do: number,
  num_can_do: number,
  num_will_do: number,
  num_done: number,
  company_name: string,
  company_id: number | string,
};

export type AllWorkableBacklogResponse = {
  num_should_do: number,
  num_can_do: number,
  num_will_do: number,
  num_done: number,
};

export type WorkableBacklogSuccessType = {
  companies_backlog: CompaniesWorkableBacklogResponse[],
  all: AllWorkableBacklogResponse,
};

export type WorkableBacklogAction = {|
  type: typeof ActionTypes.GET_WORKABLE_BACKLOG_SUCCESS,
  data: WorkableBacklogSuccessType,
|} | {|
  type: typeof ActionTypes.GET_WORKABLE_BACKLOG_FAIL,
|};

function* getWorkableBacklog(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: WorkableBacklogSuccessType } = yield call(getWorkableBacklogApi, action.data.projectId, action.data.startDate);
    const actionData: WorkableBacklogAction = { type: ActionTypes.GET_WORKABLE_BACKLOG_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: WorkableBacklogAction = { type: ActionTypes.GET_WORKABLE_BACKLOG_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WORKABLE_BACKLOG, getWorkableBacklog);
}
