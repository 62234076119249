// @flow

export type DelayMitigationEffortActionTypes = {
  GET_DELAY_MITIGATION_EFFORT: 'getDelayMitigationEffort',
  GET_DELAY_MITIGATION_EFFORT_SUCCESS: 'getDelayMitigationEffortSuccess',
  GET_DELAY_MITIGATION_EFFORT_FAIL: 'getDelayMitigationEffortFail',
  SET_DELAY_MITIGATION_EFFORT_METHOD: 'setDelayMitigationEffortMethod'
}

const actionTypes: DelayMitigationEffortActionTypes = {
  GET_DELAY_MITIGATION_EFFORT: 'getDelayMitigationEffort',
  GET_DELAY_MITIGATION_EFFORT_SUCCESS: 'getDelayMitigationEffortSuccess',
  GET_DELAY_MITIGATION_EFFORT_FAIL: 'getDelayMitigationEffortFail',
  SET_DELAY_MITIGATION_EFFORT_METHOD: 'setDelayMitigationEffortMethod',
};

export default actionTypes;
