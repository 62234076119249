// @flow

import * as React from 'react';
import { withRouter } from 'react-router';

import type { ContextRouter } from 'react-router';

export type Project = { id?: string | null };

export default function withProjectContext<PropsInput: {}>(
  Component: React.ComponentType<{ project: Project } & PropsInput>,
): React.ComponentType<{ project: Project } & PropsInput & ContextRouter> {
  class UserProjectContext extends React.PureComponent<
    ContextRouter & PropsInput,
  > {
    static defaultProps: ContextRouter & PropsInput;

    project = {};

    constructor(props: ContextRouter & PropsInput) {
      super(props);
      this.project = {
        id: this.props.match.params.projectId,
      };
    }

    componentWillReceiveProps(props: ContextRouter & PropsInput) {
      if (this.props.match.params.projectId !== props.match.params.projectId) {
        this.project = {
          id: this.props.match.params.projectId,
        };
      }
    }

    render(): React.Node {
      // const { match: Match, location: Location, history: RouterHistory, ...props: PropsInput } = this.props;
      const props: PropsInput = this.props;

      return (
        <div>
          <Component {...props} project={this.project} />
        </div>
      );
    }
  }

  const El: Function = withRouter(UserProjectContext);

  return El;
}
