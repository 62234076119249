// @flow

export type OthersReasonsPropTypes = {
  GET_OTHERS_DELAYS: 'getOthersReason',
  GET_OTHERS_DELAYS_SUCCESS: 'getOthersReasonSuccess',
  GET_OTHERS_DELAYS_FAIL: 'getOthersReasonFail',
  CLEAR_OTHERS_DELAYS: 'clearOthersReason',
}

const actionTypes: OthersReasonsPropTypes = {
  GET_OTHERS_DELAYS: 'getOthersReason',
  GET_OTHERS_DELAYS_SUCCESS: 'getOthersReasonSuccess',
  GET_OTHERS_DELAYS_FAIL: 'getOthersReasonFail',
  CLEAR_OTHERS_DELAYS: 'clearOthersReason',
};

export default actionTypes;
