// @flow
import { isEmpty } from 'ramda';

import ActionTypes from './action-types';
import type { ProjectStatusAction, ProjectStatusSuccessType } from './sagas';

export type ProjectStatusWeeklyState = {
  data: ProjectStatusSuccessType | {},
  ProjectStatusLoading: boolean | null,
};

const initialState: ProjectStatusWeeklyState = {
  ProjectStatusLoading: null,
  data: {},
};

const formatData: Function = function formatData(data: ProjectStatusSuccessType): ProjectStatusSuccessType | {} {
  if (isEmpty(data)) {
    return {};
  }
  return data;
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ProjectStatusWeeklyState = initialState, action: ProjectStatusAction): Exact<ProjectStatusWeeklyState> {
  let dp: ProjectStatusSuccessType;

  switch (action.type) {
    case ActionTypes.GET_PROJECT_STATUS_SUCCESS:
      dp = formatData(action.data);
      return {
        ...state,
        data: dp,
        ProjectStatusLoading: false,
      };
    default:
      return state;
  }
}
