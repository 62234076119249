// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getProjectStatusApi from './apis';

export type ProjectStatusSuccessType = {
  number_of_days: number,
  status: string,
};

export type ProjectStatusAction = {|
  type: typeof ActionTypes.GET_PROJECT_STATUS_SUCCESS,
  data: ProjectStatusSuccessType,
|} | {|
  type: typeof ActionTypes.GET_PROJECT_STATUS_FAIL,
|};

function* getProjectStatus(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ProjectStatusSuccessType } = yield call(getProjectStatusApi, action.data.projectId, action.data.startDate);
    const actionData: ProjectStatusAction = { type: ActionTypes.GET_PROJECT_STATUS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ProjectStatusAction = { type: ActionTypes.GET_PROJECT_STATUS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_STATUS, getProjectStatus);
}
