// @flow

export type DashboardActionTypes = {
  UPDATE_WIDGETS_LIST: 'updateWidgetsList',
}

const actionTypes: DashboardActionTypes = {
  UPDATE_WIDGETS_LIST: 'updateWidgetsList',
};

export default actionTypes;
