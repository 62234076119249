// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import LineChart from '../../components/charts/line';
import SelectBox from '../../components/select-box';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { WeeklyPPCState, WeeklyPPCData } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { CompanyListType } from '../../project/reducer';

import colors from '../../styles/colors';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;


type Props = {
  dispatch: Dispatch,
  data: WeeklyPPCState,
  project: Project,
  companies: CompanyListType,
}

type State = {
  selectedFilter: string,
}

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chart: {
    width: '100%',
  },
  table: {
    marginTop: '0rem',
    marginLeft: '3rem',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  label: {
    marginRight: 6,
  },
};


type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

class WeeklyPPC extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: '_all',
  }

  componentDidMount() {
    this.fetchData();
  }

  onFilterChange: Function;

  onFilterChange(val: Object) {
    this.setState({
      selectedFilter: val.value,
    }, this.fetchData);
  }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const completionPercents: number[] = [];
    const tasks: number[] = [];
    const committableTasks: number[] = [];
    this.props.data.chartData.data.forEach((item: WeeklyPPCData) => {
      labels.push(`${moment(item.startDate).format('DD MMM')} - ${moment(item.finishDate).format('DD MMM')}`);
      completionPercents.push(item.completionPercent);
      tasks.push(item.totalTasks);
      committableTasks.push(item.committableTasks);
    });
    return [
      {
        labels,
        values: completionPercents,
        name: 'PPC',
        color: colors.themePrimaryColor,
      },
      {
        labels,
        values: tasks,
        name: 'Committed Tasks',
        color: colors.themeSecondaryColor,
        yaxis: 'y2',
      },
      // {
      //   labels,
      //   values: committableTasks,
      //   name: 'Committable Tasks',
      //   color: colors.yellow,
      //   yaxis: 'y2',
      // },
    ];
  }

  getCompanyList(): React.Node {
    if (this.props.companies) {
      let companies: Object[] = [];
      companies = this.props.companies.map((c: Object): Object => ({ label: c.name, value: c.id }));
      companies.unshift({
        label: 'All',
        value: '_all',
      });
      return (
        <div style={styles.filterContainer}>
          <span style={styles.label}>Company</span>
          <SelectBox
            options={companies}
            value={this.state.selectedFilter}
            onChange={this.onFilterChange}
          />
        </div>
      );
    }
    return null;
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_WEEKLY_PPC,
      data: { projectId: this.props.project.id, companyId: this.state.selectedFilter === '_all' ? null : this.state.selectedFilter },
    });
  }

  renderTable(): React.Node {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th />
            <th>Median</th>
            <th>Min</th>
            <th>Max</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PPC</td>
            <td>{`${this.props.data.chartData.ppcMedian.toFixed(0)}%`}</td>
            <td>{`${this.props.data.chartData.ppcMin}%`}</td>
            <td>{`${this.props.data.chartData.ppcMax}%`}</td>
          </tr>
          <tr>
            <td>Committed Tasks</td>
            <td>{this.props.data.chartData.committedTasksMedian}</td>
            <td>{this.props.data.chartData.committedTasksMin}</td>
            <td>{this.props.data.chartData.committedTasksMax}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render(): React.Node {
    const yAxis: Object = {
      yaxis: {
        title: 'PPC',
        ticksuffix: '%',
        rangemode: 'tozero',
        autorange: true,
      },
      yaxis2: {
        title: 'Committed Tasks',
        side: 'right',
        overlaying: 'y',
        rangemode: 'tozero',
        zeroline: false,
        autorange: true,
      },
    };

    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150 }] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
Percent Plan Complete (Weekly)
          <img data-tip="Percent Plan Complete (PPC) is a metric to measure the commitment level of the subcontractors.<br />On a weekly basis, it shows how many tasks were committed to, and what proportion of them were completed." width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.getCompanyList()}
        <div style={styles.container}>
          <div style={styles.chart}>
            <LineChart
              data={this.getData()}
              yAxes={yAxis}
            />
          </div>
          {this.renderTable()}
        </div>
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: WeeklyPPCState, companies: CompanyListType } => ({ data: state.weeklyPPC, companies: state.project.companies }),
)(WeeklyPPC);

component.style = {
  width: '98%',
};

export default component;
