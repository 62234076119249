// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopMostDelayedActivitiesApi from './apis';

export type DelayedActivity = {
  actual_start_date: string,
  base_start_date: string,
  name: string,
  parent: string,
  start_date_difference: string,
  id: number,
  wbs: string,
};

type TopMostActivitiesResponse = {
  activities: Array<DelayedActivity>,
};

export type TopMostActivitiesAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_MOST_DELAYED_ACTIVITIES_SUCCESS'>,
  data: TopMostActivitiesResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_MOST_DELAYED_ACTIVITIES_FAIL'>,
|};

function* getTopMostDelayedActivities(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopMostActivitiesResponse } = yield call(getTopMostDelayedActivitiesApi, action.data.projectId, action.data.startDate);
    const actionData: TopMostActivitiesAction = { type: ActionTypes.GET_TOP_MOST_DELAYED_ACTIVITIES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopMostActivitiesAction = { type: ActionTypes.GET_TOP_MOST_DELAYED_ACTIVITIES_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_MOST_DELAYED_ACTIVITIES, getTopMostDelayedActivities);
}
