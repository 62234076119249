// @flow

import ActionTypes from './action-types';
import type { WeeklyCommitmentReliabilityAction, WeeklyCommitmentReliability, WeeklyCommitmentReliabilityStats } from './sagas';

export type WeeklyCommitmentReliabilityState = {
  chartData: WeeklyCommitmentReliability[],
  statsData: WeeklyCommitmentReliabilityStats | null,
  loading: boolean | null,
};

const initialState: WeeklyCommitmentReliabilityState = {
  loading: null, chartData: [], statsData: null, projectStart: null,
};

export default function (state: WeeklyCommitmentReliabilityState = initialState, action: WeeklyCommitmentReliabilityAction): WeeklyCommitmentReliabilityState {
  switch (action.type) {
    case ActionTypes.GET_WEEKLY_COMMITMENT_RELIABILITY_SUCCESS:
      return {
        ...state,
        chartData: action.data.committment_insights,
        statsData: action.data.commitment_stats,
        loading: false,
      };
    default:
      return state;
  }
}
