// @flow

import ActionTypes from './action-types';
import type { CommitmentReliabilityAction, CommitmentReliabilitySuccessType } from './sagas';

export type CommitmentReliabilityWeeklyState = {
  data: CommitmentReliabilitySuccessType,
  CommitmentReliabilityLoading: boolean | null,
};

const initialState: CommitmentReliabilityWeeklyState = {
  CommitmentReliabilityLoading: null,
  data: [],
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: CommitmentReliabilityWeeklyState = initialState, action: CommitmentReliabilityAction): Exact<CommitmentReliabilityWeeklyState> {
  let dp: CommitmentReliabilitySuccessType;

  switch (action.type) {
    case ActionTypes.GET_COMMITMENT_RELIABILITY_SUCCESS:
      dp = action.data;
      return {
        ...state,
        data: dp,
        CommitmentReliabilityLoading: false,
      };
    default:
      return state;
  }
}
