// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopExtendedTasksApi from './apis';

export type ExtendedTask = {
  base_duration: number,
  actual_duration: number,
  name: string,
  parent: string,
  id: number,
  ratio: number,
};

type TopExtendedTasksResponse = {
  tasks: Array<ExtendedTask>,
};

export type TopExtendedTasksAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EXTENDED_TASKS_SUCCESS'>,
  data: TopExtendedTasksResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EXTENDED_TASKS_FAIL'>,
|};

function* getTopExtendedTasks(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopExtendedTasksResponse } = yield call(getTopExtendedTasksApi, action.data.projectId, action.data.startDate);
    const actionData: TopExtendedTasksAction = { type: ActionTypes.GET_TOP_EXTENDED_TASKS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopExtendedTasksAction = { type: ActionTypes.GET_TOP_EXTENDED_TASKS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_EXTENDED_TASKS, getTopExtendedTasks);
}
