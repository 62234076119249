// @flow

import * as React from 'react';
import type { OthersDelaysState } from './reducer';
import type { OthersDelaysResponseType } from './sagas';

type Props = {
  // dispatch: Object => mixed,
  data: OthersDelaysState,
};

const styles: Object = {
  column: {
    textAlign: 'left',
  },
  table: {
    width: '100%',
  },
};

const OthersDelays: Function = (props: Props): React.Node | null => {
  const othersDelays: OthersDelaysResponseType | null = props.data.othersDelays;
  if (othersDelays === null) return null;
  return (
    <div>
      {!props.data.isLoading
        && (
          <table style={styles.table}>
            <thead>
              <tr style={styles.column}>
                <th>Delay Reason</th>
                <th>Occurences</th>
                <th>Total Project Delay (days)</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(othersDelays).map((item: any): any => (
                <tr key={item}>
                  <td>{item}</td>
                  <td>{othersDelays[item].occurrences}</td>
                  <td>{othersDelays[item].project_delay}</td>
                </tr>))}
            </tbody>
          </table>)}
    </div>);
};

export default OthersDelays;
