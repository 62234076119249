// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import DMActionTypes from '../weekly-delay-mitigation/action-types';
import PIActionTypes from '../weekly-productivity-improvement/action-types';
import LineChart from '../../components/charts/line';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { WeeklyDelayMitigationState, WeeklyDelayMitigationItem } from '../weekly-delay-mitigation/reducer';
import type { WeeklyProductivityImprovementState, WeeklyProductivityImprovementItem } from '../weekly-productivity-improvement/reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import colors from '../../styles/colors';

type Action = { type: $Values<typeof DMActionTypes> | $Values<typeof PIActionTypes>, data: Object }
type Dispatch = (action: Action) => void;


type Props = {
  dispatch: Dispatch,
  delayMitigation: WeeklyDelayMitigationState,
  productivityImprovement: WeeklyProductivityImprovementState,
  project: Project,
}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chart: {
    width: '100%',
  },
  table: {
    marginTop: '0rem',
    marginLeft: '3rem',
  },
  label: {
    marginRight: 6,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WeeklyPPC extends React.PureComponent<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  //   this.onFilterChange = this.onFilterChange.bind(this);
  // }

  // state = {
  //   selectedFilter: '_all',
  // }

  componentDidMount() {
    this.fetchData();
  }

  // onFilterChange: Function;

  // onFilterChange(val: Object) {
  //   this.setState({
  //     selectedFilter: val.value,
  //   }, this.fetchData);
  // }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const delayMitigation: number[] = [];
    const dmText: string[] = [];
    const productivityImprovement: number[] = [];
    const piText: string[] = [];

    this.props.delayMitigation.weeklyDelayMitigation.forEach((item: WeeklyDelayMitigationItem) => {
      labels.push(`${moment(item.endDate).format('DD MMM YYYY')}`);
      delayMitigation.push(item.delayMitigation);
      dmText.push(`Total Duration Shortened: ${item.durationShortened}<br> Total Duration Extended: ${item.durationExtended}`);
    });

    this.props.productivityImprovement.weeklyProductivityImprovement.forEach((item: WeeklyProductivityImprovementItem) => {
      if (labels.length === 0) {
        labels.push(`${moment(item.endDate).format('DD MMM YYYY')}`);
      }
      productivityImprovement.push(item.productivityImprovement);
      piText.push(`Total Duration Shortened: ${item.durationShortened}<br> Total Base Duration: ${item.totalBaseDuration}`);
    });

    return [
      {
        labels,
        values: delayMitigation,
        name: 'Delay Mitigation Effort',
        color: colors.themePrimaryColor,
        texts: dmText,
      },
      {
        labels,
        values: productivityImprovement,
        name: 'Productivity Improvement Effort',
        color: colors.themeSecondaryColor,
        texts: piText,
      },
    ];
  }

  fetchData() {
    this.props.dispatch({
      type: DMActionTypes.GET_WEEKLY_DELAY_MITIGATION,
      data: { projectId: this.props.project.id },
    });
    this.props.dispatch({
      type: PIActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT,
      data: { projectId: this.props.project.id },
    });
  }

  renderWidget(yAxis: Object): React.Node {
    return this.props.delayMitigation.weeklyDelayMitigation.length !== 0 || this.props.productivityImprovement.weeklyProductivityImprovement.length !== 0
      ? (
        <div style={styles.container}>
          <div style={styles.chart}>
            <LineChart
              data={this.getData()}
              yAxes={yAxis}
            />
          </div>
        </div>)
      : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    const yAxis: Object = {
      yaxis: {
        ticksuffix: '%',
        rangemode: 'tozero',
        autorange: true,
      },
    };

    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
Weekly Productivity Improvement and Delay Mitigation Effort
          <img data-tip="This graphs shows the weekly impacts on the project of actual and future (forecast) events.<br />The weekly actual delay/mitigation value is the total extension/shortening of the project duration due to actual (realized) events happening in the week.<br />The weekly forecast delay/mitigation value is the total extension/shortening of the project duration due to any forecast changes happening to future works that planned out by the team" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.delayMitigation.weeklyDelayMitigationLoading === null && this.props.productivityImprovement.weeklyProductivityImprovementLoading === null
            ? <Loader /> : this.renderWidget(yAxis)
        }
      </section>
    );
  }
}

const component: any = connect((state: Reducers): {
    delayMitigation: WeeklyDelayMitigationState,
    productivityImprovement: WeeklyProductivityImprovementState
  } => ({ delayMitigation: state.weeklyDelayMitigation, productivityImprovement: state.weeklyProductivityImprovement }))(WeeklyPPC);

component.style = {
  width: '98%',
};

export default component;
