// @flow
import ActionTypes from './action-types';
import type { TopMostTasksAction, DelayedTask } from './sagas';

export type TopMostDelayedTasksState = {
  chartData: DelayedTask[],
  loading: boolean | null,
};

const initialState: TopMostDelayedTasksState = { loading: null, chartData: [] };

export default function (state: TopMostDelayedTasksState = initialState, action: TopMostTasksAction): TopMostDelayedTasksState {
  switch (action.type) {
    case ActionTypes.GET_TOP_MOST_DELAYED_TASKS_SUCCESS:
      return {
        ...state,
        chartData: action.data.tasks,
        loading: false,
      };
    default:
      return state;
  }
}
