// @flow

export type ActivityDetailPropTypes = {
  GET_TASK_DETAIL: 'getTaskDetail',
  GET_TASK_DETAIL_SUCCESS: 'getTaskDetailSuccess',
  GET_TASK_DETAIL_FAIL: 'getTaskDetailFail',
  CLEAR_TASK_DETAIL: 'clearTaskDetail',
}

const actionTypes: ActivityDetailPropTypes = {
  GET_TASK_DETAIL: 'getTaskDetail',
  GET_TASK_DETAIL_SUCCESS: 'getTaskDetailSuccess',
  GET_TASK_DETAIL_FAIL: 'getTaskDetailFail',
  CLEAR_TASK_DETAIL: 'clearTaskDetail',
};

export default actionTypes;
