// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getWorkableBacklogApi from './apis';

export type WorkableBacklog = {
  num_should_do: number,
  num_can_do: number,
  num_will_do: number,
  num_done: number,
  complete_percentage: number,
  end_date: string,
  project_id: number,
  start_date: string,
};

export type WorkableBacklogSuccessType = {
  weekly_workable_baklog: WorkableBacklog,
};

export type WorkableBacklogAction = {|
  type: typeof ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG_SUCCESS,
  data: WorkableBacklogSuccessType,
|} | {|
  type: typeof ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG_FAIL,
|};

function* getWorkableBacklog(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: WorkableBacklogSuccessType } = yield call(getWorkableBacklogApi, action.data.projectId);
    const actionData: WorkableBacklogAction = { type: ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: WorkableBacklogAction = { type: ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG, getWorkableBacklog);
}
