// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopMostDelayedTasksApi from './apis';

export type DelayedTask = {
  actual_start_date: string,
  base_start_date: string,
  name: string,
  parent: string,
  start_date_difference: string,
  id: number,
  wbs: string,
};

type TopMostTasksResponse = {
  tasks: Array<DelayedTask>,
};

export type TopMostTasksAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_MOST_DELAYED_TASKS_SUCCESS'>,
  data: TopMostTasksResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_MOST_DELAYED_TASKS_FAIL'>,
|};

function* getTopMostDelayedTasks(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopMostTasksResponse } = yield call(getTopMostDelayedTasksApi, action.data.projectId, action.data.startDate);
    const actionData: TopMostTasksAction = { type: ActionTypes.GET_TOP_MOST_DELAYED_TASKS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopMostTasksAction = { type: ActionTypes.GET_TOP_MOST_DELAYED_TASKS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_MOST_DELAYED_TASKS, getTopMostDelayedTasks);
}
