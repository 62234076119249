// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopCriticalPathDelayedActivitiesApi from './apis';

export type CriticalPathDelayedActivity = {
  name: string,
  project_delay: number,
  reason: string[],
  // cause: string,
  // cause_name: string,
  delay: string,
  id: number,
  deleted: boolean,
};

type TopCriticalPathDelayedActivitiesResponse = {
  critical_activities: Array<CriticalPathDelayedActivity>,
};

export type TopCriticalPathDelayedActivitiesAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_SUCCESS'>,
  data: TopCriticalPathDelayedActivitiesResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_FAIL'>,
|};

function* getTopCriticalPathDelayedActivities(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopCriticalPathDelayedActivitiesResponse } = yield call(getTopCriticalPathDelayedActivitiesApi, action.data.projectId);
    const actionData: TopCriticalPathDelayedActivitiesAction = { type: ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopCriticalPathDelayedActivitiesAction = { type: ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES, getTopCriticalPathDelayedActivities);
}
