// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getWorkDelayApi from './apis';

export type WorkDelay = {
  project_delay: number,
  variance: string,
  delay: number,
  delay_description: string,
  responsible_company_name: string,
  responsible_entity: string,
  activity_name: string,
  task_name: string,
  id: number,
  delayed_on: string,
  delayed_by: string,
  delayed_artifact: string,
  delay_description: string,
  responsible_party: string,
  parent_chain: string,
};

type WorkDelayResponse = {
  delays: Array<WorkDelay>,
};

export type WorkDelayAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WORK_DELAY_SUCCESS'>,
  data: WorkDelayResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WORK_DELAY_FAIL'>,
|};

function* getWorkDelays(action: { data: { projectId: string, weekId: number } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: WorkDelayResponse } = yield call(getWorkDelayApi, action.data.projectId, action.data.weekId);
    const actionData: WorkDelayAction = { type: ActionTypes.GET_WORK_DELAY_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: WorkDelayAction = { type: ActionTypes.GET_WORK_DELAY_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WORK_DELAY, getWorkDelays);
}
