// @flow

type ReasonForDelaysActionTypes = {
  GET_REASON_FOR_DELAYS: 'getReasonForDelays',
  GET_REASON_FOR_DELAYS_SUCCESS: 'getReasonForDelaysSuccess',
  GET_REASON_FOR_DELAYS_FAIL: 'getReasonForDelaysFail',
}

const actionTypes: ReasonForDelaysActionTypes = {
  GET_REASON_FOR_DELAYS: 'getReasonForDelays',
  GET_REASON_FOR_DELAYS_SUCCESS: 'getReasonForDelaysSuccess',
  GET_REASON_FOR_DELAYS_FAIL: 'getReasonForDelaysFail',
};

export default actionTypes;
