// @flow

import * as React from 'react';
import './loader.css';

const styles: Object = {
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 20px',
    fontSize: '20px',
    height: '100%',
  },
};

type Props = {
  children: any,
};

const Loader: Function = function Loader(props: Props): React.Node {
  return (
    <div style={styles.loader}>
      Loading
      <span className="loader-dot">.</span>
      <span className="loader-dot">.</span>
      <span className="loader-dot">.</span>
      {
        props.children
      }
    </div>
  );
};

export default Loader;
