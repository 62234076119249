// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getReasonForDelaysApi from './apis';

export type ReasonForDelayResponseItem = {
  classification: string,
  count: number,
  project_delay: number,
  artifact_delay: number,
  type: string,
};

export type ReasonForDelaysResponse = {
  variance: [
    ReasonForDelayResponseItem,
  ]
};

export type ReasonForDelayAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_REASON_FOR_DELAYS_SUCCESS'>,
  data: ReasonForDelaysResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_REASON_FOR_DELAYS_FAIL'>,
|};


function* getReasonForDelays(action: { data: { projectId: string, filter: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ReasonForDelaysResponse } = yield call(getReasonForDelaysApi, action.data.projectId, action.data.filter, action.data.startDate);
    yield put({ type: ActionTypes.GET_REASON_FOR_DELAYS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_REASON_FOR_DELAYS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_REASON_FOR_DELAYS, getReasonForDelays);
}
