// @flow

import * as React from 'react';

import type { ReasonForDelayItem } from './reducer';

type Props = {
  delayReasons: ReasonForDelayItem[],
  showArtifact: boolean,
  showOthersDelaysModal: any,
};

const styles: Object = {
  reasonColumn: {
    paddingRight: '1.2rem',
  },
  table: {
    width: '100%',
  },
};

export default function ReasonsTable(props: Props): React.Node {
  const { showOthersDelaysModal }: any = props;
  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.tableHeader}>Delay Reason</th>
          <th style={styles.tableHeader}>Occurrences</th>
          {props.showArtifact ? (
            <th style={styles.tableHeader}>
              Total Delay
              <br />
              {' '}
(days)
            </th>
          ) : null}
          <th style={styles.tableHeader}>
            Total Project Delay
            <br />
            {' '}
(days)
          </th>
        </tr>
      </thead>
      <tbody>
        {props.delayReasons.map(
          (item: ReasonForDelayItem): React.Node => (
            <tr key={item.label}>
              {item.label === 'Others' ? (
                <td>
                  <a onClick={showOthersDelaysModal} className="underline-hover">
                    {item.label}
                  </a>
                </td>
              ) : (
                <td style={styles.reasonColumn}>{item.label}</td>
              )}
              <td>{item.value}</td>
              {props.showArtifact ? <td>{item.artifactDelay}</td> : null}
              <td>{item.projectDelay}</td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
}
