// @flow

type ProjectSummaryActionTypes = {
  GET_CPR_WEEKLY: 'getCprWeekly',
  GET_CPR_WEEKLY_SUCCESS: 'getCprWeeklySuccess',
  GET_CPR_WEEKLY_FAIL: 'getCprWeeklyFail',
}

const actionTypes: ProjectSummaryActionTypes = {
  GET_CPR_WEEKLY: 'getCprWeekly',
  GET_CPR_WEEKLY_SUCCESS: 'getCprWeeklySuccess',
  GET_CPR_WEEKLY_FAIL: 'getCprWeeklyFail',
};

export default actionTypes;
