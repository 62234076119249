// @flow

export type DelayDetailPropTypes = {
  GET_DELAY_HISTORY: 'getDelayHistory',
  GET_DELAY_HISTORY_SUCCESS: 'getDelayHistorySuccess',
  GET_DELAY_HISTORY_FAIL: 'getDelayHistoryFail',
  UPDATE_DELAY_HISTORY: 'updateDelayHistory',
  UPDATE_DELAY_HISTORY_SUCCESS: 'updateDelayHistorySuccess',
  UPDATE_DELAY_HISTORY_FAIL: 'updateDelayHistoryFail',
  CLEAR_DELAY_DETAIL: 'clearDelayDetail',
  GET_DELAY_COMMENTS: 'getDelayComments',
  GET_DELAY_COMMENTS_SUCCESS: 'getDelayCommentsSuccess',
  GET_DELAY_COMMENTS_FAIL: 'getDelayCommentsFail',
  POST_DELAY_COMMENTS: 'postDelayComments',
  POST_DELAY_COMMENTS_SUCCESS: 'postDelayCommentsSuccess',
  POST_DELAY_COMMENTS_FAIL: 'postDelayCommentsFail',
}

const actionTypes: DelayDetailPropTypes = {
  GET_DELAY_HISTORY: 'getDelayHistory',
  GET_DELAY_HISTORY_SUCCESS: 'getDelayHistorySuccess',
  GET_DELAY_HISTORY_FAIL: 'getDelayHistoryFail',
  UPDATE_DELAY_HISTORY: 'updateDelayHistory',
  UPDATE_DELAY_HISTORY_SUCCESS: 'updateDelayHistorySuccess',
  UPDATE_DELAY_HISTORY_FAIL: 'updateDelayHistoryFail',
  CLEAR_DELAY_DETAIL: 'clearDelayDetail',
  GET_DELAY_COMMENTS: 'getDelayComments',
  GET_DELAY_COMMENTS_SUCCESS: 'getDelayCommentsSuccess',
  GET_DELAY_COMMENTS_FAIL: 'getDelayCommentsFail',
  POST_DELAY_COMMENTS: 'postDelayComments',
  POST_DELAY_COMMENTS_SUCCESS: 'postDelayCommentsSuccess',
  POST_DELAY_COMMENTS_FAIL: 'postDelayCommentsFail',
};

export default actionTypes;
