// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';

import Loader from '../../components/loader';
import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import ExportButton from '../../components/export';

import type { TopShortenedActivitiesState } from './reducer';
import type { ShortenedActivity } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopShortenedActivitiesState,
  project: Project,
  week: string,
};

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  taskNameTable: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  // parentHierarchy: {
  //   fontSize: '0.70rem',
  //   marginTop: '0.2rem',
  // },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '140',
  '2': '175',
  '3': '207',
  '4': '240',
  '5': '270',
  '6': '302',
  '7': '334',
  '8': '366',
  '9': '398',
  '10': '430',
};
/* eslint-enable */

class TopShortenedActivitiesWidget extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.fetchData();
    }
  }

  // getParentHierarchy(activity: ShortenedActivity): string {
  //   const parents: Array<string> = activity.parent.split(' | ').reverse();
  //   parents.pop();
  //   return parents.join(' ➔ ');
  // }

  getData(): ChartData[] {
    return this.props.data.chartData.map(
      (activity: ShortenedActivity): ChartData => ({
        label: activity.name + activity.wbs,
        value: activity.ratio * -100,
        color: colors.themeSecondaryColor,
        text:
          `${activity.wbs} ${activity.name.trim()}<br>Actual duration: ${
            activity.actual_duration
          }<br>`
          + `Base duration: ${
            activity.base_duration
          }<br>Deduction: ${activity.base_duration - activity.actual_duration}`,
      }),
    );
  }

  // getXAxisLabels(): string[] {
  //   return this.props.data.chartData.map((activity: ShortenedActivity): string => {
  //     if (activity.name.length > 20) return `${activity.name.substring(0, 15)}...`;

  //     return activity.name;
  //   });
  // }

  showActivityDetailModal = (activity: ShortenedActivity) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.id,
        activityName: activity.name,
      },
    });
  };

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_SHORTENED_ACTIVITIES,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderTable(): React.Element<any> {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.taskNameTable}>Activity</th>
            <th className="hide-element">Base Duration (days)</th>
            <th className="hide-element">Actual Duration (days)</th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.chartData.map(
            (activity: ShortenedActivity, i: number): React.Node => (
              <tr key={i}>
                <td style={styles.taskNameTable}>
                  <a
                    onClick={this.showActivityDetailModal.bind(this, activity)}
                    className="underline-hover"
                  >
                    {activity.name}
                  </a>
                </td>
                <td className="hide-element">{activity.base_duration}</td>
                <td className="hide-element">{activity.actual_duration}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  }

  renderWidget(options: Object): React.Node {
    return this.props.data.chartData.length !== 0 && this.props.data.loading === false
      ? (
        <div>
          { this.props.week ? <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="top_shortened_activities" /> : null }
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '60%' }}>
              {this.renderTable()}
            </div>
            <div style={{ width: '40%' }}>
              <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h' }} />
            </div>
          </div>
        </div>) : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    const options: Object = {
      height: getHeight[this.props.data.chartData.length],
      yaxis: {
        showticklabels: false,
      },
      xaxis: {
        title: 'Percentage Shortened',
      },
      margin: {
        t: 35,
        r: 10,
        l: 3,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Top Shortened Activities
          <img
            data-tip="This chart lists the top shortened activities sorted based on the most shortened to least shortened"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget(options)
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: TopShortenedActivitiesState } => ({
    data: state.topShortenedActivities,
  }),
)(TopShortenedActivitiesWidget);

component.style = {
  width: '98%',
};

export default component;
