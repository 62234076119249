// @flow

export type ActivityProfileEndDateActionTypes = {
  GET_END_DATE_PROFILE: 'getEndDateProfile',
  GET_END_DATE_PROFILE_SUCCESS: 'getEndDateProfileSuccess',
  GET_END_DATE_PROFILE_FAIL: 'getEndDateProfileFail',
  GET_END_DATE_PROFILE_TASK: 'getEndDateProfileTask',
  GET_END_DATE_PROFILE_TASK_SUCCESS: 'getEndDateProfileTaskSuccess',
  GET_END_DATE_PROFILE_TASK_FAIL: 'getEndDateProfileTaskFail',
}

const actionTypes: ActivityProfileEndDateActionTypes = {
  GET_END_DATE_PROFILE: 'getEndDateProfile',
  GET_END_DATE_PROFILE_SUCCESS: 'getEndDateProfileSuccess',
  GET_END_DATE_PROFILE_FAIL: 'getEndDateProfileFail',
  GET_END_DATE_PROFILE_TASK: 'getEndDateProfileTask',
  GET_END_DATE_PROFILE_TASK_SUCCESS: 'getEndDateProfileTaskSuccess',
  GET_END_DATE_PROFILE_TASK_FAIL: 'getEndDateProfileTaskFail',
};

export default actionTypes;
