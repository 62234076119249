// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

import type { RateOfProgressWeeklyState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import './styles.css';
import ExportButton from '../../components/export';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: RateOfProgressWeeklyState,
  project: Project,
  week: string,
  widgetStyle: Object,
}

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '350px',
  },
  note: {
    display: 'block',
    marginTop: '30px',
    fontSize: '0.8rem',
  },
};

class RateOfProgressWeekly extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  getCSS(str: string, numData: Object, min: string, max: string): Object {
    if (max === min) {
      return { bottom: 155 };
    } if (max === str) {
      return { bottom: 260 };
    } if (min === str) {
      return { bottom: 10 };
    }
    const delta: number = numData[max] - numData[min];
    const pixel: number = ((numData[str] - numData[min]) * (250 / delta)) + 10;
    return { bottom: pixel };
  }

  getRequiredSection(data: Object, isOverlapping: boolean, requiredPosition: Object): React.Node {
    return (
      isOverlapping ? (
        <div className="absolute-position margin-left-rate-progress required-rate" style={requiredPosition}>
          <div className="left-value-container">
            {data.required_progress_rate}
          </div>
          <div className="arrow-container-rate-progress">
            <div className="left-text-container">Required rate</div>
            <div className="triangle-right-rate-progress" style={{ borderLeft: '10px solid #fadd00' }} />
            <div className="horizontal-left-line-rate-progress" style={{ border: '1px solid #fadd00' }} />
          </div>
        </div>
      ) : (
        <div className="absolute-position" style={requiredPosition}>
          <div className="right-value-container">
            {data.required_progress_rate}
          </div>
          <div className="arrow-container-rate-progress">
            <div className="horizontal-right-line-rate-progress" />
            <div className="triangle-left-rate-progress" />
            <div className="right-text-container">Required rate</div>
          </div>
        </div>
      ));
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_RATE_OF_PROGRESS,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    const data: Object = this.props.data.data;
    const numData: Object = {};
    /** eslint-disable */
    //  used in place of window.isNAN()
    // https://www.reddit.com/r/javascript/comments/7t3z9g/isnan_numberisnan/
    if (!Number.isNaN(data.actual_progress_rate)) {
      numData.actual_progress_rate = Number(data.actual_progress_rate);
    }
    if (!Number.isNaN(data.required_progress_rate)) {
      numData.required_progress_rate = Number(data.required_progress_rate);
    }
    if (!Number.isNaN(data.planned_progress_rate)) {
      numData.planned_progress_rate = Number(data.planned_progress_rate);
    }
    /** eslint-enable */
    const max: string = Object.keys(numData).reduce((a: string, b: string): string => (numData[a] > numData[b] ? a : b));
    const min: string = Object.keys(numData).reduce((a: string, b: string): string => (numData[a] < numData[b] ? a : b));

    const plannedPosition: Object = this.getCSS('planned_progress_rate', numData, min, max);
    const requiredPosition: Object = this.getCSS('required_progress_rate', numData, min, max);
    const actualPosition: Object = this.getCSS('actual_progress_rate', numData, min, max);

    const isOverlapping: boolean = Math.abs(requiredPosition.bottom - plannedPosition.bottom) < 20;

    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    if (numData.planned_progress_rate === 0 && numData.required_progress_rate === 0 && numData.actual_progress_rate === 0 && this.props.data.RateOfProgressLoading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <div>
        <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="rate_of_progress" />
        <div className="center-text" style={{ position: 'relative' }}>
          <div className="arrow-container-rate-progress" style={{ flexDirection: 'column' }}>
            <div className="triangel-up-rate-progress" />
            <div className="vertical-line-rate-progress" />
          </div>
          <div>
            {
              data.actual_progress_rate === 'N/A' ? null
                : (
                  <div className="absolute-position margin-left-rate-progress" style={actualPosition}>
                    <div className="left-value-container">
                      {data.actual_progress_rate}
                    </div>
                    <div className="arrow-container-rate-progress">
                      <div className="left-text-container">Actual rate</div>
                      <div className="triangle-right-rate-progress" />
                      <div className="horizontal-left-line-rate-progress" />
                    </div>
                  </div>)
            }
            {
              data.required_progress_rate !== 'N/A' ? this.getRequiredSection(data, isOverlapping, requiredPosition) : null
            }
            {
              data.planned_progress_rate === 'N/A' ? null
                : (
                  <div className="absolute-position" style={plannedPosition}>
                    <div className="right-value-container">
                      {data.planned_progress_rate}
                    </div>
                    <div className="arrow-container-rate-progress">
                      <div className="horizontal-right-line-rate-progress" style={{ border: 'solid 1px #3777b0' }} />
                      <div className="triangle-left-rate-progress" style={{ borderRight: '10px solid #3777b0' }} />
                      <div className="right-text-container">Planned rate</div>
                    </div>
                  </div>)
            }
          </div>
        </div>
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={this.props.widgetStyle}>
        <h1 className="header-widget-common">
Rate of Progress
          <img data-tip="This displays the comparison among the Required Progress/Actual Progress/Planned Progress for that week" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.RateOfProgressLoading === null ? <Loader /> : this.renderWidget()
        }
        <span style={styles.note}>*Data available from July 2018</span>
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: RateOfProgressWeeklyState } => ({
    data: state.rateOfProgressWeekly,
  }),
)(RateOfProgressWeekly);

Component.style = {
  width: '98%',
};

export default Component;
