// @flow

export type WeeklyDelayMitigationActionTypes = {
  GET_WEEKLY_DELAY_MITIGATION: 'getWeeklyDelayMitigation',
  GET_WEEKLY_DELAY_MITIGATION_SUCCESS: 'getWeeklyDelayMitigationSuccess',
  GET_WEEKLY_DELAY_MITIGATION_FAIL: 'getWeeklyDelayMitigationFail',
}

const actionTypes: WeeklyDelayMitigationActionTypes = {
  GET_WEEKLY_DELAY_MITIGATION: 'getWeeklyDelayMitigation',
  GET_WEEKLY_DELAY_MITIGATION_SUCCESS: 'getWeeklyDelayMitigationSuccess',
  GET_WEEKLY_DELAY_MITIGATION_FAIL: 'getWeeklyDelayMitigationFail',
};

export default actionTypes;
