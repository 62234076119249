// @flow

export type TopShortenedActivities = {
  GET_TOP_SHORTENED_ACTIVITIES: 'getTopShortenedActivities',
  GET_TOP_SHORTENED_ACTIVITIES_SUCCESS: 'getTopShortenedActivitiesSuccess',
  GET_TOP_SHORTENED_ACTIVITIES_FAIL: 'getTopShortenedActivitiesFail',
}

const actionTypes: TopShortenedActivities = {
  GET_TOP_SHORTENED_ACTIVITIES: 'getTopShortenedActivities',
  GET_TOP_SHORTENED_ACTIVITIES_SUCCESS: 'getTopShortenedActivitiesSuccess',
  GET_TOP_SHORTENED_ACTIVITIES_FAIL: 'getTopShortenedActivitiesFail',
};

export default actionTypes;
