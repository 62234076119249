// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import BarChart from '../../components/charts/bar';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import SelectBox from '../../components/select-box';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import ExportButton from '../../components/export';

import type { WorkableBacklogState, CompaniesWorkableBacklogItem, AllWorkableBacklog } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: WorkableBacklogState,
  project: Project,
  week: string,
  widgetStyle: Object,
}

type ViewModel = Array<{
  label: string,
  value: number,
  color: string,
  text: string,
}>;

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  selectBoxContainer: {
    maxWidth: '250px',
  },
  // selectBoxCompany: {
  //   margin: '0 1% 0 4%',
  // },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '340px',
  },
  note: {
    fontSize: '0.8rem',
  },
};

type State = {
  companyId: number | string,
};

class WorkableBacklogWeekly extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      companyId: 'all',
    };
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onCompanyChange = this.onCompanyChange.bind(this);

  onCompanyChange(e: Object) {
    this.setState({
      companyId: e.value,
    });
  }

  getFormattedData(data: CompaniesWorkableBacklogItem | AllWorkableBacklog): ViewModel {
    const shouldDo: number = data.shouldDo;
    const canDo: number = data.canDo;
    const willDo: number = data.willDo;
    const done: number = data.done;
    const traces: ViewModel = [
      {
        label: `(${shouldDo})<br />Should do`,
        value: shouldDo,
        text: `${shouldDo} Should Do`,
        color: colors.tundora,
      },
      {
        label: `(${canDo})<br />Can do`,
        value: canDo,
        text: `${canDo} Can do`,
        color: colors.cerulean,
      },
      {
        label: `(${willDo})<br />Will do`,
        value: willDo,
        text: `${willDo} Will Do`,
        color: colors.orange,
      },
      {
        label: `(${done})<br />Did`,
        value: done,
        text: `${done} Did`,
        color: colors.aquaForest,
      },
    ];

    return traces;
  }

  getCompanies = this.getCompanies.bind(this);

  getWorkableBacklogGraph = this.getWorkableBacklogGraph.bind(this);

  getWorkableBacklogGraph(companyId: number | string, options: Object): React.Node {
    if (!this.props.data.data.companiesBacklog || this.props.data.data.companiesBacklog[0].companyId === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    const companies: Object[] = this.props.data.data.companiesBacklog.map((c: CompaniesWorkableBacklogItem): Object => ({ label: c.companyName, value: c.companyId }));
    companies.unshift({
      label: 'All companies',
      value: 'all',
    });
    const index: number = companyId !== 'all' ? companies.findIndex((obj: Object): boolean => obj.value === companyId) - 1 : -2;
    const data: CompaniesWorkableBacklogItem | AllWorkableBacklog = index !== -2 ? this.props.data.data.companiesBacklog[index] : this.props.data.data.all;
    if (data.shouldDo === 0 && data.canDo === 0 && data.willDo === 0 && data.done === 0) {
      return (
        <div>
          <div style={styles.selectBoxContainer}>
            {this.getCompanies(companies)}
          </div>
          <span style={styles.noData}>
          No data available for selected company
          </span>
          <span style={styles.note}>*Data available from March 2018</span>
        </div>
      );
    }
    return (
      <div>
        <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="workable_backlog" />
        <div style={styles.selectBoxContainer}>
          {this.getCompanies(companies)}
        </div>
        <BarChart
          data={this.getFormattedData(data)}
          options={options}
        />
        <span style={styles.note}>*Data available from March 2018</span>
      </div>
    );
  }

  getCompanies(companies: Object[]): React.Node {
    return (
      <SelectBox
        options={companies}
        value={this.state.companyId}
        onChange={this.onCompanyChange}
      />
    );
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_WORKABLE_BACKLOG,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    // const dataOptions: Object = {
    //   textposition: 'inside',
    //   textfont: {
    //     family: 'Lato',
    //     size: 18,
    //     color: colors.white,
    //   },
    // };
    const options: Object = {
      height: 340,
      tickangle: '0',
      margin: {
        t: 10,
        l: 10,
        r: 10,
        b: 60,
        pad: 12,
      },
      xaxis: {
        fixedrange: true,
        tickfont: {
          size: 12,
        },
      },
      yaxis: {
        showgrid: false,
        showticklabels: false,
        fixedrange: true,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    if (this.props.data.data.companiesBacklog === undefined && this.props.data.WorkableBacklogLoading === false) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      this.getWorkableBacklogGraph(this.state.companyId, options)
    );
  }

  render(): React.Node {
    return (
      <section style={this.props.widgetStyle}>
        <h1 className="header-widget-common">
Workable Backlog
          <img data-tip="This chart provides the total number of tasks that the team SHOULD/ CAN/ WILL/ and DID in a week" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.WorkableBacklogLoading === null ? <Loader /> : this.renderWidget()
        }
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: WorkableBacklogState } => ({
    data: state.workableBacklog,
  }),
)(WorkableBacklogWeekly);

Component.style = {
  width: '98%',
};

export default Component;
