// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import Chart from './chart';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { DelaysAndMitigationsState, DelaysAndMitigationsItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: DelaysAndMitigationsState,
  project: Project,
};

type ViewModel = {
  labels: string[],
  values: number[],
  texts: string[],
  name: string,
  color: string,
  textColor: ?string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WeeklyDelayMitigation extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_DELAYS_AND_MITIGATIONS,
      data: { projectId: this.props.project.id },
    });
  }

  getChartData(data: DelaysAndMitigationsItem[]): Object {
    const actualDelays: number[] = [];
    const forecastDelays: number[] = [];
    const actualMitigations: number[] = [];
    const forecastMitigations: number[] = [];

    const total: number[] = [];
    const dates: string[] = [];

    const forecastDelaysHoverTexts: string[] = [];
    const actualDelaysHoverTexts: string[] = [];
    const actualMitigationsHoverTexts: string[] = [];
    const forecastMitigationsHoverTexts: string[] = [];
    const totalHoverTexts: string[] = [];

    data.forEach((item: DelaysAndMitigationsItem) => {
      // const totalValue: number = item.delaySum;
      const totalValue: number = item.effectiveDelay;
      actualDelays.push(item.actualDelays);
      forecastDelays.push(item.forecastDelays);
      actualMitigations.push(item.actualAdvances * -1);
      forecastMitigations.push(item.forecastAdvances * -1);
      dates.push(moment(item.endDate).format('DD MMM YYYY'));
      total.push(totalValue);

      actualDelaysHoverTexts.push(`Actual Delays: ${item.actualDelays} days`);
      forecastDelaysHoverTexts.push(`Forecast Delays: ${item.forecastDelays} days`);
      actualMitigationsHoverTexts.push(`Actual Mitigations: ${item.actualAdvances} days`);
      forecastMitigationsHoverTexts.push(`Forecast Mitigations: ${item.forecastAdvances} days`);
      totalHoverTexts.push(`Effective Delay: ${totalValue} <br>Base End Date: ${item.projectBaseEndDate !== null
        ? moment(item.projectBaseEndDate).format('DD MMM YY') : 'Not Available'}
        <br>Expected End Date: ${item.projectBaseEndDate !== null ? moment(item.projectExpectedEndDate).format('DD MMM YY') : 'Not Available'}`);
    });
    const traces: ViewModel[] = [
      {
        labels: dates,
        values: actualDelays,
        texts: actualDelaysHoverTexts,
        name: 'Actual Delays',
        color: colors.themePrimaryColor,
        textColor: '#fff',
      },
      {
        labels: dates,
        values: forecastDelays,
        texts: forecastDelaysHoverTexts,
        name: 'Forecast Delays',
        color: colors.mandysPink,
        textColor: '#fff',
      },
      {
        labels: dates,
        values: actualMitigations,
        texts: actualMitigationsHoverTexts,
        name: 'Actual Mitigations',
        color: colors.yellow,
        textColor: '#000',
      },
      {
        labels: dates,
        values: forecastMitigations,
        texts: forecastMitigationsHoverTexts,
        name: 'Forecast Mitigations',
        color: colors.lightYellow,
        textColor: '#000',
      },
    ];

    const totalLine: ViewModel[] = [
      {
        labels: dates,
        values: total,
        texts: totalHoverTexts,
        name: 'Cumulative Project Delay',
        color: colors.themeSecondaryColor,
        textColor: null,
      },
    ];

    return {
      traces,
      totalLine,
    };
  }

  renderWidget(chartData: Object): React.Node {
    return this.props.data.delaysAndMitigations.length !== 0 && this.props.data.delaysAndMitigationsLoading === false ? (
      <React.Fragment>
        <Chart barData={chartData.traces} lineData={chartData.totalLine} />
        <p style={{ fontSize: '0.8rem' }}>* Information accuracy available from July, 2018</p>
      </React.Fragment>
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  render(): React.Node {
    const chartData: Object = this.getChartData(this.props.data.delaysAndMitigations);
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Weekly Delays and Mitigations
          <img
            data-tip="This graph shows the net effect delay calculated from (actual delay + forecast delay) - (actual mitigation + forecast mitigation)"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.delaysAndMitigationsLoading === null ? <Loader /> : this.renderWidget(chartData)}
      </section>
    );
  }
}

const Component: any = connect((state: Reducers): { data: DelaysAndMitigationsState } => (
  { data: state.delaysAndMitigations }))(WeeklyDelayMitigation);

Component.style = {
  width: '98%',
};

export default Component;
