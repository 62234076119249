// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';
import { groupBy } from 'ramda';

import Colors from '../styles/colors';
import type { TaskDetailResponseType } from './sagas';

type Props = {
  taskName: string,
  task: TaskDetailResponseType | null,
};

type State = {
  selectedTab: string,
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  tabsContainer: {
    marginTop: '1rem',
    overflowY: 'auto',
  },
  tabs: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    paddingLeft: '1rem',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  variantName: {
    color: '#6f6f6f',
  },
  delay: {
    color: '#6f6f6f',
    paddingRight: '10px',
  },
  dates: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    lineHeight: 1.5,
  },
  dateRow: {
    display: 'flex',
  },
  date: {
    flex: 1,
  },
  projectDelay: {
    color: '#ff0000',
  },
  delayHeading: {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
  },
  description: {
    color: '#6f6f6f',
  },
  delayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '10px',
  },
  displayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    padding: '20px 0 20px 0',
  },
  delayedBy: {
    color: '#6f6f6f',
  },
};

const delayTexts: Object = {
  access_space: 'Access / Space',
  approval_permits: 'Approval / Permits',
  client_decision: 'Client decision',
  contracts: 'Contracts',
  drawings: 'Drawings',
  equipment: 'Equipment',
  inspection: 'Inspection',
  labor: 'Labor',
  logistics_storage: 'Logistics / Storage',
  material: 'Material',
  method_statement: 'Method statement',
  minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
  rfi: 'RFI',
  safety_requirement: 'Safety requirement',
  re_scheduling: 'Re-Scheduling ',
  unforeseen_site_conditions: 'Unforeseen site conditions',
  weather: 'Weather',
  schedule_error: 'Schedule error',
  defects_rework: 'Defects rework',
  first_lookahead: 'Delay on First Lookahead',
  others: 'Others',
};

function getParentHierarchy(task: TaskDetailResponseType | null): string {
  if (!task) return '';

  const parents: Array<string> = task.parent_chain.split(' | ').reverse();
  parents.pop();
  return parents.join(' ➔ ');
}

export default class TaskDetail extends React.Component<Props, State> {
  state = {
    selectedTab: '',
  };

  componentWillReceiveProps(props: Props) {
    if (this.props.task !== props.task) {
      this.setState({ selectedTab: this.getActiveTab(props) });
    }
  }

  getActiveTab(props: Props): string {
    if (props.task === null) return '';

    if (props.task.task_constraint_delays.length !== 0) return 'taskConstraint';
    if (props.task.task_delays.length !== 0) return 'task';

    return '';
  }

  setTab(tab: string) {
    this.setState({ selectedTab: tab });
  }

  getResponsibleParty(delayItem: Object): React.Node {
    const party: string = delayItem.delay_responsible_party;
    if (party) {
      return (
        <div style={styles.delay}>
          Responsible Party:
          {party}
        </div>
      );
    }
    return null;
  }

  getActivityProjectDelay(item: Object): React.Node {
    if (item.project_delay) {
      return (
        <div style={styles.delayMessage}>
          Activity{' '}
          {item.affected_day === 'start_day' ? 'start date' : 'end date'} caused
          project to delay by{' '}
          <span style={styles.projectDelay}>{item.project_delay} day(s)</span>
        </div>
      );
    }
    return (
      <div style={styles.delayMessage}>
        Activity
        {item.affected_day === 'start_day' ? 'start date' : 'end date'} delayed
        by
        {item.delay} day(s)
      </div>
    );
  }

  getTaskProjectDelay(item: Object): React.Node {
    if (item.project_delay) {
      return (
        <div style={styles.delayMessage}>
          {item.name}{' '}
          {item.affected_day === 'start_day' ? 'start date' : 'end date'} caused
          project to delay by{' '}
          <span style={styles.projectDelay}>{item.project_delay} day(s)</span>
        </div>
      );
    }
    return (
      <div style={styles.delayMessage}>
        {item.name}{' '}
        {item.affected_day === 'start_day' ? 'start date' : 'end date'} delayed
        by {item.delay} day(s)
      </div>
    );
  }

  getGroupedList(list: Object[]): Object {
    const task: Object[] = list;
    const byProjectDelays: (Object[]) => Object = groupBy(
      (taskDelay: Object): string =>
        taskDelay.project_delay === null
          ? 'otherDelayReasons'
          : 'projectDelayReasons',
    );
    return byProjectDelays(task);
  }

  renderGivenDelays(delays: Object[]): React.Node {
    return delays
      ? delays.map(
          (item: Object, index: number): React.Node => (
            <li style={styles.variant} key={index}>
              {this.state.selectedTab !== 'activity'
                ? this.getTaskProjectDelay(item)
                : this.getActivityProjectDelay(item)}
              {delayTexts[item.variance] || item.variance ? (
                <div style={styles.variantName}>
                  Reason:
                  {delayTexts[item.variance] || item.variance}
                </div>
              ) : null}
              <div style={styles.description}>
                Description:
                {item.description}
              </div>
              {this.getResponsibleParty(item)}
              {item.delayed_by ? (
                <div style={styles.delayedBy}>
                  Recorded by:
                  {item.delayed_by}
                </div>
              ) : null}
            </li>
          ),
        )
      : null;
  }

  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    if (this.props.task === null) return null;

    if (this.props.task.task_constraint_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'taskConstraint')}
          style={
            this.state.selectedTab === 'taskConstraint'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="task-constraints"
        >
          Task Constraint Delays
        </li>,
      );
    }

    if (this.props.task.task_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'task')}
          style={
            this.state.selectedTab === 'task'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="task"
        >
          Task Delays
        </li>,
      );
    }

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  renderDelays(): React.Node {
    const types: Object = {
      taskConstraint: 'task_constraint_delays',
      task: 'task_delays',
    };

    if (this.props.task === null) return null;
    if (this.state.selectedTab === '') return null;

    const groupedDelays: Object = this.getGroupedList(
      this.props.task[types[this.state.selectedTab]],
    );

    return (
      <ul style={styles.variants}>
        {this.renderGivenDelays(groupedDelays.projectDelayReasons)}
        {this.renderGivenDelays(groupedDelays.otherDelayReasons)}
      </ul>
    );
  }

  renderDates(): React.Node | null {
    const task: TaskDetailResponseType | null = this.props.task;
    let startDate: React.Node;
    let endDate: React.Node;

    if (task === null) return null;

    if (task.actual_start_date) {
      startDate = (
        <div style={styles.date}>
          Actual start date:
          {moment(task.actual_start_date).format('DD MMM YYYY')}
        </div>
      );
    } else {
      startDate = (
        <div style={styles.date}>
          Expected start date:
          {moment(task.start_date).format('DD MMM YYYY')}
        </div>
      );
    }

    if (task.actual_end_date) {
      endDate = (
        <div style={styles.date}>
          Actual end date:
          {moment(task.actual_end_date).format('DD MMM YYYY')}
        </div>
      );
    } else {
      endDate = (
        <div style={styles.date}>
          Expected end date:
          {moment(task.end_date).format('DD MMM YYYY')}
        </div>
      );
    }

    return (
      <div style={styles.dates}>
        <div style={styles.dateRow}>
          <div style={styles.date}>
            Base start date:
            {moment(task.base_start_date).format('DD MMM YYYY')}
          </div>
          <div style={styles.date}>
            Base end date:
            {moment(task.base_end_date).format('DD MMM YYYY')}
          </div>
        </div>
        <div style={styles.dateRow}>
          {startDate}
          {endDate}
        </div>
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{this.props.taskName}</h2>
        <div style={styles.parent}>{getParentHierarchy(this.props.task)}</div>
        <div>{this.renderDates()}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div style={{ flex: 1, overflowY: 'auto' }}>{this.renderDelays()}</div>
      </section>
    );
  }
}
