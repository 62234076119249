// @flow

import * as React from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Home from './home';

import store from './store';

const App: Function = (): React.Element<any> => (
  <Provider store={store}>
    <div className="App">
      <Router>
        <Route path="/projects/:projectId" component={Home} />
      </Router>
    </div>
  </Provider>
);

export default App;
