// @flow
import ActionTypes from './action-types';
import type { DashboardAction } from './sagas';

export type widget = { name: string };
export type dashboardState = {
  widgets: Array<widget>,
  loading: boolean | null,
};


const initialState: dashboardState = {
  loading: null,
  widgets: [
    { name: 'projectStatus' },
    { name: 'projectProjection' },
    { name: 'projectWeeklyProgress' },
    { name: 'projectWeeklyCostProgress' },
    { name: 'topCriticalPathDelayedActivities' },
    { name: 'weeklyWorkableBacklog' },
    { name: 'weeklyCommitmentReliability' },
    { name: 'activityProfileDate' },
    { name: 'activityProfileDuration' },
    { name: 'activityProfileEndDate' },
    { name: 'weeklyActivityProfileDate' },
    { name: 'weeklyActivityProfileEndDate' },
    { name: 'weeklyActivityProfileDuration' },
    // { name: 'weeklyPPC' },

    { name: 'reasonForDelays' },
    { name: 'delayResponsibility' },
    { name: 'delayReasons' },
    { name: 'delaysAndMitigations' },
    { name: 'weeklyMitigationImprovementEffort' },
    { name: 'workDelay' },
    { name: 'constraintDelay' },

    { name: 'topMostDelayedActivities' },
    { name: 'topExtendedActivities' },
    { name: 'topShortenedActivities' },
    { name: 'topEarlyFinishedActivities' },
    { name: 'topDelayedConstraints' },
  ],
};

export default function (state: dashboardState = initialState, action: DashboardAction): dashboardState {
  switch (action.type) {
    case ActionTypes.UPDATE_WIDGETS_LIST:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
