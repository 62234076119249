// @flow

export type ActivityWorkableBacklogTypes = {
  GET_TOP_DELAY_REASONS: 'getTopDelayReasonsWeekly',
  GET_TOP_DELAY_REASONS_SUCCESS: 'getTopDelayReasonsWeeklySuccess',
  GET_TOP_DELAY_REASONS_FAIL: 'getTopDelayReasonsWeeklyFail',
}

const actionTypes: ActivityWorkableBacklogTypes = {
  GET_TOP_DELAY_REASONS: 'getTopDelayReasonsWeekly',
  GET_TOP_DELAY_REASONS_SUCCESS: 'getTopDelayReasonsWeeklySuccess',
  GET_TOP_DELAY_REASONS_FAIL: 'getTopDelayReasonsWeeklyFail',
};

export default actionTypes;
