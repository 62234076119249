// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { WorkableBacklogAction, WorkableBacklog } from './sagas';

export type WeeklyWorkableBacklogItem = {
  shouldDo: number,
  canDo: number,
  willDo: number,
  done: number,
  completePercentage: number,
  endDate: string,
};

export type WeeklyWorkableBacklogState = {
  weeklyWorkableBacklog: WeeklyWorkableBacklogItem[],
  weeklyWorkableBacklogLoading: boolean | null,
};

const initialState: WeeklyWorkableBacklogState = {
  weeklyWorkableBacklogLoading: null,
  weeklyWorkableBacklog: [],
};

const formatData: Function = function formatData(data: WorkableBacklog): WeeklyWorkableBacklogItem[] {
  return map((item: WorkableBacklog): WeeklyWorkableBacklogItem => (
    {
      shouldDo: item.num_should_do,
      canDo: item.num_can_do,
      willDo: item.num_will_do,
      done: item.num_done,
      completePercentage: item.complete_percentage,
      endDate: item.end_date,
    }
  ), data);
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyWorkableBacklogState = initialState, action: WorkableBacklogAction): Exact<WeeklyWorkableBacklogState> {
  let dp: WeeklyWorkableBacklogItem[];

  switch (action.type) {
    case ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG_SUCCESS:
      dp = formatData(action.data.weekly_workable_baklog);
      return {
        ...state,
        weeklyWorkableBacklog: dp,
        weeklyWorkableBacklogLoading: false,
      };
    default:
      return state;
  }
}
