// @flow

import axios from 'axios';
import config from '../config/config.json';

export function getCompanies(projectId: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/companies/list`);
}

export function getProjectDetails(projectId: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}`);
}

export function getAllWeeks(projectId: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/insights/do_weeks`);
}
