// @flow

type ProjectWeeklyProgress = {
  GET_WEEKLY_PPC: 'getWeeklyPPC',
  GET_WEEKLY_PPC_SUCCESS: 'getWeeklyPPCSuccess',
  GET_WEEKLY_PPC_FAIL: 'getWeeklyPPCFail',
}

const actionTypes: ProjectWeeklyProgress = {
  GET_WEEKLY_PPC: 'getWeeklyPPC',
  GET_WEEKLY_PPC_SUCCESS: 'getWeeklyPPCSuccess',
  GET_WEEKLY_PPC_FAIL: 'getWeeklyPPCFail',
};

export default actionTypes;
