// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectWeeklyProgressApi from './apis';

export type ProjectWeeklyProgress = {
  finish_date: string,
  start_date: string,
  overall_percentage: number,
  planned_percentage: number,
}

type ProjectWeeklyProgressResponse = {
  project_start_date: string,
  weekly_progress: ProjectWeeklyProgress[]
};

export type ProjectWeeklyProgressAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_PROGRESS_SUCCESS'>,
  data: ProjectWeeklyProgressResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_PROGRESS_FAIL'>,
|};

function* getProjectWeeklyProgress(action: { data: { projectId: string} }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: ProjectWeeklyProgressResponse } = yield call(getProjectWeeklyProgressApi, action.data.projectId);
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_PROGRESS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_PROGRESS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_WEEKLY_PROGRESS, getProjectWeeklyProgress);
}
