// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getDelaysAndMitigationsApi from './apis';

export type DelaysAndMitigations = {
  actual_advances: number,
  actual_delays: number,
  // delay_sum: number,
  end_date: string,
  forecast_advances: number,
  forecast_delays: number,
  start_date: string,
  total_advances: number,
  total_delays: number,
  project_base_end_date: string,
  project_expected_end_date: string,
  effective_delay: number,
};

export type DelaysAndMitigationsSuccessType = {
  project_delays: DelaysAndMitigations[],
};

export type DelaysAndMitigationsAction = {|
  type: typeof ActionTypes.GET_DELAYS_AND_MITIGATIONS_SUCCESS,
  data: DelaysAndMitigationsSuccessType,
|} | {|
  type: typeof ActionTypes.GET_DELAYS_AND_MITIGATIONS_FAIL,
|};

function* getDelaysAndMitigations(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DelaysAndMitigationsSuccessType } = yield call(getDelaysAndMitigationsApi, action.data.projectId);
    const actionData: DelaysAndMitigationsAction = { type: ActionTypes.GET_DELAYS_AND_MITIGATIONS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: DelaysAndMitigationsAction = { type: ActionTypes.GET_DELAYS_AND_MITIGATIONS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DELAYS_AND_MITIGATIONS, getDelaysAndMitigations);
}
