// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getEndDateProfileApi from './apis';

export type EndDateProfile = {
  as_planned: number,
  delayed: number,
  earlier: number,
  end_date: string,
  project_id: number,
  start_date: string,
};

export type EndDateProfileSuccessType = {
  profiles: EndDateProfile,
};

export type ActivityProfileAction = {|
  type: typeof ActionTypes.GET_WEEKLY_END_DATE_PROFILE_SUCCESS,
  data: EndDateProfileSuccessType,
|} | {|
  type: typeof ActionTypes.GET_WEEKLY_END_DATE_PROFILE_FAIL,
|};

function* getEndDateProfile(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: EndDateProfileSuccessType } = yield call(getEndDateProfileApi, action.data.projectId);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_WEEKLY_END_DATE_PROFILE_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_WEEKLY_END_DATE_PROFILE_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_END_DATE_PROFILE, getEndDateProfile);
}
