// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import { getDurationProfile as getDurationProfileApi, getDurationProfileTask as getDurationProfileTaskApi } from './apis';

export type DurationProfile = {
  as_planned: number,
  extended: number,
  shortened: number,
};

export type DurationProfileSuccessType = {
  activities: DurationProfile,
};

export type DurationProfileTaskSuccessType = {
  tasks: DurationProfile,
};

export type ActivityProfileAction = {|
  type: typeof ActionTypes.GET_DURATION_PROFILE_SUCCESS,
  data: DurationProfileSuccessType,
|} | {|
  type: typeof ActionTypes.GET_DURATION_PROFILE_FAIL,
|} | {|
  type: typeof ActionTypes.GET_DURATION_PROFILE_TASK_SUCCESS,
  data: DurationProfileTaskSuccessType,
|} | {|
  type: typeof ActionTypes.GET_DURATION_PROFILE_TASK_FAIL,
|};

function* getDurationProfile(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DurationProfileSuccessType } = yield call(getDurationProfileApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DURATION_PROFILE_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DURATION_PROFILE_FAIL };
    yield put(actionData);
  }
}

function* getDurationProfileTask(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DurationProfileTaskSuccessType } = yield call(getDurationProfileTaskApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DURATION_PROFILE_TASK_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DURATION_PROFILE_TASK_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DURATION_PROFILE, getDurationProfile);
  yield takeLatest(ActionTypes.GET_DURATION_PROFILE_TASK, getDurationProfileTask);
}
