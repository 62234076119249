// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopDelayReasonsApi from './apis';

export type TopDelayResponseType = {
  delay: string,
  name: string,
  project_delay: number,
  reason: string[],
  description: string[],
  id: number,
  deleted: boolean,
}

export type TopDelayReasonsSuccessType = {
  critical_activities: Array<TopDelayResponseType>
};

export type TopDelayReasonsAction = {|
  type: typeof ActionTypes.GET_TOP_DELAY_REASONS_SUCCESS,
  data: Array<TopDelayReasonsSuccessType>,
|} | {|
  type: typeof ActionTypes.GET_TOP_DELAY_REASONS_FAIL,
|};


function* getRateOfProgress(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: Array<TopDelayReasonsSuccessType> } = yield call(getTopDelayReasonsApi, action.data.projectId, action.data.startDate);
    const actionData: TopDelayReasonsAction = { type: ActionTypes.GET_TOP_DELAY_REASONS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopDelayReasonsAction = { type: ActionTypes.GET_TOP_DELAY_REASONS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_DELAY_REASONS, getRateOfProgress);
}
