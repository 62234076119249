// @flow

export type ConstraintDelay = {
  GET_CONSTRAINT_DELAY: 'getConstraintDelay',
  GET_CONSTRAINT_DELAY_SUCCESS: 'getConstraintDelaySuccess',
  GET_CONSTRAINT_DELAY_FAIL: 'getConstraintDelayFail',
}

const actionTypes: ConstraintDelay = {
  GET_CONSTRAINT_DELAY: 'getConstraintDelay',
  GET_CONSTRAINT_DELAY_SUCCESS: 'getConstraintDelaySuccess',
  GET_CONSTRAINT_DELAY_FAIL: 'getConstraintDelayFail',
};

export default actionTypes;
