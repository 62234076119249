// @flow

export type DelayReasonsActionTypes = {
  GET_ROOT_CAUSE_OF_DELAY_REASONS: 'getRootCauseOfDelayReasons',
  GET_ROOT_CAUSE_OF_DELAY_REASONS_SUCCESS: 'getRootCauseOfDelayReasonsSuccess',
  GET_ROOT_CAUSE_OF_DELAY_REASONS_FAIL: 'getRootCauseOfDelayReasonsFail',
}

const actionTypes: DelayReasonsActionTypes = {
  GET_ROOT_CAUSE_OF_DELAY_REASONS: 'getRootCauseOfDelayReasons',
  GET_ROOT_CAUSE_OF_DELAY_REASONS_SUCCESS: 'getRootCauseOfDelayReasonsSuccess',
  GET_ROOT_CAUSE_OF_DELAY_REASONS_FAIL: 'getRootCauseOfDelayReasonsFail',
};

export default actionTypes;
