// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import { getEndDateProfile as getEndDateProfileApi, getEndDateProfileTask as getEndDateProfileTaskApi } from './apis';

export type EndDateProfile = {
  as_planned: number,
  delayed: number,
  earlier: number,
};

export type EndDateProfileSuccessType = {
  activities: EndDateProfile,
};

export type EndDateProfileTaskSuccessType = {
  tasks: EndDateProfile,
};

export type ActivityProfileAction = {|
  type: typeof ActionTypes.GET_END_DATE_PROFILE_SUCCESS,
  data: EndDateProfileSuccessType,
|} | {|
  type: typeof ActionTypes.GET_END_DATE_PROFILE_FAIL,
|} | {|
  type: typeof ActionTypes.GET_END_DATE_PROFILE_TASK_SUCCESS,
  data: EndDateProfileTaskSuccessType,
|} | {|
  type: typeof ActionTypes.GET_END_DATE_PROFILE_TASK_FAIL,
|};

function* getEndDateProfile(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: EndDateProfileSuccessType } = yield call(getEndDateProfileApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_END_DATE_PROFILE_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_END_DATE_PROFILE_FAIL };
    yield put(actionData);
  }
}

function* getEndDateProfileTask(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: EndDateProfileTaskSuccessType } = yield call(getEndDateProfileTaskApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_END_DATE_PROFILE_TASK_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_END_DATE_PROFILE_TASK_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_END_DATE_PROFILE, getEndDateProfile);
  yield takeLatest(ActionTypes.GET_END_DATE_PROFILE_TASK, getEndDateProfileTask);
}
