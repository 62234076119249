// @flow
import ActionTypes from './action-types';
import type { ConstraintDelayAction, ConstraintDelay } from './sagas';
import DelayDetailActions from '../../delay-detail/action-types';
import type { DelayDetailAction } from '../../delay-detail/sagas';

export type ConstraintDelayState = {
  chartData: ConstraintDelay[],
  loading: boolean | null,
};

const initialState: ConstraintDelayState = { loading: null, chartData: [] };

export default function (state: ConstraintDelayState = initialState, action: ConstraintDelayAction | DelayDetailAction): ConstraintDelayState {
  switch (action.type) {
    case ActionTypes.GET_CONSTRAINT_DELAY_SUCCESS:
      return {
        ...state,
        chartData: action.data.delays,
        loading: false,
      };
    case DelayDetailActions.UPDATE_DELAY_HISTORY_SUCCESS:
      // $FlowFixMe
      const delayItemIndex: number = state.chartData.findIndex((d: WorkDelay): boolean => d.id === action.data.delay.id);
      if (delayItemIndex === -1) {
        return { ...state };
      }
      const chartData: ConstraintDelay[] = [...state.chartData];
      chartData[delayItemIndex] = { ...chartData[delayItemIndex], ...action.data.delay };
      return {
        ...state,
        chartData: [...chartData],
        loading: false,
      };
    default:
      return state;
  }
}
