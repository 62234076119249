// @flow

import * as React from 'react';
import Anchor from '../components/anchor';
import colors from '../styles/colors';

const styles: Object = {
  header: {
    backgroundColor: '#2E3E46',
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    height: '3rem',
    alignItems: 'center',
    padding: '0 2rem',
    userSelect: 'none',
    position: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 10,
  },
  titleLean: {
    fontFamily: 'plando-font',
    color: colors.themePrimaryColor,
    fontSize: '1.8rem',
  },
  titlePlando: {
    fontFamily: 'plando-font',
    color: '#fff',
    fontSize: '1.8rem',
  },
  appName: {
    fontSize: '1rem',
    color: '#B8BDC1',
  },
  linkToPlando: {
    display: 'flex',
    alignItems: 'center',
  },
  appLink: {
    textDecoration: 'none',
  },
};

const Header: Function = function Header(): React.Node {
  return (
    <header style={styles.header}>
      <Anchor style={styles.appLink}>
        <span style={styles.titleLean}>LEAN</span>
        <span>&nbsp;&nbsp;</span>
        <span style={styles.titlePlando}>PLANDO</span>
        <span style={styles.appName}>Insights</span>
      </Anchor>
      <div style={styles.linkToPlando}>
        <Anchor
          href={window.location.hostname === 'insights.learn.leanplando.com' ? 'https://learn.leanplando.com' : 'https://leanplando.com'}
          alt="Goto Plando"
        >
          Go to Lean Plando
        </Anchor>
      </div>
    </header>
  );
};

export default Header;
