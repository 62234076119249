// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import { getDateProfile as getDateProfileApi, getDateProfileTask as getDateProfileTaskApi } from './apis';

export type DateProfile = {
  as_planned: number,
  delayed: number,
  earlier: number,
};

export type DateProfileSuccessType = {
  activities: DateProfile,
};

export type DateProfileTaskSuccessType = {
  tasks: DateProfile,
};

export type ActivityProfileAction = {|
  type: typeof ActionTypes.GET_DATE_PROFILE_SUCCESS,
  data: DateProfileSuccessType,
|} | {|
  type: typeof ActionTypes.GET_DATE_PROFILE_FAIL,
|} | {|
  type: typeof ActionTypes.GET_DATE_PROFILE_TASK_SUCCESS,
  data: DateProfileTaskSuccessType,
|} | {|
  type: typeof ActionTypes.GET_DATE_PROFILE_TASK_FAIL,
|};

function* getDateProfile(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DateProfileSuccessType } = yield call(getDateProfileApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DATE_PROFILE_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DATE_PROFILE_FAIL };
    yield put(actionData);
  }
}

function* getDateProfileTask(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DateProfileTaskSuccessType } = yield call(getDateProfileTaskApi, action.data.projectId, action.data.startDate);
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DATE_PROFILE_TASK_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ActivityProfileAction = { type: ActionTypes.GET_DATE_PROFILE_TASK_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DATE_PROFILE, getDateProfile);
  yield takeLatest(ActionTypes.GET_DATE_PROFILE_TASK, getDateProfileTask);
}
