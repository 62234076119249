// @flow

import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';

import delayReasonsSagas from './widgets/delay-reasons/sagas';
import delayResponsibilitySagas from './widgets/delay-responsibility/sagas';
import reasonForDelaysSagas from './widgets/reason-for-delays/sagas';
import projectWeeklyProgressSagas from './widgets/project-weekly-progress/sagas';
import projectWeeklyCostProgressSagas from './widgets/project-weekly-cost-progress/sagas';
import topMostDelayedActivitiesSagas from './widgets/top-most-delayed-activities/sagas';
import topMostDelayedTasksSagas from './widgets/top-most-delayed-tasks/sagas';
import topExtendedActivitiesSagas from './widgets/top-extended-activities/sagas';
import topExtendedTasksSagas from './widgets/top-extended-tasks/sagas';
import topDelayedConstraintsSagas from './widgets/top-delayed-constraints/sagas';
import topEarlyFinishedActivitiesSagas from './widgets/top-early-finished-activities/sagas';
import topEarlyFinishedTasksSagas from './widgets/top-early-finished-tasks/sagas';
import topShortenedActivitiesSagas from './widgets/top-shortened-activities/sagas';
import topShortenedTasksSagas from './widgets/top-shortened-tasks/sagas';
import activityDateProfileSagas from './widgets/activity-profile-date/sagas';
import activityEndDateProfileSagas from './widgets/activity-profile-enddate/sagas';
import activityDurationProfileSagas from './widgets/activity-profile-duration/sagas';
import weeklyActivityDateProfileSagas from './widgets/weekly-activity-profile-date/sagas';
import weeklyActivityDurationProfileSagas from './widgets/weekly-activity-profile-duration/sagas';
import weeklyActivityEndDateProfileSagas from './widgets/weekly-activity-profile-end-date/sagas';
import delayMitigationEffortSagas from './widgets/delay-mitigation-effort/sagas';
import topCriticalPathDelayedActivitiesSaga from './widgets/top-critical-path-delayed-activities/sagas';
import projectStatusSaga from './widgets/project-status/sagas';
import projectStatusWeeklySaga from './widgets/project-status-weekly/sagas';
import rateOfProgressWeeklySaga from './widgets/rate-progress-weekly/sagas';
import projectSummaryWeeklySaga from './widgets/project-summary-weekly/sagas';
import cprWeeklySaga from './widgets/cpr-weekly/sagas';
import topDelayReasonsWeeklySaga from './widgets/top-critical-delay-reasons-weekly/sagas';
import projectProjectionSaga from './widgets/project-projection/sagas';
import weeklyPPCSaga from './widgets/weekly-ppc/sagas';
import weeklyDelayMitigationSagas from './widgets/weekly-delay-mitigation/sagas';
import weeklyProductivityImprovementSagas from './widgets/weekly-productivity-improvement/sagas';
import weeklyWorkableBacklogSagas from './widgets/weekly-workablebacklog/sagas';
import workableBacklogSagas from './widgets/workable-backlog-weekly/sagas';
import workDelaySagas from './widgets/work-delay-widget-weekly/sagas';
import constraintDelaySagas from './widgets/constraint-delay-widget-weekly/sagas';
import weeklyCommitmentReliabilitySagas from './widgets/weekly-commitment-reliability/sagas';
import commimentReliabilityWeeklySagas from './widgets/commitment-reliability-weekly/sagas';
import delaysAndMitigationsSagas from './widgets/delays-and-mitigations/sagas';
import activityDetailSaga from './activity-detail/sagas';
import taskDetailSaga from './task-detail/sagas';
import delayDetailSaga from './delay-detail/sagas';
import projectSaga from './project/sagas';
import othersDelaysSaga from './others-delays/sagas';

import reducers from './reducers';

const sagaMiddleware: Object = createSagaMiddleware();
const store: Object = createStore(reducers, undefined, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(delayReasonsSagas);
sagaMiddleware.run(delayResponsibilitySagas);
sagaMiddleware.run(reasonForDelaysSagas);
sagaMiddleware.run(projectWeeklyProgressSagas);
sagaMiddleware.run(projectWeeklyCostProgressSagas);
sagaMiddleware.run(topMostDelayedActivitiesSagas);
sagaMiddleware.run(topMostDelayedTasksSagas);
sagaMiddleware.run(topExtendedActivitiesSagas);
sagaMiddleware.run(topExtendedTasksSagas);
sagaMiddleware.run(topDelayedConstraintsSagas);
sagaMiddleware.run(topEarlyFinishedActivitiesSagas);
sagaMiddleware.run(topEarlyFinishedTasksSagas);
sagaMiddleware.run(topShortenedActivitiesSagas);
sagaMiddleware.run(topShortenedTasksSagas);
sagaMiddleware.run(activityDateProfileSagas);
sagaMiddleware.run(activityEndDateProfileSagas);
sagaMiddleware.run(activityDurationProfileSagas);
sagaMiddleware.run(weeklyActivityDateProfileSagas);
sagaMiddleware.run(weeklyActivityDurationProfileSagas);
sagaMiddleware.run(weeklyActivityEndDateProfileSagas);
sagaMiddleware.run(delayMitigationEffortSagas);
sagaMiddleware.run(topCriticalPathDelayedActivitiesSaga);
sagaMiddleware.run(projectStatusSaga);
sagaMiddleware.run(projectStatusWeeklySaga);
sagaMiddleware.run(rateOfProgressWeeklySaga);
sagaMiddleware.run(projectSummaryWeeklySaga);
sagaMiddleware.run(cprWeeklySaga);
sagaMiddleware.run(topDelayReasonsWeeklySaga);
sagaMiddleware.run(weeklyPPCSaga);
sagaMiddleware.run(weeklyDelayMitigationSagas);
sagaMiddleware.run(weeklyProductivityImprovementSagas);
sagaMiddleware.run(weeklyWorkableBacklogSagas);
sagaMiddleware.run(workableBacklogSagas);
sagaMiddleware.run(workDelaySagas);
sagaMiddleware.run(constraintDelaySagas);
sagaMiddleware.run(weeklyCommitmentReliabilitySagas);
sagaMiddleware.run(commimentReliabilityWeeklySagas);
sagaMiddleware.run(delaysAndMitigationsSagas);
sagaMiddleware.run(activityDetailSaga);
sagaMiddleware.run(taskDetailSaga);
sagaMiddleware.run(delayDetailSaga);
sagaMiddleware.run(projectProjectionSaga);
sagaMiddleware.run(projectSaga);
sagaMiddleware.run(othersDelaysSaga);

export default store;
