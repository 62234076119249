// @flow

export type WeeklyActionTypes = {
  UPDATE_WEEKLY_WIDGETS_LIST: 'updateWeeklyWidgetsList',
}

const actionTypes: WeeklyActionTypes = {
  UPDATE_WEEKLY_WIDGETS_LIST: 'updateWeeklyWidgetsList',
};

export default actionTypes;
