// @flow

export type ActivityDetailPropTypes = {
  GET_ACTIVITY_DETAIL: 'getActivityDetail',
  GET_ACTIVITY_DETAIL_SUCCESS: 'getActivityDetailSuccess',
  GET_ACTIVITY_DETAIL_FAIL: 'getActivityDetailFail',
  CLEAR_ACTIVITY_DETAIL: 'clearActivityDetail',
}

const actionTypes: ActivityDetailPropTypes = {
  GET_ACTIVITY_DETAIL: 'getActivityDetail',
  GET_ACTIVITY_DETAIL_SUCCESS: 'getActivityDetailSuccess',
  GET_ACTIVITY_DETAIL_FAIL: 'getActivityDetailFail',
  CLEAR_ACTIVITY_DETAIL: 'clearActivityDetail',
};

export default actionTypes;
