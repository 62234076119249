// @flow
import ActionTypes from './action-types';
import type { WeeklyAction } from './sagas';

export type widget = { name: string };
export type weeklyState = {
  widgets: Array<widget>,
  loading: boolean | null,
};


const initialState: weeklyState = {
  loading: null,
  widgets: [
    { name: 'projectSummaryWeekly' },
    { name: 'weekHighlightsWeekly' },
    { name: 'cprWeekly' },
    { name: 'planReliabilityActivityWeekly' },
    { name: 'planReliabilityTaskWeekly' },
    { name: 'reasonForDelays' },
    { name: 'workDelay' },
    { name: 'constraintDelay' },

    { name: 'topEarlyFinishedActivities' },
    { name: 'topEarlyFinishedTasks' },
    { name: 'topMostDelayedActivities' },
    { name: 'topMostDelayedTasks' },
    { name: 'topExtendedActivities' },
    { name: 'topExtendedTasks' },
    { name: 'topShortenedActivities' },
    { name: 'topShortenedTasks' },
    { name: 'topDelayedConstraints' },
  ],
};

export default function (state: weeklyState = initialState, action: WeeklyAction): weeklyState {
  switch (action.type) {
    case ActionTypes.UPDATE_WEEKLY_WIDGETS_LIST:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
