// @flow

export type ModalActionTypes = {
  SHOW_MODAL: 'showModal',
  HIDE_MODAL: 'hideModal',
}

const actionTypes: ModalActionTypes = {
  SHOW_MODAL: 'showModal',
  HIDE_MODAL: 'hideModal',
};

export default actionTypes;
