// @flow
import ActionTypes from './action-types';
import type { TopShortenedTasksAction, ShortenedTask } from './sagas';

export type TopShortenedTasksState = {
  chartData: ShortenedTask[],
  loading: boolean | null,
};

const initialState: TopShortenedTasksState = { loading: null, chartData: [] };

export default function (state: TopShortenedTasksState = initialState, action: TopShortenedTasksAction): TopShortenedTasksState {
  switch (action.type) {
    case ActionTypes.GET_TOP_SHORTENED_TASKS_SUCCESS:
      return {
        ...state,
        chartData: action.data.tasks,
        loading: false,
      };
    default:
      return state;
  }
}
