// @flow

export type TopExtendedActivities = {
  GET_TOP_EXTENDED_ACTIVITIES: 'getTopExtendedActivities',
  GET_TOP_EXTENDED_ACTIVITIES_SUCCESS: 'getTopExtendedActivitiesSuccess',
  GET_TOP_EXTENDED_ACTIVITIES_FAIL: 'getTopExtendedActivitiesFail',
}

const actionTypes: TopExtendedActivities = {
  GET_TOP_EXTENDED_ACTIVITIES: 'getTopExtendedActivities',
  GET_TOP_EXTENDED_ACTIVITIES_SUCCESS: 'getTopExtendedActivitiesSuccess',
  GET_TOP_EXTENDED_ACTIVITIES_FAIL: 'getTopExtendedActivitiesFail',
};

export default actionTypes;
