// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getOthersDelaysApi from './apis';

// export type OtherDelay = {
//   othersDelays: any,
//   isLoading: boolean
// }

export type OthersDelaysResponseType = {
  othersDelays: any,
};

export type OthersDelaysAction =
  | {|
      +type: $PropertyType<typeof ActionTypes, 'GET_OTHERS_DELAYS_SUCCESS'>,
      data: OthersDelaysResponseType,
    |}
  | {|
      +type: $PropertyType<typeof ActionTypes, 'GET_OTHERS_DELAYS_FAIL'>,
    |}
  | {|
      +type: $PropertyType<typeof ActionTypes, 'CLEAR_OTHERS_DELAYS'>,
    |};

function* getOthersDelays(action: { data: { projectId: string, week: string } }): Generator<IOEffect, void, any> {
  // eslint-disable-line
  try {
    const response: { data: OthersDelaysResponseType } = yield call(getOthersDelaysApi, action.data.projectId, action.data.week);
    yield put({ type: ActionTypes.GET_OTHERS_DELAYS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_OTHERS_DELAYS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_OTHERS_DELAYS, getOthersDelays);
}
