// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getWeeklyProductivityImprovementApi from './apis';

export type ProductivityImprovement = {
  productivity_improvement: number,
  duration_shortened: number,
  end_date: string,
  start_date: string,
  total_base_duration: number,
};

export type ProductivityImprovementSuccessType = {
  productivity_improvement: ProductivityImprovement,
};

export type ProductivityImprovementAction = {|
  type: typeof ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_SUCCESS,
  data: ProductivityImprovementSuccessType,
|} | {|
  type: typeof ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_FAIL,
|};

function* getWeeklyProductivityImprovement(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ProductivityImprovementSuccessType } = yield call(getWeeklyProductivityImprovementApi, action.data.projectId);
    const actionData: ProductivityImprovementAction = { type: ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ProductivityImprovementAction = { type: ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT, getWeeklyProductivityImprovement);
}
