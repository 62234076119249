// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { ActivityProfileAction, EndDateProfile } from './sagas';

export type WeeklyActivityProfileEndDateItem = {
  asPlanned: number,
  delayed: number,
  earlier: number,
  endDate: string,
  startDate: string,
};


export type WeeklyActivityProfileEndDateState = {
  weeklyEndDateProfile: WeeklyActivityProfileEndDateItem[],
  weeklyEndDateProfileLoading: boolean | null,
};

const initialState: WeeklyActivityProfileEndDateState = {
  weeklyEndDateProfileLoading: null,
  weeklyEndDateProfile: [],
};

const formatData: Function = function formatData(data: EndDateProfile): WeeklyActivityProfileEndDateItem[] {
  return map((item: EndDateProfile): WeeklyActivityProfileEndDateItem => (
    {
      asPlanned: item.as_planned,
      delayed: item.delayed,
      earlier: item.earlier,
      endDate: item.end_date,
      startDate: item.start_date,
    }
  ), data);
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyActivityProfileEndDateState = initialState, action: ActivityProfileAction): Exact<WeeklyActivityProfileEndDateState> {
  let dp: WeeklyActivityProfileEndDateItem[];

  switch (action.type) {
    case ActionTypes.GET_WEEKLY_END_DATE_PROFILE_SUCCESS:
      dp = formatData(action.data.profiles);
      return {
        ...state,
        weeklyEndDateProfile: dp,
        weeklyEndDateProfileLoading: false,
      };
    default:
      return state;
  }
}
