// @flow

import * as React from 'react';

type LegendItem = {
    label: string,
    color: string,
}
type Props = {
  legend: Array<Object>,
};

const styles: Object = {
  legendItem: {
    fontSize: '0.85rem',
    marginBottom: '0.30rem',
  },
  colorBox: {
    height: '0.85rem',
    width: '0.85rem',
    display: 'inline-block',
    marginRight: '0.5rem',
  },
};

const getItems: Function = function getItems(legends: LegendItem[]): React.Node {
  return legends.map((item: LegendItem): React.Node => (
    <div key={item.label} style={styles.legendItem}>
      <div style={{ ...styles.colorBox, backgroundColor: item.color }} />
      <span>{item.label}</span>
    </div>
  ));
};

export default function Legend(props: Props): React.Node {
  return (
    <div>
      {getItems(props.legend)}
    </div>
  );
}
