// @flow

export type TopExtendedActivities = {
  GET_TOP_DELAYED_CONSTRAINTS: 'getTopDelayedConstraints',
  GET_TOP_DELAYED_CONSTRAINTS_SUCCESS: 'getTopDelayedConstraintsSuccess',
  GET_TOP_DELAYED_CONSTRAINTS_FAIL: 'getTopDelayedConstraintsFail',
}

const actionTypes: TopExtendedActivities = {
  GET_TOP_DELAYED_CONSTRAINTS: 'getTopDelayedConstraints',
  GET_TOP_DELAYED_CONSTRAINTS_SUCCESS: 'getTopDelayedConstraintsSuccess',
  GET_TOP_DELAYED_CONSTRAINTS_FAIL: 'getTopDelayedConstraintsFail',
};

export default actionTypes;
