// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getWeeklyDelayMitigationApi from './apis';

export type DelayMitigation = {
  delay_mitigation: number,
  duration_extended: number,
  duration_shortened: number,
  end_date: string,
  start_date: string,
};

export type DelayMitigationSuccessType = {
  delay_mitigation: DelayMitigation,
};

export type DelayMitigationAction = {|
  type: typeof ActionTypes.GET_WEEKLY_DELAY_MITIGATION_SUCCESS,
  data: DelayMitigationSuccessType,
|} | {|
  type: typeof ActionTypes.GET_WEEKLY_DELAY_MITIGATION_FAIL,
|};

function* getWeeklyDelayMitigation(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DelayMitigationSuccessType } = yield call(getWeeklyDelayMitigationApi, action.data.projectId);
    const actionData: DelayMitigationAction = { type: ActionTypes.GET_WEEKLY_DELAY_MITIGATION_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: DelayMitigationAction = { type: ActionTypes.GET_WEEKLY_DELAY_MITIGATION_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_DELAY_MITIGATION, getWeeklyDelayMitigation);
}
