// @flow

import * as React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import type { Project } from '../auth/with-project-context';
import WeekList from './week-list';
import './styles.css';

const styles: Object = {
  container: {
    position: 'relative',
    zIndex: 1,
  },
  activeTab: {
    backgroundColor: '#ff6500',
  },
  activeFont: {
    color: '#ffffff',
  },
  activeArrow: {
    borderTop: '6px solid #ffffff',
  },
};

type Props = {
  project: Project,
  weekDate: string,
  weekStart: string,
  allWeeks: [],
};

type State = {
  showMenu: boolean,
};

class Header extends React.Component<Props, State> {
  toggleMenu = this.toggleMenu.bind(this);

  closeMenu = this.closeMenu.bind(this);

  constructor(props: Object) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  getCurrentLastWeek(activeTab: string, props: Props): React.Node {
    if (props.allWeeks !== null && props.allWeeks.length >= 2) {
      return (
        <div style={{ display: 'flex' }}>
          <Link
            to={`/projects/${this.props.project.id || ''}/weekly/current`}
            className="link-weekly"
          >
            <span
              className="button-weekly tab-container-weekly"
              style={activeTab === 'current' ? styles.activeTab : null}
              role="button"
              tabIndex="0"
              onClick={this.closeMenu}
              onKeyDown={() => {}}
            >
              <span
                className="tab-text-weekly"
                style={activeTab === 'current' ? styles.activeFont : null}
              >
                This Week
              </span>
            </span>
          </Link>
          <Link
            to={`/projects/${this.props.project.id || ''}/weekly/last`}
            className="link-weekly"
          >
            <span
              className="button-weekly tab-container-weekly"
              style={activeTab === 'last' ? styles.activeTab : null}
              role="button"
              tabIndex="-1"
              onClick={this.closeMenu}
              onKeyDown={() => {}}
            >
              <span
                className="tab-text-weekly"
                style={activeTab === 'last' ? styles.activeFont : null}
              >
                Last Week
              </span>
            </span>
          </Link>
        </div>
      );
    }
    return null;
  }

  getPastWeek(activeTab: string, props: Props): React.Node {
    if (props.allWeeks !== null && props.allWeeks.length >= 3) {
      return (
        <span
          className="button-weekly tab-container-weekly past-week-button"
          style={
            activeTab !== 'current' && activeTab !== 'last'
              ? styles.activeTab
              : null
          }
          role="button"
          tabIndex="-2"
          onClick={this.toggleMenu}
          onKeyDown={() => {}}
        >
          <div
            className="tab-text-weekly past-week no-select"
            style={
              activeTab !== 'current' && activeTab !== 'last'
                ? styles.activeFont
                : null
            }
          >
            Past Weeks
            <span
              className="arrow-down-weekly"
              style={
                activeTab !== 'current' && activeTab !== 'last'
                  ? styles.activeArrow
                  : null
              }
            />
          </div>
        </span>
      );
    }
    return null;
  }

  toggleMenu() {
    this.setState((prevState: State): State => ({
      showMenu: !prevState.showMenu,
    }));
  }

  closeMenu() {
    this.setState({
      showMenu: false,
    });
  }

  render(): React.Node {
    const activeTab: string = this.props.weekStart;
    const weekDates: ?Object = this.props.allWeeks.find(
      (weekObj: Object): boolean => weekObj.start === this.props.weekDate,
    );
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 200}] */
    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <div className="tabs-weekly">
            {this.getCurrentLastWeek(activeTab, this.props)}
            {this.getPastWeek(activeTab, this.props)}
          </div>
        </div>
        {this.state.showMenu ? (
          <WeekList
            projectId={this.props.project.id || ''}
            weekStart={this.props.weekStart}
            toggleMenu={this.toggleMenu}
            allWeeks={this.props.allWeeks}
          />
        ) : null}
        <div className="current-week-label">
          Viewing insights from
          <span className="dates-week-label">
            {weekDates && moment(weekDates.start).format('DD MMM YY')}
          </span>
          to
          <span className="dates-week-label">
            {weekDates && moment(weekDates.end).format('DD MMM YY')}
          </span>
        </div>
      </div>
    );
  }
}

export default Header;
