// @flow

import axios from 'axios';
import config from '../../config/config.json';

export function getDateProfile(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/insights/date_profile${startDate ? `?start_date=${startDate}` : ''}`);
}

export function getDateProfileTask(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/insights/task_date_profile?start_date=${startDate}`);
}
