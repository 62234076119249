// @flow

export type WeeklyActivityWorkableBacklogTypes = {
  GET_WEEKLY_WORKABLE_BACKLOG: 'getWeeklyWorkableBacklog',
  GET_WEEKLY_WORKABLE_BACKLOG_SUCCESS: 'getWeeklyWorkableBacklogSuccess',
  GET_WEEKLY_WORKABLE_BACKLOG_FAIL: 'getWeeklyWorkableBacklogFail',
}

const actionTypes: WeeklyActivityWorkableBacklogTypes = {
  GET_WEEKLY_WORKABLE_BACKLOG: 'getWeeklyWorkableBacklog',
  GET_WEEKLY_WORKABLE_BACKLOG_SUCCESS: 'getWeeklyWorkableBacklogSuccess',
  GET_WEEKLY_WORKABLE_BACKLOG_FAIL: 'getWeeklyWorkableBacklogFail',
};

export default actionTypes;
