// @flow

import axios from 'axios';
import config from '../config/config.json';

export default function getActivityDetail(projectId: string, activityId: string, delayType: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/insights/activities/${activityId}`, {
    params: { delay_type: delayType },
  });
}
