// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getRootCauseOfDelayReasonsApi from './apis';

type DelayReasonsResponseSuccessType = {
  root_cause: {
    [key: string]: {
      value: number,
      project_delay: {
        [key: string]: number,
      },
      occurrence: number,
    },
  }
};

export type DelayReasonAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_ROOT_CAUSE_OF_DELAY_REASONS_SUCCESS'>,
  data: DelayReasonsResponseSuccessType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_ROOT_CAUSE_OF_DELAY_REASONS_FAIL'>,
|};

function* getRootCauseOfDelayReasons(action: { data: { projectId: string, filter: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DelayReasonsResponseSuccessType } = yield call(getRootCauseOfDelayReasonsApi, action.data.projectId, action.data.filter);
    const actionData: DelayReasonAction = { type: ActionTypes.GET_ROOT_CAUSE_OF_DELAY_REASONS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: DelayReasonAction = { type: ActionTypes.GET_ROOT_CAUSE_OF_DELAY_REASONS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_ROOT_CAUSE_OF_DELAY_REASONS, getRootCauseOfDelayReasons);
}
