// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getWeeklyPPCApi from './apis';

export type PPC = {
  total_activities: number,
  end_date: string,
  percentage_complete: number,
  start_date: string,
  committable_tasks: number,
}

export type WeeklyPPCResponse = {
  committed_tasks_median: number,
  ppc_median: number,
  committed_tasks_min: number,
  ppc_min: number,
  committed_tasks_max: number,
  ppc_max: number,
  ppc_report: PPC[],
}

export type WeeklyPPCAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WEEKLY_PPC_SUCCESS'>,
  data: WeeklyPPCResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_WEEKLY_PPC_FAIL'>,
|};

function* getWeeklyPPC(action: { data: { projectId: string, companyId: ?string } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: WeeklyPPCResponse } = yield call(getWeeklyPPCApi, action.data.projectId, action.data.companyId);
    yield put({ type: ActionTypes.GET_WEEKLY_PPC_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_WEEKLY_PPC_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_WEEKLY_PPC, getWeeklyPPC);
}
