// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopEarlyFinishedTasksApi from './apis';

export type EarlyFinishedTask = {
  actual_end_date: string,
  base_end_date: string,
  end_date_difference: number,
  name: string,
  activity: string,
};

type TopEarlyFinishedTasksResponse = {
  tasks: Array<EarlyFinishedTask>,
};

export type TopEarlyFinishedTasksAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EARLY_FINISHED_TASKS_SUCCESS'>,
  data: TopEarlyFinishedTasksResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_EARLY_FINISHED_TASKS_FAIL'>,
|};

function* getTopEarlyFinishedTasks(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopEarlyFinishedTasksResponse } = yield call(getTopEarlyFinishedTasksApi, action.data.projectId, action.data.startDate);
    const actionData: TopEarlyFinishedTasksAction = { type: ActionTypes.GET_TOP_EARLY_FINISHED_TASKS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopEarlyFinishedTasksAction = { type: ActionTypes.GET_TOP_EARLY_FINISHED_TASKS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_EARLY_FINISHED_TASKS, getTopEarlyFinishedTasks);
}
