// @flow

type ProjectProjectionActionTypes = {
  GET_PROJECT_PROJECTION: 'getProjectProjection',
  GET_PROJECT_PROJECTION_SUCCESS: 'getProjectProjectionSuccess',
  GET_PROJECT_PROJECTION_FAIL: 'getProjectProjectionFail',
}

const actionTypes: ProjectProjectionActionTypes = {
  GET_PROJECT_PROJECTION: 'getProjectProjection',
  GET_PROJECT_PROJECTION_SUCCESS: 'getProjectProjectionSuccess',
  GET_PROJECT_PROJECTION_FAIL: 'getProjectProjectionFail',
};

export default actionTypes;
