// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'ramda';
import ReactTooltip from 'react-tooltip';

import colors from '../../styles/colors';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import ExportButton from '../../components/export';

import type { ProjectSummaryWeeklyState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import './styles.css';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProjectSummaryWeeklyState,
  project: Project,
  week: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  statusField: {
    borderColor: colors.themeSecondaryColor,
  },
  circleColorOntime: {
    border: '4px solid #3777b0',
  },
  circleColorDelayed: {
    border: '4px solid #ff6500',
  },
  circleColorAhead: {
    border: '4px solid #f8e71c',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '8px',
  },
};

function truncatePercent(value: number): string {
  let percentage: any = `${value}`;

  percentage = percentage.split('.');
  if (percentage.length > 1) {
    // eslint-disable-next-line prefer-template
    percentage = percentage[0] + '.' + percentage[1].substr(0, 1);
  } else {
    percentage = percentage[0];
  }

  return percentage;
}

class ProjectSummaryWeeklyWidget extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_PROJECT_SUMMARY_WEEKLY,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    const data: Object = this.props.data.data;
    const actualProgress: number = data.actual_progress;
    const plannedProgress: number = data.planned_progress;
    if (
      isEmpty(data)
      || !(
        data.actual_progress
        && data.delay_indicator !== null
        && data.expected_completion_date
        && data.overall_status
        && data.planned_completion_date
        && data.planned_progress
        && this.props.data.loading === false
      )
    ) {
      return <span style={styles.noData}>No data available</span>;
    }
    function getCircleColor(): Object {
      if (data.overall_status === 'Delayed') {
        return styles.circleColorDelayed;
      }
      if (data.overall_status === 'On Time') {
        return styles.circleColorOntime;
      }
      return styles.circleColorAhead;
    }
    return (
      <div>
        <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="project_summary" />
        <div className="center-text">
          <div className="center-text flex-container" style={{ justifyContent: 'space-between' }}>
            <div>
              <div className="box-size box-size-text center-text">Expected</div>
              <div className="dotted-horizontal-line dotted-horizontal-line-left" />
              <div className="box-size box-size-text center-text">Planned</div>
            </div>
            <div>
              <div className="half-circle-top box-size circle-common center-text">{moment(data.expected_completion_date).format('DD MMM YY')}</div>
              <div className="half-circle-bottom box-size circle-common center-text">{moment(data.planned_completion_date).format('DD MMM YY')}</div>
            </div>
            <div className="arrow-container">
              <div className="center-text arrow-common">
                <div className="type-container center-text">Completed</div>
                <div className="arrow-left" />
                <div className="horizontal-line-status" />
              </div>
            </div>
            <div className="full-circle-hollow center-text" style={getCircleColor()}>
              <div className="status-type">{data.overall_status}</div>
              <div className="status-common">{data.delay_indicator}</div>
              <div className="status-common" style={{ marginTop: '4px' }}>
                weeks
              </div>
            </div>
            <div className="arrow-container">
              <div className="center-text arrow-common">
                <div className="horizontal-line-status" />
                <div className="arrow-right" />
                <div className="type-container center-text">Progress</div>
              </div>
            </div>
            <div>
              <div className="half-circle-top box-size circle-common center-text">
                {actualProgress
                  ? `${truncatePercent(actualProgress)}%
                  ${this.props.offsetPercentage !== null ? '*' : ''}` : 'NA'}
              </div>
              <div className="half-circle-bottom box-size circle-common center-text">
                {plannedProgress
                  ? `${truncatePercent(plannedProgress)}% ${this.props.offsetPercentage !== null ? '*' : ''}` : 'NA'}
              </div>
            </div>
            <div>
              <div className="box-size box-size-text center-text">Actual</div>
              <div className="dotted-horizontal-line dotted-horizontal-line-right" />
              <div className="box-size box-size-text center-text">Planned</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(): React$Element<*> {
    const data: Object = this.props.data.data;
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          {isEmpty(data) ? 'Project Summary' : `Project Summary - ${data.project_name}`}
          {' '}
          <img
            data-tip="Displays the current status of the project with the comparison of expected project completion date vs the planned project completion date.<br />It also displays the current project progress vs the planned project progress"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderWidget()}
        <span style={styles.note}>*Data available from July 2018</span>
        {
          !isEmpty(data) && this.props.offsetPercentage
          && <div style={{ fontSize: '0.8rem', marginTop: 8 }}>
             * Actual and Planned progress values are recalculated based on already
              completed percentage of the project provided in the settings
             </div>
                }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ProjectSummaryWeeklyState } => ({
    data: state.projectSummaryWeekly,
    offsetPercentage: state.project.projectDetails.preferences.general.offset_percentage,
  }),
)(ProjectSummaryWeeklyWidget);

component.style = {
  width: '98%',
};

export default component;
