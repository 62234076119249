// @flow

export type WeeklyActivityProfileDateActionTypes = {
  GET_WEEKLY_DATE_PROFILE: 'getWeeklyDateProfile',
  GET_WEEKLY_DATE_PROFILE_SUCCESS: 'getWeeklyDateProfileSuccess',
  GET_WEEKLY_DATE_PROFILE_FAIL: 'getWeeklyDateProfileFail',
}

const actionTypes: WeeklyActivityProfileDateActionTypes = {
  GET_WEEKLY_DATE_PROFILE: 'getWeeklyDateProfile',
  GET_WEEKLY_DATE_PROFILE_SUCCESS: 'getWeeklyDateProfileSuccess',
  GET_WEEKLY_DATE_PROFILE_FAIL: 'getWeeklyDateProfileFail',
};

export default actionTypes;
