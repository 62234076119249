// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ResponsivePieChart from '../../components/charts/pie';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import ExportButton from '../../components/export';

import type { ProfileDurationState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProfileDurationState,
  project: Project,
  week: string,
  isTask: boolean,
}

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string,
}

const LabelStrings: Object = {
  as_planned: 'As Planned',
  extended: 'Extended',
  shortened: 'Shortened',
  delayed: 'Delayed',
  earlier: 'Earlier',
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ActivityProfileDuration extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  getDaysDeltaText(item: Object): string {
    if (item.id === 'shortened' || item.id === 'extended') {
      return `${item.totalDays} days`;
    }
    return '';
  }

  getFormattedData(data: Array<Object>): ViewModel[] {
    if (this.props.isTask === 'true') {
      return data.map((item: Object): ViewModel => ({
        label: LabelStrings[item.id],
        value: item.percentage,
        text: `${item.value} tasks<br />${this.getDaysDeltaText(item)}`,
      }));
    }
    return data.map((item: Object): ViewModel => ({
      label: LabelStrings[item.id],
      value: item.percentage,
      text: `${item.value} activities<br />${this.getDaysDeltaText(item)}`,
    }));
  }

  getWidget(): React.Node {
    if (this.props.data.durationProfileTask.length !== 0 && this.props.isTask === 'true') {
      return (
        <div>
          <div>
            <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="plan_reliability_duration_tasks" />
          </div>
          <br />
          <br />
          <div>
            <ResponsivePieChart data={this.getFormattedData(this.props.data.durationProfileTask)} />
          </div>
        </div>
      );
    } if (this.props.data.durationProfile.length !== 0 && this.props.isTask !== 'true') {
      return (
        <div>
          <div>
            {this.props.week ? <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="plan_reliability_duration_activities" /> : null }
          </div>
          {this.props.week ? <br /> : null }
          {this.props.week ? <br /> : null }
          <div>
            <ResponsivePieChart data={this.getFormattedData(this.props.data.durationProfile)} />
          </div>
        </div>
      );
    }
    return (
      <span style={styles.noData}>
      No data available
      </span>
    );
  }

  dispatch() {
    if (this.props.isTask === 'true') {
      this.props.dispatch({
        type: ActionTypes.GET_DURATION_PROFILE_TASK,
        data: {
          projectId: this.props.project.id,
          startDate: this.props.week,
          isTask: this.props.isTask,
        },
      });
    } else {
      this.props.dispatch({
        type: ActionTypes.GET_DURATION_PROFILE,
        data: {
          projectId: this.props.project.id,
          startDate: this.props.week,
          isTask: this.props.isTask,
        },
      });
    }
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div style={styles.chart}>
          <h1 className="header-widget-common">
            {this.props.week ? 'Duration' : 'Plan Reliability Categorization (Duration)'}
            {' '}
            <img data-tip={`This categorization of the actual ${this.props.isTask ? 'task' : 'activity'} end dates and proportionately comparing with the planned end dates.<br />It helps to understand the reliability in planning the end of ${this.props.isTask ? 'tasks' : 'activities'}. Together the two have interdependencies and a pattern might emerge between these two`} width="14" src={infoIcon} alt="i" />
          </h1>
          <ReactTooltip place="top" type="dark" effect="solid" border multiline />
          {
            this.props.data.durationProfileLoading === null
              ? <Loader /> : this.getWidget()
          }
        </div>
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: ProfileDurationState } => ({ data: state.activityProfileDuration }),
)(ActivityProfileDuration);

Component.style = {
  width: '31%',
};

export default Component;
