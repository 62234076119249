// @flow

type WeeklyCommitmentReliability = {
  GET_WEEKLY_COMMITMENT_RELIABILITY: 'getWeeklyCommitmentReliability',
  GET_WEEKLY_COMMITMENT_RELIABILITY_SUCCESS: 'getWeeklyCommitmentReliabilitySuccess',
  GET_WEEKLY_COMMITMENT_RELIABILITY_FAIL: 'getWeeklyCommitmentReliabilityFail',
}

const actionTypes: WeeklyCommitmentReliability = {
  GET_WEEKLY_COMMITMENT_RELIABILITY: 'getWeeklyCommitmentReliability',
  GET_WEEKLY_COMMITMENT_RELIABILITY_SUCCESS: 'getWeeklyCommitmentReliabilitySuccess',
  GET_WEEKLY_COMMITMENT_RELIABILITY_FAIL: 'getWeeklyCommitmentReliabilityFail',
};

export default actionTypes;
