// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopDelayedConstraintsApi from './apis';

export type DelayedConstraint = {
  delay: number,
  name: string,
  project_delay: string,
  type: string,
  artifact: string,
  activity_id: number,
  task_id: number,
};

type TopDelayedConstraintsResponse = {
  delays: Array<DelayedConstraint>,
};

export type TopDelayedConstraintsAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_DELAYED_CONSTRAINTS_SUCCESS'>,
  data: TopDelayedConstraintsResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_DELAYED_CONSTRAINTS_FAIL'>,
|};

function* getTopDelayedConstraints(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopDelayedConstraintsResponse } = yield call(getTopDelayedConstraintsApi, action.data.projectId, action.data.startDate);
    const actionData: TopDelayedConstraintsAction = { type: ActionTypes.GET_TOP_DELAYED_CONSTRAINTS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopDelayedConstraintsAction = { type: ActionTypes.GET_TOP_DELAYED_CONSTRAINTS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_DELAYED_CONSTRAINTS, getTopDelayedConstraints);
}
