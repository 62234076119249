// @flow
import ActionTypes from './action-types';
import type { TopExtendedActivitiesAction, ExtendedActivity } from './sagas';

export type TopExtendedActivitiesState = {
  chartData: ExtendedActivity[],
  loading: boolean | null,
};

const initialState: TopExtendedActivitiesState = { loading: null, chartData: [] };

export default function (state: TopExtendedActivitiesState = initialState, action: TopExtendedActivitiesAction): TopExtendedActivitiesState {
  switch (action.type) {
    case ActionTypes.GET_TOP_EXTENDED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        chartData: action.data.activities,
        loading: false,
      };
    default:
      return state;
  }
}
