// @flow
import { map } from 'ramda';

import ActionTypes from './action-types';
import type { ActivityProfileAction, DateProfile } from './sagas';

export type WeeklyActivityProfileDateItem = {
  asPlanned: number,
  delayed: number,
  earlier: number,
  endDate: string,
  startDate: string,
};


export type WeeklyActivityProfileDateState = {
  weeklyDateProfile: WeeklyActivityProfileDateItem[],
  weeklyDateProfileLoading: boolean | null,
};

const initialState: WeeklyActivityProfileDateState = {
  weeklyDateProfileLoading: null,
  weeklyDateProfile: [],
};

const formatData: Function = function formatData(data: DateProfile): WeeklyActivityProfileDateItem[] {
  return map((item: DateProfile): WeeklyActivityProfileDateItem => (
    {
      asPlanned: item.as_planned,
      delayed: item.delayed,
      earlier: item.earlier,
      endDate: item.end_date,
      startDate: item.start_date,
    }
  ), data);
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyActivityProfileDateState = initialState, action: ActivityProfileAction): Exact<WeeklyActivityProfileDateState> {
  let dp: WeeklyActivityProfileDateItem[];

  switch (action.type) {
    case ActionTypes.GET_WEEKLY_DATE_PROFILE_SUCCESS:
      dp = formatData(action.data.profiles);
      return {
        ...state,
        weeklyDateProfile: dp,
        weeklyDateProfileLoading: false,
      };
    default:
      return state;
  }
}
