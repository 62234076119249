// @flow

import React from 'react';
import Plotly from 'plotly.js/lib/index-basic';
import { reject, isNil, isEmpty } from 'ramda';

import withResponsive from '../utils/withResponsive';
import colors from '../../../styles/colors';

export type DataItem = {
  label: string,
  value: number,
  color?: string,
  text?: string,
};

type Props = {
  onRenderChart: (Object) => mixed,
  data: DataItem[],
  dataOptions?: Object,
  options?: Object,
};

const ColorConfig: Array<string> = [
  colors.paleCerulean,
  colors.mandysPink,
  colors.yellow,
  colors.cappuccino,
  colors.seashell,
  colors.cinderella,
  colors.apricot,
  colors.aquaForest,
];

class PieChart extends React.PureComponent<Props> {
  static defaultProps = {
    // onRenderChart: () => {},
    options: {},
    dataOptions: {},
  };

  componentDidMount() {
    if (this.el) {
      this.renderChart();
    }
  }

  componentDidUpdate() {
    if (this.el) {
      this.renderChart();
    }
  }

  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;

    this.props.onRenderChart(el);
  }

  el: HTMLDivElement;

  renderChart() {
    const labels: Array<string> = this.props.data.map((item: DataItem): string => item.label);
    const values: Array<number> = this.props.data.map((item: DataItem): number => item.value);
    const text: Array<string> = this.props.data.map((item: DataItem): string | any => item.text);
    const markerColors: Array<string> = this.props.data.map((item: DataItem): any => item.color);

    const data: Array<Object> = [
      {
        labels,
        values,
        text,
        type: 'pie',
        marker: {
          colors: isEmpty(reject(isNil, markerColors)) ? ColorConfig : markerColors,
        },
        hoverinfo: 'label+text',
        textinfo: 'percent',
        ...this.props.dataOptions,
      },
    ];

    Plotly.newPlot(this.el, data, {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      legend: {
        orientation: 'h',
        xanchor: 'center',
        x: 0.5,
        y: -0.2,
      },
      margin: {
        t: 40,
        l: 10,
        r: 10,
        b: 80,
      },
      ...this.props.options,
    }, {
      showLink: false,
      displaylogo: false,
      sendData: false,
      modeBarButtonsToRemove: ['sendDataToCloud', 'hoverClosestCartesian', 'hoverClosestPie', this.props.options && this.props.options.hideDownloadImage ? 'toImage' : null],
    });
  }

  render(): React$Node {
    return (
      <div ref={this.getElementRef} />
    );
  }
}

export default withResponsive<any>(PieChart);
