// @flow

export type ActivityWorkableBacklogTypes = {
  GET_COMMITMENT_RELIABILITY: 'getCommitmentReliabilityWeekly',
  GET_COMMITMENT_RELIABILITY_SUCCESS: 'getCommitmentReliabilityWeeklySuccess',
  GET_COMMITMENT_RELIABILITY_FAIL: 'getCommitmentReliabilityWeeklyFail',
}

const actionTypes: ActivityWorkableBacklogTypes = {
  GET_COMMITMENT_RELIABILITY: 'getCommitmentReliabilityWeekly',
  GET_COMMITMENT_RELIABILITY_SUCCESS: 'getCommitmentReliabilityWeeklySuccess',
  GET_COMMITMENT_RELIABILITY_FAIL: 'getCommitmentReliabilityWeeklyFail',
};

export default actionTypes;
