// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import {
  getDelayCommentsApi,
  postDelayCommentsApi,
  getDelayHistoryApi,
  updateDelayHistoryApi,
} from './apis';

type Delay = {
  name: string
}

export type DelayType = {
  comment: string,
  commented_at: string,
  userDetails: Delay[],
};

export type DelayResponseType = {
  comments: DelayType[],
};

export type DelayDetailResponseType = {
  comments: DelayResponseType[],
};

type UpdateDelayResponseType = {
  delay: any,
};

type DelayCommentPostType= {
  projectId: string,
  delayId: number,
  comment: string,
}

type DelayHistoryPostType= {
  projectId: string,
  delayId: number,
  cmd: string,
  variance: string,
  description: string,
  responsible_party: string,
}

export type DelayHistoryType = {
  description: string,
  modified_on: string,
  responsible_entity: string,
  variance: string,
}
export type DelayHistoryResponseType = {
  edit_history: DelayHistoryType[],
}

export type DelayDetailAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELAY_HISTORY_SUCCESS'>,
  data: DelayHistoryResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELAY_HISTORY_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELAY_COMMENTS_SUCCESS'>,
  data: DelayDetailResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELAY_COMMENTS_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'CLEAR_DELAY_DETAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'UPDATE_DELAY_HISTORY_SUCCESS'>,
  data: UpdateDelayResponseType,
|}


function* getDelayComments(action: { data: { projectId: string, delayId: number } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: DelayDetailResponseType } = yield call(getDelayCommentsApi, action.data.projectId, action.data.delayId);
    yield put({ type: ActionTypes.GET_DELAY_COMMENTS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_DELAY_COMMENTS_FAIL });
  }
}

function* getDelayHistory(action: { data: { projectId: string, delayId: number } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: DelayHistoryResponseType } = yield call(getDelayHistoryApi, action.data.projectId, action.data.delayId);
    yield put({ type: ActionTypes.GET_DELAY_HISTORY_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_DELAY_HISTORY_FAIL });
  }
}

function* postDelayComments(action: { data: { projectId: string, delayId: number, data: Object } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: DelayCommentPostType } = yield call(postDelayCommentsApi, action.data.projectId, action.data.delayId, action.data.data);
    yield put({ type: ActionTypes.POST_DELAY_COMMENTS_SUCCESS, data: response.data });
    yield put({ type: ActionTypes.GET_DELAY_COMMENTS, data: { projectId: action.data.projectId, delayId: action.data.delayId } });
  } catch (e) {
    yield put({ type: ActionTypes.POST_DELAY_COMMENTS_FAIL });
  }
}

function* updateDelayHistory(action: { data: { projectId: string, delayId: number, data: Object } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: DelayHistoryPostType } = yield call(updateDelayHistoryApi, action.data.projectId, action.data.delayId, action.data.data);
    yield put({ type: ActionTypes.UPDATE_DELAY_HISTORY_SUCCESS, data: response.data });
    yield put({ type: ActionTypes.GET_DELAY_HISTORY, data: { projectId: action.data.projectId, delayId: action.data.delayId } });
  } catch (e) {
    yield put({ type: ActionTypes.UPDATE_DELAY_HISTORY_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DELAY_COMMENTS, getDelayComments);
  yield takeLatest(ActionTypes.GET_DELAY_HISTORY, getDelayHistory);
  yield takeLatest(ActionTypes.POST_DELAY_COMMENTS, postDelayComments);
  yield takeLatest(ActionTypes.UPDATE_DELAY_HISTORY, updateDelayHistory);
}
