// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { groupBy, remove } from 'ramda';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import ActionTypes from './action-types';
import BarChart from '../../components/charts/bar';
import ReasonsTable from './reasons-table';
import Legend from './legend';
import colors from '../../styles/colors';
import ToggleButton from '../../components/toggle-button';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import ExportButton from '../../components/export';

import type { ReasonForDelaysState, ReasonForDelayItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes> | 'showModal', data?: Object };
type Dispatch = (action: Action) => void;


type Props = {
  dispatch: Dispatch,
  data: ReasonForDelaysState,
  project: Project,
  week: string,
}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    overflow: 'auto',
  },
  chart: {
    width: '50%',
    float: 'left',
    marginRight: '2%',
  },
  table: {
    marginTop: 40,
    fontSize: '0.8rem',
    lineHeight: '1.3rem',
    overflowX: 'hidden',
  },
  legendContainer: {
    paddingLeft: 40,
    paddingTop: '1rem',
  },
  label: {
    marginRight: 6,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const ColorConfig: Object = {
  'Avoidable by getting valid commitments': colors.themePrimaryColor,
  'Avoidable by making plan reliable and robust': colors.themeSecondaryColor,
  'Unforeseen / external conditions': colors.yellow,
};

const TextConstants: Object = {
  'Avoidable by getting valid commitments': 'Preventable by getting valid commitments',
  'Avoidable by making plan reliable and robust': 'Preventable by making plan reliable and robust',
  'Unforeseen / external conditions': 'Unforeseen / external conditions',
};

class ReasonForDelays extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.fetchData();
    }
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    }, this.fetchData);
  }

  getChartData(data: ReasonForDelayItem[]): ViewModel[] {
    return data.map((delayItem: ReasonForDelayItem): Object => {
      const getSortFilter: Object = {
        occurences: delayItem.value,
        project_delay: delayItem.projectDelay,
        artifact_delay: delayItem.artifactDelay,
      };
      const value: number = getSortFilter[this.state.selectedFilter];
      return {
        label: delayItem.label,
        value,
        color: ColorConfig[delayItem.classification],
        text: `${TextConstants[delayItem.classification]}<br>A total of ${delayItem.value} occurrence(s) has delayed the project by ${delayItem.projectDelay} day(s)`,
        classificationText: TextConstants[delayItem.classification],
      };
    });
  }

  getLegend(data: ViewModel[]): Array<Object> {
    const byColors: Object = groupBy((item: ViewModel): string => item.color, data);
    return Object.keys(byColors).map((key: string): Object => ({
      color: key,
      label: byColors[key][0].classificationText,
    }));
  }

  getWidget(chartData: ViewModel[], toggleItems: Array<*>): React.Node {
    return this.props.data.chartData.length !== 0
      ? (
        <div>
          { this.props.week ? <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="delay_classification" /> : null }
          <div>
            <ToggleButton
              items={this.props.week ? toggleItems : remove(1, 1, toggleItems)}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
          <div style={styles.chart}>
            <BarChart data={chartData} />
            <div style={styles.legendContainer}>
              <Legend legend={this.getLegend(chartData)} />
            </div>
          </div>
          <div style={styles.table}>
            {this.props.week
              ? (
                <ReasonsTable
                  delayReasons={this.props.data.chartData}
                  showOthersDelaysModal={this.showOthersDelaysModal}
                  showArtifact
                />)
              : (
                <ReasonsTable
                  delayReasons={this.props.data.chartData}
                  showArtifact={false}
                  showOthersDelaysModal={this.showOthersDelaysModal}
                />)}
          </div>
        </div>)
      : <span style={styles.noData}> No data available</span>;
  }

  showOthersDelaysModal = () => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'othersDelays',
      modalProps: {
        projectId: this.props.project.id,
        week: this.props.week,
      },
    });
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_REASON_FOR_DELAYS,
      data: {
        projectId: this.props.project.id,
        filter: this.state.selectedFilter,
        startDate: this.props.week,
      },
    });
  }

  render(): React.Node {
    const chartData: ViewModel[] = this.getChartData(this.props.data.chartData);
    const toggleItems: Array<*> = [
      { value: 'occurences', label: 'Occurences' },
      { value: 'artifact_delay', label: 'Total Delay (days)' },
      { value: 'project_delay', label: 'Total Project Delay (days)' },
    ];
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 200}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
Delay Classification
          <img data-tip="This shows the classification of the reasons for any delays in activities, tasks or constraints in the project. The list can be sorted by their occurrences or their impact to the project.<br />The list is also categorised to indicate how such issues can be solved. The entire list is also presented as a table with respective figures" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.getWidget(chartData, toggleItems)
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ReasonForDelaysState } => ({ data: state.reasonForDelays }),
)(ReasonForDelays);

component.style = {
  width: '98%',
};

export default component;
