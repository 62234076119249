// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import Loader from '../../components/loader';
import StackedBarChart from '../../components/charts/stacked-bar';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { WeeklyActivityProfileDateState, WeeklyActivityProfileDateItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { DataItem } from '../../components/charts/stacked-bar';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: WeeklyActivityProfileDateState,
  project: Project,
};

type ViewModel = Array<DataItem>;

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ActivityProfileDate extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_WEEKLY_DATE_PROFILE,
      data: { projectId: this.props.project.id },
    });
  }

  getFormattedData(data: WeeklyActivityProfileDateItem[]): ViewModel {
    const asPlanned: number[] = [];
    const delayed: number[] = [];
    const earlier: number[] = [];
    const dates: string[] = [];
    data.forEach((item: WeeklyActivityProfileDateItem) => {
      asPlanned.push(item.asPlanned);
      delayed.push(item.delayed);
      earlier.push(item.earlier);
      dates.push(moment(item.endDate).format('DD MMM YYYY'));
    });
    const traces: ViewModel = [
      {
        labels: dates,
        values: asPlanned,
        text: 'As Planned',
        color: colors.paleCerulean,
      },
      {
        labels: dates,
        values: earlier,
        text: 'Earlier',
        color: colors.yellow,
      },
      {
        labels: dates,
        values: delayed,
        text: 'Delayed',
        color: colors.mandysPink,
      },
    ];

    return traces;
  }

  renderWidget(): React.Node {
    return this.props.data.weeklyDateProfile.length !== 0 ? (
      <StackedBarChart data={this.getFormattedData(this.props.data.weeklyDateProfile)} />
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Weekly Activity Profile (Start Date)
          <img
            data-tip="This displays the weekly profile of the activities based on the start date to arrive at a trend based on the past actual starts"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.weeklyDateProfileLoading === null ? <Loader /> : this.renderWidget()}
      </section>
    );
  }
}

const Component: any = connect((state: Reducers): { data: WeeklyActivityProfileDateState } => (
  { data: state.weeklyActivityProfileDate }))(ActivityProfileDate);

export default Component;
