// @flow

import axios from 'axios';
import config from '../config/config.json';


export function getDelayCommentsApi(projectId: string, delayId: string): Promise<Object> {
  return axios.get(`${config.baseUrl}v1/projects/${projectId}/delay/${delayId}/comments`);
}

export function postDelayCommentsApi(projectId: string, delayId: string, payload: Object): Promise<Object> {
  return axios.post(`${config.baseUrl}v1/projects/${projectId}/delay/${delayId}/comments`, payload);
}

export function getDelayHistoryApi(projectId: string, delayId: string): Promise<Object> {
  return axios.get(`${config.baseUrl}projects/${projectId}/delays/${delayId}/edit_history?cmd=get_delay_history`);
}

export function updateDelayHistoryApi(projectId: string, delayId: string, payload: Object): Promise<Object> {
  return axios.put(`${config.baseUrl}projects/${projectId}/delays/${delayId}`, payload);
}
