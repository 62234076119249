// @flow

import ActionTypes from './action-types';
import type { TopDelayReasonsAction, TopDelayReasonsSuccessType } from './sagas';

export type TopDelayReasonsState = {
  data: Array<TopDelayReasonsSuccessType>,
  TopDelayReasonsLoading: boolean | null,
};

const initialState: TopDelayReasonsState = {
  TopDelayReasonsLoading: null,
  data: [],
};

const formatData: Function = function formatData(data: TopDelayReasonsSuccessType): TopDelayReasonsSuccessType {
  return data;
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: TopDelayReasonsState = initialState, action: TopDelayReasonsAction): Exact<TopDelayReasonsState> {
  let dp: TopDelayReasonsSuccessType;

  switch (action.type) {
    case ActionTypes.GET_TOP_DELAY_REASONS_SUCCESS:
      dp = formatData(action.data);
      return {
        ...state,
        data: dp.critical_activities,
        TopDelayReasonsLoading: false,
      };
    default:
      return state;
  }
}
