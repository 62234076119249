// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getTopShortenedActivitiesApi from './apis';

export type ShortenedActivity = {
  base_duration: number,
  actual_duration: number,
  name: string,
  parent: string,
  ratio: number,
  wbs: string,
  id: number,
};

type TopShortenedActivitiesResponse = {
  activities: Array<ShortenedActivity>,
};

export type TopShortenedActivitiesAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_SHORTENED_ACTIVITIES_SUCCESS'>,
  data: TopShortenedActivitiesResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TOP_SHORTENED_ACTIVITIES_FAIL'>,
|};

function* getTopShortenedActivities(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: TopShortenedActivitiesResponse } = yield call(getTopShortenedActivitiesApi, action.data.projectId, action.data.startDate);
    const actionData: TopShortenedActivitiesAction = { type: ActionTypes.GET_TOP_SHORTENED_ACTIVITIES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: TopShortenedActivitiesAction = { type: ActionTypes.GET_TOP_SHORTENED_ACTIVITIES_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TOP_SHORTENED_ACTIVITIES, getTopShortenedActivities);
}
