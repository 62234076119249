// @flow

import React from 'react';

import type { Project } from '../../auth/with-project-context';
import './styles.css';
import ActivityProfileDate from '../activity-profile-date';
import ActivityProfileDuration from '../activity-profile-duration';
import ActivityProfileEndDate from '../activity-profile-enddate';

type Props = {
  project: Project,
  week: string,
}

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  commonWidget: {
    // height: '446px',
    margin: '0.5rem',
  },
  singleWidget: {
    width: '33.3%',
  },
};

function PlanReliabilityTaskWeekly(props: Props): any {
  return (
    <section style={styles.widget}>
      <h1 className="header-widget-common">Plan Reliability (Tasks)</h1>
      <div className="week-summary-container">
        <div className="single-widget-container">
          <ActivityProfileDate project={props.project} week={props.week} isTask="true" widgetStyle={{ ...styles.widget, ...styles.singleWidget, ...styles.commonWidget }} />
        </div>
        <div className="single-widget-container">
          <ActivityProfileDuration project={props.project} week={props.week} isTask="true" widgetStyle={{ ...styles.widget, ...styles.singleWidget, ...styles.commonWidget }} />
        </div>
        <div className="single-widget-container">
          <ActivityProfileEndDate project={props.project} week={props.week} isTask="true" widgetStyle={{ ...styles.widget, ...styles.singleWidget, ...styles.commonWidget }} />
        </div>
      </div>
    </section>
  );
}

PlanReliabilityTaskWeekly.style = {
  width: '98%',
};

export default PlanReliabilityTaskWeekly;
