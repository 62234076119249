// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import type { MapStateToProps, Connector } from 'react-redux';

import Loader from '../../components/loader';
import PieChart from '../../components/charts/pie';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import ToggleButton from '../../components/toggle-button';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { delayReasonsState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: delayReasonsState,
  project: Project
};

type State = {
  selectedFilter: string
};

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const ColorConfig: Object = {
  'Avoidable by getting valid commitments': colors.mandysPink,
  'Avoidable by making plan reliable and robust': colors.paleCerulean,
  'Unforeseen / external conditions': colors.yellow,
};

const TextConstants: Object = {
  'Avoidable by getting valid commitments':
    'Preventable by getting valid commitments',
  'Avoidable by making plan reliable and robust':
    'Preventable by making plan reliable and robust',
  'Unforeseen / external conditions': 'Unforeseen / external conditions',
};

class DelayReasonsWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  };

  componentDidMount() {
    this.fetchData();
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState(
      {
        selectedFilter: val,
      },
      this.fetchData,
    );
  }

  getChartData(data: Array<Object>): ViewModel[] {
    return data.map(
      (item: Object): Object => ({
        label: TextConstants[item.label],
        value: item.value,
        color: ColorConfig[item.label],
        text: item.text,
      }),
    );
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_ROOT_CAUSE_OF_DELAY_REASONS,
      data: {
        projectId: this.props.project.id,
        filter: this.state.selectedFilter,
      },
    });
  }

  renderWidget(): React.Node {
    return this.props.data.chartData.length !== 0
    && this.props.data.loading === false ? (
      <div>
        <div style={styles.filterContainer}>
          <ToggleButton
            items={[
              { value: 'occurences', label: 'Occurences' },
              { value: 'days', label: 'Total Project Delay (days)' },
            ]}
            value={this.state.selectedFilter}
            onChange={this.onFilterChange}
          />
        </div>
        <PieChart data={this.getChartData(this.props.data.chartData)} />
      </div>
      ) : (
        <span style={styles.noData}>No data available</span>
      );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section
        style={
          this.props.data.chartData.length !== 0
            ? { ...styles.widget, height: 536 }
            : styles.widget
        }
      >
        <h1 className="header-widget-common">
          Delay Preventions
          <img
            data-tip="This chart shows what proportion of delays can be prevented by improving on planning or commitments or others"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderWidget()}
      </section>
    );
  }
}

// export default connect(
//   (state: Reducers): { data: delayReasonsState } => ({ data: state.delayReasons }),
// )(DelayReasonsWidget);

type TMapStateToFilter = MapStateToProps<Reducers, any, { data: delayReasonsState }>;

const mapStateToProps: TMapStateToFilter = (state: any): Object => ({
  data: state.delayReasons,
});

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(DelayReasonsWidget);
