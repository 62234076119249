// @flow

type ProjectSummaryActionTypes = {
  GET_PROJECT_SUMMARY_WEEKLY: 'getProjectSummaryWeekly',
  GET_PROJECT_SUMMARY_WEEKLY_SUCCESS: 'getProjectSummaryWeeklySuccess',
  GET_PROJECT_SUMMARY_WEEKLY_FAIL: 'getProjectSummaryWeeklyFail',
}

const actionTypes: ProjectSummaryActionTypes = {
  GET_PROJECT_SUMMARY_WEEKLY: 'getProjectSummaryWeekly',
  GET_PROJECT_SUMMARY_WEEKLY_SUCCESS: 'getProjectSummaryWeeklySuccess',
  GET_PROJECT_SUMMARY_WEEKLY_FAIL: 'getProjectSummaryWeeklyFail',
};

export default actionTypes;
