// @flow

import * as React from 'react';

const styles: Object = {
  button: {
    background: '#fff',
    border: '1px solid #b3b3b3',
    fontSize: '0.8rem',
    padding: '0.3rem 0.7rem',
    cursor: 'pointer',
    color: '#616161',
    transition: 'border-color 0.2s',
    ':hover': {
      borderColor: '#737373',
    },
  },
};

type Props = {
  children: any,
};

const Button: Function = function Button(props: Props): React.Node {
  return (
    <button type="button" style={styles.button} {...props}>{props.children}</button>
  );
};

export default Button;
