// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getActivityDetailApi from './apis';

export type DelayType = {
  delay: number,
  id: number,
  variance: string,
};

export type ActivityDetailResponseType = {
  activity_constraint_delays: DelayType[],
  actual_end_date: string,
  actual_start_date: string,
  base_end_date: string,
  base_start_date: string,
  start_date: string,
  end_date: string,
  parent_chain: string,
  task_constraint_delays: DelayType[],
  task_delays: DelayType[],
  activity_delays: DelayType[],
};

export type ActivityDetailAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_ACTIVITY_DETAIL_SUCCESS'>,
  data: ActivityDetailResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_ACTIVITY_DETAIL_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'CLEAR_ACTIVITY_DETAIL'>,
|};

function* getActivityDetail(action: { data: { projectId: string, activityId: number, delayType: string } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: ActivityDetailResponseType } = yield call(getActivityDetailApi, action.data.projectId, action.data.activityId, action.data.delayType);
    yield put({ type: ActionTypes.GET_ACTIVITY_DETAIL_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_ACTIVITY_DETAIL_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_ACTIVITY_DETAIL, getActivityDetail);
}
