// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getTaskDetailApi from './apis';

export type DelayType = {
  delay: number,
  id: number,
  variance: string,
};

export type TaskDetailResponseType = {
  actual_end_date: string,
  actual_start_date: string,
  base_end_date: string,
  base_start_date: string,
  start_date: string,
  end_date: string,
  parent_chain: string,
  task_constraint_delays: DelayType[],
  task_delays: DelayType[],
};

export type TaskDetailAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TASK_DETAIL_SUCCESS'>,
  data: TaskDetailResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_TASK_DETAIL_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'CLEAR_TASK_DETAIL'>,
|};

function* getTaskDetail(action: { data: { projectId: string, activityId: number, taskId:number } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: TaskDetailResponseType } = yield call(getTaskDetailApi, action.data.projectId, action.data.activityId, action.data.taskId);
    yield put({ type: ActionTypes.GET_TASK_DETAIL_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_TASK_DETAIL_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_TASK_DETAIL, getTaskDetail);
}
