// @flow

import * as React from 'react';
import Plotly from 'plotly.js/lib/index-basic';

import withResponsive from '../../components/charts/utils/withResponsive';

type DataItem = {
  labels: string,
  values: number,
  color: string,
  name: string,
  texts: string,
  textColor: ?string,
};

type Props = {
  onRenderChart: (Object) => mixed,
  barData: any,
  lineData: any,
};

class CustomChart extends React.PureComponent<Props> {
  // static defaultProps = {
  //   onRenderChart: () => {},
  // };

  componentDidMount() {
    if (this.el) {
      this.renderChart();
    }
  }

  componentDidUpdate() {
    if (this.el) {
      this.renderChart();
    }
  }


  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;

    this.props.onRenderChart(el);
  }

  el: HTMLDivElement;

  renderChart() {
    const data: Array<Object> = this.props.barData.map((trace: DataItem): Object => ({
      x: trace.labels,
      y: trace.values,
      name: trace.name,
      type: 'bar',
      text: trace.values,
      textposition: 'inside',
      hovertext: trace.texts,
      hoverinfo: 'text',
      marker: {
        color: trace.color,
      },
      textfont: {
        color: trace.textColor,
      },
    }));

    const lineData: Array<Object> = this.props.lineData.map((trace: DataItem): Object => ({
      x: trace.labels,
      y: trace.values,
      name: trace.name,
      type: 'scatter',
      hovertext: trace.texts,
      hoverinfo: 'text',
      marker: {
        color: trace.color,
        width: 3,
      },
    }));

    Plotly.newPlot(this.el, [...data, ...lineData], {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      barmode: 'relative',
      margin: {
        t: 40,
        l: 70,
        r: 100,
        b: 120,
      },
      xaxis: {
        tickangle: -45,
        type: 'category',
      },
      yaxis: {
        showline: true,
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.32,
      },
      annotations: [{
        x: 1,
        y: 0,
        xref: 'paper',
        yref: 'y',
        showarrow: false,
        xanchor: 'left',
        text: 'Base End Date',
      },
      {
        x: -0.04,
        y: 0.85,
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        textangle: -90,
        text: 'Delay',
      },
      {
        x: -0.04,
        y: 0.10,
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        textangle: -90,
        text: 'Mitigation',
      }],
    }, {
      showLink: false,
      displaylogo: false,
      sendData: false,
      modeBarButtonsToRemove: ['zoom2d', 'pan', 'pan2d', 'autoScale2d', 'sendDataToCloud', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian'],
    });
  }

  render(): React.Node {
    return (
      <div ref={this.getElementRef} />
    );
  }
}

export default withResponsive<any>(CustomChart);
