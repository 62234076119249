// @flow
import { map, takeLast } from 'ramda';

import ActionTypes from './action-types';
import type { ProductivityImprovementAction, ProductivityImprovement } from './sagas';

export type WeeklyProductivityImprovementItem = {
  productivityImprovement: number,
  endDate: string,
  startDate: string,
  durationShortened: number,
  totalBaseDuration: number,
};


export type WeeklyProductivityImprovementState = {
  weeklyProductivityImprovement: WeeklyProductivityImprovementItem[],
  weeklyProductivityImprovementLoading: boolean | null,
};

const initialState: WeeklyProductivityImprovementState = {
  weeklyProductivityImprovementLoading: null,
  weeklyProductivityImprovement: [],
};

const formatData: Function = function formatData(data: ProductivityImprovement): WeeklyProductivityImprovementItem[] {
  return map((item: ProductivityImprovement): WeeklyProductivityImprovementItem => (
    {
      productivityImprovement: item.productivity_improvement,
      endDate: item.end_date,
      startDate: item.start_date,
      durationShortened: item.duration_shortened,
      totalBaseDuration: item.total_base_duration,
    }
  ), takeLast(20, data));
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: WeeklyProductivityImprovementState = initialState, action: ProductivityImprovementAction): Exact<WeeklyProductivityImprovementState> {
  let pi: WeeklyProductivityImprovementItem[];

  switch (action.type) {
    case ActionTypes.GET_WEEKLY_PRODUCTIVITY_IMPROVEMENT_SUCCESS:
      pi = formatData(action.data.productivity_improvement);
      return {
        ...state,
        weeklyProductivityImprovement: pi,
        weeklyProductivityImprovementLoading: false,
      };
    default:
      return state;
  }
}
