// @flow

import * as React from 'react';
import Plotly from 'plotly.js/lib/index-basic';

import withResponsive from '../utils/withResponsive';

export type BorderLine = {
  width: number,
  color: string | string[],
}

type Annotation = {
  x: number,
  y: number,
  text: string,
  showarrow: boolean,
  font: {
    size: number,
  },
}

export type Annotations = Array<Annotation>;

export type DataItem = {
  labels: string[],
  values: number[],
  text: string | string[],
  color?: string | string[],
  borderLine?: BorderLine,
};

type Props = {
  onRenderChart: (any) => mixed,
  data: DataItem[],
  options?: Object,
  dataOptions?: Object,
};

class BarChart extends React.PureComponent<Props> {
  static defaultProps = {
    // onRenderChart: () => {},
    options: {},
    dataOptions: {},
  };

  componentDidMount() {
    if (this.el) {
      this.renderChart();
    }
  }

  componentDidUpdate() {
    if (this.el) {
      this.renderChart();
    }
  }

  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;
    if (this.props.onRenderChart) {
      this.props.onRenderChart(el);
    }
  }

  el: HTMLDivElement;

  renderChart() {
    const data: Array<Object> = this.props.data.map((trace: DataItem): Object => ({
      x: trace.labels,
      y: trace.values,
      name: trace.text,
      // text: this.props.dataOptions ? trace.text : null,
      type: 'bar',
      width: 0.3,
      marker: {
        color: trace.color,
        line: trace.borderLine,
      },
      ...this.props.dataOptions,
    }));

    Plotly.newPlot(this.el, data, {
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      barmode: 'stack',
      margin: {
        t: 40,
        l: 50,
        r: 50,
        b: 120,
      },
      xaxis: {
        tickangle: this.props.options ? this.props.options.tickangle : -45,
        type: 'category',
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.32,
      },
      annotations: this.props.options ? this.props.options.annotations : null,
      ...this.props.options,
    }, {
      showLink: false,
      displaylogo: false,
      sendData: false,
      modeBarButtonsToRemove: ['zoom2d', 'pan', 'pan2d', 'autoScale2d', 'sendDataToCloud', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian'],
    });
  }

  render(): React.Node {
    return (
      <div ref={this.getElementRef} />
    );
  }
}

export default withResponsive<any>(BarChart);
