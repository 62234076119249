// @flow

export type WeeklyActivityProfileEndDateActionTypes = {
  GET_WEEKLY_END_DATE_PROFILE: 'getWeeklyEndDateProfile',
  GET_WEEKLY_END_DATE_PROFILE_SUCCESS: 'getWeeklyEndDateProfileSuccess',
  GET_WEEKLY_END_DATE_PROFILE_FAIL: 'getWeeklyEndDateProfileFail',
}

const actionTypes: WeeklyActivityProfileEndDateActionTypes = {
  GET_WEEKLY_END_DATE_PROFILE: 'getWeeklyEndDateProfile',
  GET_WEEKLY_END_DATE_PROFILE_SUCCESS: 'getWeeklyEndDateProfileSuccess',
  GET_WEEKLY_END_DATE_PROFILE_FAIL: 'getWeeklyEndDateProfileFail',
};

export default actionTypes;
