// @flow

import * as React from 'react';

const styles: Object = {
  anchor: {
    fontSize: '0.8rem',
    color: '#fff',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
};

type Props = {
  children: any,
};

const Anchor: Function = function Anchor(props: Props): React.Node {
  return (
    <a style={styles.anchor} {...props}>{props.children}</a>
  );
};

export default Anchor;
