// @flow

type DelayResponsibilitiesActionTypes = {
  GET_RESPONSIBILITY_OF_DELAY_REASONS: 'getResponsibilityOfDelayReasons',
  GET_RESPONSIBILITY_OF_DELAY_REASONS_SUCCESS: 'getResponsibilityOfDelayReasonsSuccess',
  GET_RESPONSIBILITY_OF_DELAY_REASONS_FAIL: 'getResponsibilityOfDelayReasonsFail',
}

const actionTypes: DelayResponsibilitiesActionTypes = {
  GET_RESPONSIBILITY_OF_DELAY_REASONS: 'getResponsibilityOfDelayReasons',
  GET_RESPONSIBILITY_OF_DELAY_REASONS_SUCCESS: 'getResponsibilityOfDelayReasonsSuccess',
  GET_RESPONSIBILITY_OF_DELAY_REASONS_FAIL: 'getResponsibilityOfDelayReasonsFail',
};

export default actionTypes;
